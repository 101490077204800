import { Check, ChevronDown, ChevronUp, Plus } from 'react-feather';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, {
  ChangeEvent, FocusEvent, memo, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import times from 'lodash/times';
import uniq from 'lodash/uniq';

import { Availability, Quantities } from '../useQuantities';
import { CreateUpgradeInput } from '../../../__generated__/globalTypes';
import { ErrorBag, resizedImageUrl } from '../../../common/helpers';
import { Event, Product, ProductVariant, Promotion, Session } from '../helpers';
import { StateContextValue } from '../StateContext';
import { useTracking } from '../../../common/Tracking';
import CheckoutContext from '../CheckoutContext';
import DiscountInfo from '../DiscountInfo';
import PriceRange from '../../../common/Common/PriceRange';
import SoldOutLabel from '../SoldOutLabel';
import UI from '../../../common/UI';
import useCurrencyInput from '../../../common/useCurrencyInput';
import useLocale from '../../../common/useLocale';
import useMediaDevice from '../../../common/useMediaDevice';

export interface ProductSelectorProps {
  event: Event;
  products: Product[];
  /**
   * List of unique upgrades, used to calculate the entered donation amount per product.
   */
  upgrades: CreateUpgradeInput[];
  /**
   * The quantities to be used by this product selector.
   * E.g., quantities of upgrades for a specific registration.
   */
  quantities: Quantities;
  /**
   * The global quantities object, to potentially display information about discounts,
   * which are based on the total number of upgrades, for all registrations.
   */
  globalQuantities: Quantities;
  availability: Availability;
  onChange: (upgrades: CreateUpgradeInput[]) => void;
  units?: number;
  errors?: ErrorBag;
}

/**
 * Memoized using Lodash' isEqual() method because the quantities and availabilities are not stable.
 * This prevents unnecessary rerenders which is especially useful when there are many tickets.
 */
const ProductSelector = memo(
  ({
    event, products, upgrades, quantities, globalQuantities, availability, onChange, units = 1, errors,
  }: ProductSelectorProps) => {
    // Explicitly use frontend namespace, because this component is also used in the participant dashboard
    const { t } = useTranslation('frontend');
    const { session, rememberDonationChoice } = useContext(CheckoutContext);

    /** The entered donation amount per product ID */
    const amounts = products.reduce((amounts, product) => {
      if (product.donation) {
        const promotionIds = product.promotions_for_sale.map(({ id }) => id);

        return {
          ...amounts,
          [product.id]: upgrades.reduce((sum, upgrade) => (
            sum + (promotionIds.includes(upgrade.purchase.promotion.id) ? upgrade.purchase.amount : 0)
          ), 0),
        };
      }

      return amounts;
    }, {} as { [productId: string]: number; });

    const handleChange = useCallback(
      (items: Item[]) => {
        // Recursively insert all items because inserting one item changes the list of upgrades,
        // so that new list needs to be used to insert the next item.
        const newItems = items.reduce((upgrades, { quantity, product, promotion, productVariant, amount }) => (
          product.donation
            ? insertDonation(upgrades, product, promotion, amount)
            : insertUpgrade(upgrades, product, promotion, productVariant, quantity)
        ), [...upgrades]);

        onChange(newItems);
      },
      [onChange, upgrades],
    );

    return (
      <UI.InputGroup sc={{ invalid: !!errors?.products, mb: 0 }}>
        <UI.FormGrid sc={{ gutter: 0.75 }}>
          {products.map((product) => (
            <ProductSelect
              event={event}
              product={product}
              quantities={quantities}
              globalQuantities={globalQuantities}
              availability={availability}
              onChange={handleChange}
              units={units}
              amount={amounts[product.id]}
              donate={product.donation ? session?.donate : undefined}
              rememberDonationChoice={product.donation ? rememberDonationChoice : undefined}
              key={product.id}
            />
          ))}
        </UI.FormGrid>
        <UI.ErrorMessages attribute={t('extras')} errors={errors?.products} sc={{ mt: 2 }} />
      </UI.InputGroup>
    );
  },
  (prevProps, newProps) => isEqual(prevProps, newProps),
);

ProductSelector.displayName = 'ProductSelector';

interface Item {
  quantity: number;
  product: Product;
  promotion: Promotion;
  productVariant?: ProductVariant;
  /** Entered donation amount */
  amount?: number;
}

interface ProductSelectProps {
  event: Event;
  product: Product;
  quantities: Quantities;
  globalQuantities: Quantities;
  availability: Availability;
  onChange: (items: Item[]) => void;
  units?: number;
  /** Entered donation amount */
  amount?: number;
  donate?: Session['donate'];
  rememberDonationChoice?: StateContextValue['rememberDonationChoice'];
}

/**
 * Memoized using Lodash' isEqual() method because the quantities and availabilities are not stable.
 * This prevents unnecessary rerenders which is especially useful when there are many tickets.
 */
const ProductSelect = memo(
  ({
    event, product, quantities, globalQuantities, availability, onChange, units = 1, amount, donate,
    rememberDonationChoice,
  }: ProductSelectProps) => {
    // Explicitly use frontend namespace, because this component is also used in the participant dashboard
    const { t } = useTranslation('frontend');

    const quantity = quantities.products[product.id];
    const selected = quantity > 0;
    const defaultPromotion = product.promotions_for_sale[0];
    const minQuantity = product.stand_alone ? product.min_per_order : product.min_per_ticket;

    const canBeSelected = useMemo(() => {
      if (selected) {
        return true;
      }

      if (product.current_max_per_order === 0) {
        return false;
      }

      for (let i = 0; i < product.promotions_for_sale.length; i++) {
        const promotion = product.promotions_for_sale[i];

        for (let j = 0; j < product.active_product_variants.length; j++) {
          const productVariant = product.active_product_variants[j];

          if (availability.productVariants[promotion.id][productVariant.id] >= units) {
            return true;
          }
        }

        if (product.active_product_variants.length === 0) {
          if (availability.promotions[promotion.id] >= units) {
            return true;
          }
        }
      }

      return false;
    }, [selected, product, availability, units]);

    const [showingQuantitySelect, setShowingQuantitySelect] = useState(
      (selected || minQuantity > 0) && canBeSelected,
    );

    /**
     * If the form state gets changed from somewhere else, update the UI accordingly.
     */
    useEffect(() => {
      if (selected) {
        setShowingQuantitySelect(true);
      }
    }, [selected]);

    /** Only show prices next to promotions if they have different prices */
    const showPromotionPrices = useMemo(
      () => uniq(map(product.promotions_for_sale, 'amount')).length > 1,
      [product],
    );

    const options = Math.max(1, product.active_product_variants.length) * product.promotions_for_sale.length;

    const select = (quantity: number = 1) => {
      setShowingQuantitySelect(true);

      if (options === 1) {
        onChange([{
          quantity,
          product,
          promotion: defaultPromotion,
          productVariant: product.active_product_variants[0],
        }]);
      }
    };

    const theme: any = useTheme();
    const device = useMediaDevice();

    return (
      <UI.Card
        sc={{ outline: selected ? 'secondary' : 'gray.200', mb: 0 }}
        style={{
          borderWidth: selected ? 2 : undefined,
          padding: selected ? theme.gutter * (device.width <= theme.breakpoints.md ? 0.75 : 1) - 1 : undefined,
        }}
        id={`ProductSelect_${product.id}`}
      >
        <UI.GridContainer sc={{ gutter: 0.75 }}>
          {product.image && (
            <UI.Div>
              <UI.Image sc={{ ratio: 2 }}>
                <UI.Div>
                  <img
                    src={resizedImageUrl(product.image.url, 800)}
                    alt={product.title}
                  />
                </UI.Div>
              </UI.Image>
            </UI.Div>
          )}
          <UI.GridContainer sc={{ gutter: 0.5 }} style={{ opacity: product.is_sold_out ? 0.5 : 1 }}>
            <UI.GridContainer sc={{ columns: '1fr fit-content(200px)', gutter: 0.5 }}>
              <UI.Div style={{ minWidth: 0 }}>
                <UI.H4>
                  {units > 1 && (
                    <>
                      {units}
                      &times;
                      {' '}
                    </>
                  )}
                  {product.title}
                  {' '}
                  {product.promotions_for_sale.length === 1 && !defaultPromotion.standard && (
                    <>
                      <UI.Span sc={{ muted: true }}> • </UI.Span>
                      {defaultPromotion.title}
                    </>
                  )}
                  {donate && product.donation && !minQuantity && (
                    <>
                      {' '}
                      <UI.RequiredMark sc={{ valid: typeof donate[product.id] !== 'undefined' }} />
                    </>
                  )}
                  {minQuantity > 0 && (
                    <>
                      {' '}
                      <UI.RequiredMark sc={{ valid: quantity >= minQuantity }} />
                    </>
                  )}
                </UI.H4>
              </UI.Div>
              <UI.Div sc={{ pt: 0.2, textAlign: 'right' }}>
                {!product.donation && (
                  <UI.Span sc={{ fontWeight: 500 }}>
                    <PriceRange
                      prices={product.current_price_range.map((price) => ({ amount: units * price }))}
                    />
                  </UI.Span>
                )}
              </UI.Div>
            </UI.GridContainer>
            {product.description && (
              <UI.Div sc={{ color: 'gray.600' }}>
                <UI.HTML html={product.description} />
              </UI.Div>
            )}
          </UI.GridContainer>

          {!product.donation && (!product.is_sold_out ? (
            <UI.Div style={{ position: 'relative', minHeight: 40 }}>
              {!showingQuantitySelect && (
                <UI.GridContainer
                  sc={{
                    columns: (canBeSelected && options > 1) || !canBeSelected ? '5fr 2fr' : '1fr',
                    alignVertical: 'center',
                  }}
                  style={{ position: 'absolute', width: '100%', zIndex: 1 }}
                >
                  <UI.Div>
                    <UI.Button
                      onClick={() => select()}
                      sc={{ brand: 'secondary' }}
                      disabled={!canBeSelected}
                      aria-label={t(options === 1 ? 'add_item' : 'choose_item', { title: product.title })}
                    >
                      {options === 1 && (
                        <>
                          <UI.Icon>
                            <Plus />
                          </UI.Icon>
                          {' '}
                          {t('add')}
                        </>
                      )}
                      {options > 1 && (
                        <>
                          {t('choose')}
                          {' '}
                          <UI.Icon>
                            <ChevronDown />
                          </UI.Icon>
                        </>
                      )}
                    </UI.Button>
                  </UI.Div>
                  {canBeSelected && options > 1 && (
                    <UI.Div sc={{ textAlign: 'right', muted: true, noWrap: true }}>
                      {t('n_options', { count: options })}
                    </UI.Div>
                  )}
                  {!canBeSelected && (
                    <UI.Div sc={{ textAlign: 'right', muted: true, noWrap: true }}>
                      {t('unavailable')}
                    </UI.Div>
                  )}
                </UI.GridContainer>
              )}

              <UI.AnimateHeight
                duration={options === 1 ? 0 : undefined}
                isVisible={showingQuantitySelect}
              >
                <UI.GridContainer sc={{ gutter: 0.75 }}>
                  {product.promotions_for_sale.map((promotion) => (
                    <PromotionSelect
                      product={product}
                      promotion={promotion}
                      quantities={quantities}
                      availability={availability}
                      onChange={onChange}
                      onDeselect={() => setShowingQuantitySelect(false)}
                      units={units}
                      showPrice={showPromotionPrices}
                      key={promotion.id}
                    />
                  ))}
                  {product.current_discount && (
                    <DiscountInfo event={event} discount={product.current_discount} quantities={globalQuantities} />
                  )}
                  {options > 1 && minQuantity === 0 && (
                    <UI.Div>
                      <UI.HR sc={{ mt: 0, mb: [2.25, 3] }} />
                      <UI.A
                        onClick={() => setShowingQuantitySelect(false)}
                        sc={{ disabled: selected }}
                      >
                        <UI.Icon>
                          <ChevronUp />
                        </UI.Icon>
                        {' '}
                        {t('show_less')}
                      </UI.A>
                    </UI.Div>
                  )}
                </UI.GridContainer>
              </UI.AnimateHeight>
            </UI.Div>
          ) : (
            <UI.Div>
              <SoldOutLabel title={product.title} />
            </UI.Div>
          ))}

          {product.donation && (
            <DonationSelect
              product={product}
              amount={amount}
              quantities={quantities}
              availability={availability}
              onChange={onChange}
              donate={donate}
              rememberDonationChoice={rememberDonationChoice}
            />
          )}
        </UI.GridContainer>
      </UI.Card>
    );
  },
  (prevProps, newProps) => isEqual(prevProps, newProps),
);

ProductSelect.displayName = 'ProductSelect';

interface PromotionSelectProps {
  product: Product;
  promotion: Promotion;
  quantities: Quantities;
  availability: Availability;
  onChange: (items: Item[]) => void;
  onDeselect: () => void;
  units?: number;
  showPrice?: boolean;
}

const PromotionSelect = ({
  product, promotion, quantities, availability, onChange, onDeselect, units = 1, showPrice = true,
}: PromotionSelectProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');
  const { formatCurrency } = useLocale();

  const productQuantity = quantities.products[product.id];
  const promotionQuantity = quantities.promotions[promotion.id];
  const promotionAvailability = availability.promotions[promotion.id];
  const productVariantQuantities = quantities.productVariants[promotion.id];
  const productVariantAvailabilities = availability.productVariants[promotion.id];

  const toggle = (productVariant?: ProductVariant) => {
    const currentQuantity = productVariant ? productVariantQuantities[productVariant.id] : promotionQuantity;
    const newQuantity = currentQuantity === 0 ? 1 : 0;
    const items: Item[] = [];

    const promotionId = promotion.id;
    const productVariantId = productVariant?.id;

    product.promotions_for_sale.forEach((promotion) => {
      product.active_product_variants.forEach((productVariant) => {
        items.push({
          quantity: promotion.id === promotionId && productVariant.id === productVariantId ? newQuantity : 0,
          product,
          promotion,
          productVariant,
        });
      });

      if (product.active_product_variants.length === 0) {
        items.push({
          quantity: promotion.id === promotionId ? newQuantity : 0,
          product,
          promotion,
        });
      }
    });

    onChange(items);
  };

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (product.promotions_for_sale.length === 1 && product.active_product_variants.length <= 1
      && promotionQuantity === 0 && !focused) {
      onDeselect();
    }
  }, [product.promotions_for_sale, product.active_product_variants, promotionQuantity, focused, onDeselect]);

  const maxQuantity = product.stand_alone ? product.max_per_order : product.max_per_ticket;

  return (
    <UI.Div>
      {(product.active_product_variants.length > 0 || product.promotions_for_sale.length > 1) && (
        <UI.HR sc={{ mt: 0, mb: [2.25, 3] }} />
      )}

      <UI.GridContainer sc={{ gutter: 0.75 }}>
        {(product.active_product_variants.length === 0 || product.promotions_for_sale.length > 1) && (
          <UI.GridContainer
            sc={{
              columns: product.promotions_for_sale.length > 1
                && (promotion.is_sold_out || product.active_product_variants.length) === 0
                ? '1fr fit-content(180px)' : '1fr',
            }}
          >
            {product.promotions_for_sale.length > 1 && (
              <UI.GridContainer
                sc={{
                  columns: product.active_product_variants.length > 0 ? '1fr 1fr' : '1fr',
                  gutter: 0,
                }}
              >
                <UI.Div>
                  {product.active_product_variants.length === 0 && (
                    <UI.InputLabel>{promotion.title || t('standard')}</UI.InputLabel>
                  )}
                  {product.active_product_variants.length > 0 && (
                    <UI.Legend>{promotion.title || t('standard')}</UI.Legend>
                  )}
                </UI.Div>
                {showPrice && (
                  <UI.Div sc={{ textAlign: product.active_product_variants.length > 0 ? 'right' : undefined }}>
                    {promotion.amount === 0 && t('free')}
                    {promotion.amount > 0 && formatCurrency(units * promotion.amount)}
                  </UI.Div>
                )}
              </UI.GridContainer>
            )}
            {promotion.is_sold_out && (
              <UI.Div>
                <SoldOutLabel title={`${product.title} / ${promotion.title || t('standard')}`} />
              </UI.Div>
            )}
            {!promotion.is_sold_out && product.active_product_variants.length === 0 && (
              <UI.FlexContainer
                sc={{
                  justifyContent: product.promotions_for_sale.length > 1 || promotion.is_sold_out
                    ? 'flex-end' : undefined,
                }}
              >
                {maxQuantity === 1 && (
                  <UI.Button
                    onClick={() => toggle()}
                    sc={{
                      brand: promotionQuantity === units ? 'gray.100' : 'secondary',
                      muted: productQuantity > 0 && promotionQuantity === 0,
                    }}
                    disabled={productQuantity + promotionAvailability < units}
                    aria-label={t(promotionQuantity === 0 ? 'add_item' : 'remove_item', {
                      title: `${product.title} / ${promotion.title || t('standard')}`,
                    })}
                  >
                    <UI.Icon>
                      {promotionQuantity > 0 ? <Check /> : <Plus />}
                    </UI.Icon>
                    {' '}
                    {t(promotionQuantity === 0 ? 'add' : 'added')}
                  </UI.Button>
                )}
                {maxQuantity !== 1 && (
                  <UI.QuantitySelect
                    quantity={promotionQuantity / units}
                    onChange={(quantity) => onChange([{ quantity, product, promotion }])}
                    maxQuantity={Math.floor((promotionQuantity + promotionAvailability) / units)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    title={`${product.title} / ${promotion.title || t('standard')}`}
                  />
                )}
              </UI.FlexContainer>
            )}
          </UI.GridContainer>
        )}

        {!promotion.is_sold_out && product.active_product_variants.map((productVariant) => (
          <UI.GridContainer
            sc={{ columns: '1fr fit-content(180px)', alignVertical: 'center', gutter: 0.5 }}
            key={productVariant.id}
          >
            <UI.Div>
              <UI.InputLabel
                sc={{
                  muted: productVariantQuantities[productVariant.id] === 0
                  && productVariantAvailabilities[productVariant.id] === 0,
                }}
              >
                {productVariant.title}
              </UI.InputLabel>
            </UI.Div>
            <UI.FlexContainer sc={{ justifyContent: 'flex-end' }}>
              {!productVariant.is_sold_out && maxQuantity === 1 && (
                <UI.Button
                  onClick={() => toggle(productVariant)}
                  sc={{
                    brand: productVariantQuantities[productVariant.id] === 1 ? 'gray.100' : 'secondary',
                    muted: productQuantity > 0 && productVariantQuantities[productVariant.id] === 0,
                  }}
                  disabled={productQuantity + productVariantAvailabilities[productVariant.id] === 0}
                  aria-label={
                    t(productVariantQuantities[productVariant.id] === 0 ? 'add_item' : 'remove_item', {
                      title: `${product.title} / ${promotion.title || t('standard')} / ${productVariant.title}`,
                    })
                  }
                >
                  <UI.Icon>
                    {productVariantQuantities[productVariant.id] > 0 ? <Check /> : <Plus />}
                  </UI.Icon>
                  {' '}
                  {t(productVariantQuantities[productVariant.id] === 0 ? 'add' : 'added')}
                </UI.Button>
              )}
              {!productVariant.is_sold_out && maxQuantity !== 1 && (
                <UI.QuantitySelect
                  quantity={productVariantQuantities[productVariant.id] / units}
                  onChange={(quantity) => onChange([{ quantity, product, promotion, productVariant }])}
                  maxQuantity={
                    productVariantQuantities[productVariant.id]
                      + productVariantAvailabilities[productVariant.id]
                  }
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  title={`${product.title} / ${promotion.title || t('standard')} / ${productVariant.title}`}
                />
              )}
              {productVariant.is_sold_out && (
                <SoldOutLabel
                  title={`${product.title} / ${promotion.title || t('standard')} / ${productVariant.title}`}
                />
              )}
            </UI.FlexContainer>
          </UI.GridContainer>
        ))}
      </UI.GridContainer>
    </UI.Div>
  );
};

interface DonationSelectProps {
  product: Product;
  quantities: Quantities;
  availability: Availability;
  onChange: (items: Item[]) => void;
  amount?: number;
  donate?: Session['donate'];
  rememberDonationChoice?: StateContextValue['rememberDonationChoice'];
}

const DonationSelect = ({
  product, amount, quantities, availability, onChange, donate, rememberDonationChoice,
}: DonationSelectProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');
  const { formatCurrency } = useLocale();
  const { track } = useTracking();

  const defaultPromotion = product.promotions_for_sale[0];
  const minQuantity = product.stand_alone ? product.min_per_order : product.min_per_ticket;

  const inputRef = useRef<HTMLInputElement>();
  const currencyInput = useCurrencyInput();

  // If the product is marked 'required', and it has at least one example amount,
  // then the lowest example amount becomes the minimum donation amount.
  const minAmount = product.minimum_donation_amount;
  const isCustomAmount = amount > 0 && quantities.promotions[defaultPromotion.id] > 0;

  const setCustomAmount = (amount: number) => {
    onChange([{ quantity: 1, product, promotion: defaultPromotion, amount }]);
  };

  const handleDonationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const amount = currencyInput.parse(value) || 0;

    if (amount >= minAmount) {
      setCustomAmount(amount);

      // Don't want to trigger 'no, thanks' if the user backspaces
      rememberDonationChoice?.(product, amount ? true : (value ? false : undefined));
    }
  };

  /**
   * Sets the value to the minimum amount on blur, if the current value too low.
   */
  const handleDonationBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const amount = Math.max(minAmount, currencyInput.parse(value) || 0);

    inputRef.current.value = currencyInput.value(amount);
    setCustomAmount(amount);
  };

  const selectExampleAmount = (promotion: Promotion) => {
    onChange([{ quantity: 1, product, promotion, amount: promotion.amount }]);

    rememberDonationChoice?.(product, true);

    // Empty the custom amount input
    inputRef.current.value = '';
  };

  const cancel = () => {
    rememberDonationChoice?.(product, false);

    // Empty the custom amount input
    inputRef.current.value = '';

    setCustomAmount(0);

    track?.('Checkout:DonationSkipped');
  };

  return (
    <UI.GridContainer sc={{ columns: '1fr 1fr', gutter: 0.5 }}>
      {product.promotions_for_sale.map((promotion) => !promotion.standard && (
        <UI.Div key={promotion.id}>
          <UI.Radio
            checked={quantities.promotions[promotion.id] > 0}
            onChange={() => selectExampleAmount(promotion)}
            disabled={quantities.products[product.id] + availability.promotions[promotion.id] === 0}
            sc={{
              box: true,
              block: true,
              noWrap: true,
              muted: quantities.products[product.id] + availability.promotions[promotion.id] === 0,
            }}
          >
            {formatCurrency(promotion.amount)}
          </UI.Radio>
        </UI.Div>
      ))}

      <UI.Div>
        <UI.InputWrapper>
          {product.promotions_for_sale.length > 1 && (
            <span>
              <UI.Radio
                checked={isCustomAmount}
                onChange={() => inputRef.current.focus()}
                disabled={quantities.products[product.id] + availability.promotions[defaultPromotion.id] === 0}
              >
                <UI.Span
                  sc={{ muted: quantities.products[product.id] + availability.promotions[defaultPromotion.id] === 0 }}
                >
                  &euro;
                </UI.Span>
              </UI.Radio>
            </span>
          )}
          {product.promotions_for_sale.length === 1 && (
            <UI.Span
              sc={{ muted: quantities.products[product.id] + availability.promotions[defaultPromotion.id] === 0 }}
            >
              &euro;
            </UI.Span>
          )}
          <UI.DebouncedInput
            value={isCustomAmount && amount > 0 ? currencyInput.value(amount) : ''}
            onChange={(changeEvent) => handleDonationChange(changeEvent)}
            onBlur={(focusEvent) => handleDonationBlur(focusEvent)}
            onFocus={(event) => event.target.select()}
            inputMode="decimal"
            placeholder={product.promotions_for_sale.length > 1 ? t('other_placeholder') : currencyInput.value(0)}
            delay={350}
            format={currencyInput.format}
            match={currencyInput.match}
            sc={{ pl: product.promotions_for_sale.length > 1 ? 8 : 4 }}
            disabled={quantities.products[product.id] + availability.promotions[defaultPromotion.id] === 0}
            ref={inputRef}
          />
        </UI.InputWrapper>
      </UI.Div>

      {minQuantity === 0 && (
        <UI.Div>
          <UI.Radio
            checked={
              // The 'no thanks' option is checked if the amount is zero, and a donation choice has been stored in the
              // checkout session. However, when upgrading existing registrations via the participant dashboard, we
              // don't force the participant to make a donation choice (i.e., choose between an amount and 'no thanks'),
              // so in that case this option will be checked by default.
              !amount && (typeof donate === 'undefined' || typeof donate[product.id] !== 'undefined')
            }
            onChange={() => cancel()}
            sc={{
              box: true,
              block: true,
              noWrap: true,
            }}
          >
            {t('no_thank_you')}
          </UI.Radio>
        </UI.Div>
      )}
    </UI.GridContainer>
  );
};

const insertUpgrade = (
  upgrades: CreateUpgradeInput[],
  product: Product,
  promotion: Promotion,
  productVariant: ProductVariant | undefined,
  quantity: number,
) => {
  const sameUpgrades: CreateUpgradeInput[] = [];
  const otherUpgrades: CreateUpgradeInput[] = [];

  upgrades.forEach((upgrade) => {
    const isExistingUpgrade = upgrade.purchase.promotion.id === promotion.id
      && (!productVariant || upgrade.product_variant?.id === productVariant.id);

    if (isExistingUpgrade) {
      if (sameUpgrades.length < quantity) {
        sameUpgrades.push(upgrade);
      }
    } else {
      otherUpgrades.push(upgrade);
    }
  });

  const missingQuantity = quantity - sameUpgrades.length;

  return [
    ...otherUpgrades,
    ...sameUpgrades,
    ...times(missingQuantity, () => ({
      product: { id: product.id },
      ...(productVariant ? { product_variant: { id: productVariant.id } } : {}),
      purchase: {
        promotion: { id: promotion.id },
      },
      fields: [],
    })),
  ];
};

/**
 * Inserts a given donation product into the list of upgrades.
 */
const insertDonation = (
  upgrades: CreateUpgradeInput[],
  product: Product,
  promotion: Promotion,
  amount: number,
) => {
  const promotionIds = product.promotions_for_sale.map(({ id }) => id);
  const otherUpgrades = upgrades.filter((upgrade) => (
    !promotionIds.includes(upgrade.purchase.promotion.id)
  ));

  if (amount > 0) {
    return [
      ...otherUpgrades,
      {
        product: { id: product.id },
        purchase: {
          promotion: { id: promotion.id },
          amount,
        },
        fields: [],
      },
    ];
  }

  return otherUpgrades;
};

export default ProductSelector;
