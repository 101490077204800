import { useTranslation } from 'react-i18next';
import React from 'react';

import CountryOptions from '../../Common/CountryOptions';
import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';

export interface NationalityInputValue {
  nationality?: string | null;
}

const NationalityInput = ({
  value, onChange, onBlur, touched, required = true, errors,
}: ParticipantAttributeInputProps<NationalityInputValue>) => {
  const { t } = useTranslation('common');

  return (
    <UI.InputGroup sc={{ valid: !errors?.nationality, touched: touched?.nationality }}>
      <UI.InputLabel htmlFor="ParticipantNationality">
        {t('participant_attributes.nationality')}
        {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
        )}
      </UI.InputLabel>
      <UI.Select
        value={value.nationality || ''}
        onChange={(event) => onChange({ nationality: event.target.value || null })}
        onBlur={() => onBlur?.('nationality')}
        id="ParticipantNationality"
      >
        <option value=""> </option>
        <CountryOptions />
      </UI.Select>
      <UI.ErrorMessages
        attribute={t('participant_attributes.nationality')}
        errors={errors?.nationality}
      />
    </UI.InputGroup>
  );
};

export default NationalityInput;
