import { EventType, OrderPlacedPayload } from '../events';
import useEventHandler from '../../Common/useEventHandler';

interface UseOrderPlacedEventHandlerProps {
  fbq?: (action: string, event: string, data: object) => void;
  trackEvent?: (event: string, data: object) => void;
  trackPageView?: (path?: string) => void;
}

const useOrderPlacedEventHandler = ({ fbq, trackEvent, trackPageView }: UseOrderPlacedEventHandlerProps) => {
  const handleOrderPlacedEvent = (payload: OrderPlacedPayload) => {
    // Track with Facebook Pixel
    if (fbq) {
      const itemIds = [] as string[];
      const items = [] as any[];

      const evaluatePurchase = (purchase: any) => {
        itemIds.push(purchase.id);
        items.push({
          id: purchase.id,
          quantity: purchase.quantity,
          price: purchase.amount / 100,
        });
      };

      payload.tickets.forEach(evaluatePurchase);
      payload.products.forEach(evaluatePurchase);

      fbq('track', 'Purchase', {
        // Custom properties
        event_id: payload.event.id,
        event_title: payload.event.title,
        // Object properties
        currency: 'EUR',
        value: payload.order.amount / 100,
        content_type: 'product',
        content_ids: itemIds,
        contents: items,
      });
    }

    // Track with Google Analytics
    if (trackEvent) {
      // Migrate ecommerce data from UA to GA4: https://support.google.com/analytics/answer/10119380
      const GAPurchaseData = {
        // GA4 + UA parameters
        transaction_id: payload.order.id,
        currency: 'EUR',
        value: payload.order.amount / 100,
        tax: payload.order.vat_amount / 100,
        coupon: payload.order.coupon,
        affiliation: 'Atleta',
        items: [] as any[],
      };

      payload.tickets.forEach((purchase) => {
        GAPurchaseData.items.push({
          // GA4 parameters
          item_id: purchase.id,
          item_name: purchase.title,
          item_brand: payload.event.title,
          item_category: payload.event.title,
          item_category2: 'Tickets',
          item_variant: purchase.time_slot,
          currency: 'EUR',
          affiliation: 'Atleta',
          // UA parameters
          id: purchase.id,
          name: purchase.title,
          brand: payload.event.title,
          category: `${payload.event.title}/Tickets`,
          variant: purchase.time_slot,
          // GA4 + UA parameters
          price: purchase.amount / 100,
          quantity: purchase.quantity,
        });
      });

      payload.products.forEach((purchase) => {
        GAPurchaseData.items.push({
          // GA4 parameters
          item_id: purchase.id,
          item_name: purchase.title,
          item_brand: payload.event.title,
          item_category: payload.event.title,
          item_category2: 'Products',
          item_variant: purchase.product_variant,
          currency: 'EUR',
          affiliation: 'Atleta',
          // UA parameters
          id: purchase.id,
          name: purchase.title,
          brand: payload.event.title,
          category: `${payload.event.title}/Products`,
          variant: purchase.product_variant,
          // GA4 + UA parameters
          price: purchase.amount / 100,
          quantity: purchase.quantity,
        });
      });

      trackEvent('purchase', GAPurchaseData);
    }

    if (trackPageView) {
      trackPageView(`/atleta/success/${payload.event.id}`);
    }
  };

  useEventHandler(EventType.OrderPlaced, handleOrderPlacedEvent);
};

export default useOrderPlacedEventHandler;
