import { createGlobalStyle, css } from 'styled-components';

const DefaultStyle = createGlobalStyle`
  ${({ theme }: any) => css`
    body {
      background: ${theme.mix(theme.colors.secondary[50], theme.colors.gray[50], 0.9)} !important;
      transition: background .3s ease-in-out;
    }
  `}
`;

export default DefaultStyle;
