import gql from 'graphql-tag';

export default gql`
  query GetCharity($id: ID!) {
    charity(id: $id) {
      id
      title
      logo_url
      description
    }
  }
`;
