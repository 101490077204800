import { DocumentNode } from 'graphql';

import { MutationHookOptions, MutationTuple, OperationVariables,
  useMutation as useApolloMutation } from '@apollo/client';

function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode, options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  options = options || {};

  return useApolloMutation<TData, TVariables>(mutation, options);
}

export default useMutation;
