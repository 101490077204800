import gql from 'graphql-tag';

import {
  ParticipantFieldFieldsFragment,
} from '../../participant/Dashboard/Registrations/GetRegistrationDetailsQuery';

export default gql`
  query GetResellableRegistration($registration_id: ID!, $resale_token: String!, $event_id: ID!, $start_reselling: Boolean!) {
    resellableRegistration(registration_id: $registration_id, resale_token: $resale_token, start_reselling: $start_reselling) {
      id
      resale {
        id
        resellable
        available
        amount
        total_amount
        fee
        public_url
        public_token
        time_left
      }
      required_attributes
      ticket {
        id
        title
        description
        image {
          id
          url
        }
      }
      promotion {
        id
        title
      }
      time_slot {
        id
        start_date
        start_time
        title
        multi_date
      }
      participant_fields {
        ...ParticipantFieldFields
      }
      upgrades {
        id
        resale_amount
        product {
          id
          title
          is_ticket_fee
          donation
          active_product_variants {
            id
            title
            is_sold_out
            position
          }
        }
        promotion {
          id
          title
        }
        product_variant {
          id
          title
          is_sold_out
          position
        }
        participant_fields {
          ...ParticipantFieldFields
        }
      }
    }
    event(id: $event_id) {
      id
      enable_fundraising
      require_fundraising
      allow_other_charity
      charities {
        id
        title
        logo_url
        description
      }
      payment_methods
      require_company_for_bank_transfer
      terms {
        id
        url
      }
      terms_url
      terms_text
      project {
        id
        organisation_country
      }
      locale
    }
  }

  ${ParticipantFieldFieldsFragment}
`;
