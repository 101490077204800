import gql from 'graphql-tag';

export default gql`
  query GetOrderSummary($input: OrderSummaryInput!) {
    orderSummary(input: $input) {
      id
      amount
      vat_amount
      passed_on_fee
      passed_on_fee_vat_amount
      paid_amount
      remaining_amount
      due_date
      invoice_id
      first_name
      email
      assignee_emails
      unassigned_tickets_count
      created_fundraising_campaign
      tickets {
        quantity
        purchase {
          id
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          ticket {
            id
            title
          }
          time_slot {
            id
            start_date
            start_time
            multi_date
            title
          }
        }
      }
      products {
        quantity
        purchase {
          id
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          product {
            id
            title
            is_ticket_fee
          }
          product_variant {
            id
            title
          }
        }
      }
      event {
        id
        title
        confirmation_text
        start_date
        end_date
        logo {
          id
          url
        }
        location {
          id
          title
          description
          address
          coordinates
        }
        website_url
        checkout_url
        email
        brand_color
        active
      }
    }
  }
`;
