import * as yup from 'yup';
import { Search, User, UserPlus, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { ErrorBag } from '../helpers';
import {
  GetTeamsForEvent, GetTeamsForEvent_event_available_teams_data,
} from './__generated__/GetTeamsForEvent';
import { TeamInput } from '../../__generated__/globalTypes';
import UI from '../UI';
import useLazyQuery from '../../api/useLazyQuery';
import useValidation from '../useValidation';

export enum TeamMode {
  Individual = 'individual',
  Create = 'create',
  Join = 'join',
}

export const GetTeamsForEventQuery = gql`
  query GetTeamsForEvent($eventId: ID!, $search: String) {
    event(id: $eventId) {
      id
      available_teams(per_page: 100, search: $search) {
        data {
          id
          title
        }
      }
    }
  }
`;

const teamSchema = yup.object().shape({
  title: yup.string().required(),
});

export interface TeamPickerProps {
  team?: TeamInput | null;
  eventId: string;
  allowIndividuals: boolean;
  allowCreateTeam: boolean;
  allowJoinTeam: boolean;
  onChange: (team: TeamInput | null, mode: TeamMode) => void;
  touched?: {
    title?: boolean;
    search?: boolean;
  };
  touch?: (field: string) => void;
  untouch?: (field?: string) => void;
  errors?: ErrorBag;
  renderCreateTeamButton?: () => ReactNode;
}

const TeamPicker = ({
  team, eventId, allowIndividuals = true, allowCreateTeam = true, allowJoinTeam = true, onChange, touch, untouch,
  touched, errors: externalErrors, renderCreateTeamButton,
}: TeamPickerProps) => {
  const { t } = useTranslation('common');

  const [originalTeam] = useState(team);
  const [search, setSearch] = useState<string>();
  const [teams, setTeams] = useState<GetTeamsForEvent_event_available_teams_data[]>([]);

  const [getTeams, { data }] = useLazyQuery<GetTeamsForEvent>(
    GetTeamsForEventQuery,
    {
      onCompleted: (data) => {
        setTeams(data.event.available_teams.data);
      },
    },
  );

  const [teamMode, setTeamMode] = useState(() => {
    if (team?.title || team?.id) {
      return team.title ? TeamMode.Create : TeamMode.Join;
    }

    if (allowIndividuals) {
      return TeamMode.Individual;
    }

    if (allowCreateTeam) {
      return TeamMode.Create;
    }

    if (allowJoinTeam) {
      return TeamMode.Join;
    }

    return null;
  });

  const participateIndividually = () => {
    setTeamMode(TeamMode.Individual);
    onChange(null, TeamMode.Individual);
    untouch?.();
  };

  const createTeam = () => {
    setTeamMode(TeamMode.Create);
    onChange({ title: '' }, TeamMode.Create);
    untouch?.();
  };

  const joinTeam = () => {
    setTeamMode(TeamMode.Join);
    setTeams([]);
    setSearch('');
    onChange({}, TeamMode.Join);
    untouch?.();
  };

  const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    onChange({}, teamMode);
  };

  const { errors } = useValidation({
    schema: teamSchema,
    values: team,
    externalErrors,
  });

  const handleTitleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ title: value }, teamMode);
  };

  useEffect(() => {
    if (allowJoinTeam && teamMode === TeamMode.Join && search) {
      getTeams({
        variables: {
          eventId,
          search,
        },
      });
    }
  }, [allowJoinTeam, teamMode, eventId, search, getTeams]);

  const showRadioButtons = (allowIndividuals ? 1 : 0) + (allowCreateTeam ? 1 : 0)
    + (allowJoinTeam ? 1 : 0) > 1;

  return (
    <UI.FormGrid>
      {!team?.id && (
        <>
          {showRadioButtons && (
            <UI.InputGroup sc={{ mb: teamMode === TeamMode.Individual ? 0 : undefined }}>
              <UI.FormGrid sc={{ gutter: 0.25 }}>
                {allowIndividuals && (
                  <UI.Radio
                    name={`team.${TeamMode.Individual}`}
                    onChange={() => participateIndividually()}
                    checked={teamMode === TeamMode.Individual}
                    sc={{ box: true }}
                  >
                    <UI.Div sc={{ inlineBlock: true }}>
                      <UI.FormGrid sc={{ columns: '24px 1fr', gutter: 0.5 }}>
                        <UI.Strong sc={{ textAlign: 'center' }}>
                          <UI.Icon>
                            <User />
                          </UI.Icon>
                        </UI.Strong>
                        <UI.Div>
                          <UI.Strong sc={{ block: true }}>
                            {t('team_picker.participate_individually')}
                          </UI.Strong>
                          {t('team_picker.team_mode_individual_description')}
                        </UI.Div>
                      </UI.FormGrid>
                    </UI.Div>
                  </UI.Radio>
                )}
                {allowJoinTeam && (
                  <UI.Radio
                    name={`team.${TeamMode.Join}`}
                    onChange={() => joinTeam()}
                    checked={teamMode === TeamMode.Join}
                    sc={{ box: true }}
                  >
                    <UI.Div sc={{ inlineBlock: true }}>
                      <UI.FormGrid sc={{ columns: '24px 1fr', gutter: 0.5 }}>
                        <UI.Strong sc={{ textAlign: 'center' }}>
                          <UI.Icon>
                            <UserPlus />
                          </UI.Icon>
                        </UI.Strong>
                        <UI.Div>
                          <UI.Strong sc={{ block: true }}>
                            {t('team_picker.join_existing_team')}
                          </UI.Strong>
                          {t('team_picker.team_mode_join_description')}
                        </UI.Div>
                      </UI.FormGrid>
                    </UI.Div>
                  </UI.Radio>
                )}
                {allowCreateTeam && (
                  <UI.Radio
                    onChange={() => createTeam()}
                    name={`team.${TeamMode.Create}`}
                    sc={{ box: true }}
                    checked={teamMode === TeamMode.Create}
                  >
                    <UI.Div sc={{ inlineBlock: true }}>
                      <UI.FormGrid sc={{ columns: '24px 1fr', gutter: 0.5 }}>
                        <UI.Strong sc={{ textAlign: 'center' }}>
                          <UI.Icon>
                            <Users />
                          </UI.Icon>
                        </UI.Strong>
                        <UI.Div>
                          <UI.Strong sc={{ block: true }}>
                            {t('team_picker.create_new_team')}
                          </UI.Strong>
                          {t('team_picker.team_mode_create_description')}
                        </UI.Div>
                      </UI.FormGrid>
                    </UI.Div>
                  </UI.Radio>
                )}
              </UI.FormGrid>
            </UI.InputGroup>
          )}

          {teamMode === TeamMode.Create && (
            <UI.InputGroup sc={{ valid: !errors?.title, touched: touched?.title, mb: 0 }}>
              <UI.InputLabel htmlFor="TeamTitle">
                {t('team_picker.team_name')}
                {' '}
                <UI.RequiredMark />
              </UI.InputLabel>
              <UI.FormGrid sc={{ columns: renderCreateTeamButton ? '1fr fit-content(150px)' : '1fr', gutter: 0.5 }}>
                <UI.Div>
                  <UI.DebouncedInput
                    type="text"
                    value={team?.title || ''}
                    onChange={handleTitleChange}
                    name="team.title"
                    onBlur={() => touch?.('title')}
                    id="TeamTitle"
                  />
                </UI.Div>
                {renderCreateTeamButton && (
                  <UI.Div>{renderCreateTeamButton()}</UI.Div>
                )}
              </UI.FormGrid>
              <UI.ErrorMessages attribute={t('team_picker.team_name')} errors={errors.title} />
            </UI.InputGroup>
          )}

          {teamMode === TeamMode.Join && (
            <UI.FormGrid sc={{ gutter: 0.5 }}>
              <UI.InputGroup sc={{ valid: !!search, touched: touched?.search, mb: !search ? 0 : undefined }}>
                <UI.InputLabel htmlFor="TeamSearch">
                  {t('team_picker.find_a_team')}
                  {' '}
                  <UI.RequiredMark />
                </UI.InputLabel>
                <UI.InputWrapper>
                  <UI.Span>
                    <UI.Icon>
                      <Search />
                    </UI.Icon>
                  </UI.Span>
                  <UI.DebouncedInput
                    value={search}
                    onChange={handleSearch}
                    name="team.search"
                    onBlur={() => touch?.('search')}
                    sc={{ pl: 5 }}
                    id="TeamSearch"
                  />
                </UI.InputWrapper>
              </UI.InputGroup>

              {search && (
                <UI.FormGrid sc={{ gutter: 0.25 }}>
                  {teams.map((candidate) => (
                    <UI.Checkbox
                      onChange={() => onChange({ id: candidate.id, title: candidate.title }, teamMode)}
                      sc={{ box: true }}
                      key={candidate.id}
                    >
                      <UI.Strong>
                        <UI.Icon>
                          <Users />
                        </UI.Icon>
                        {' '}
                        {candidate.title}
                      </UI.Strong>
                    </UI.Checkbox>
                  ))}

                  {data && teams.length === 0 && t('team_picker.no_teams_found')}
                </UI.FormGrid>
              )}

              {!search && originalTeam?.id && (
                <UI.Checkbox
                  onChange={() => onChange({ id: originalTeam.id, title: originalTeam.title }, teamMode)}
                  sc={{ box: true }}
                >
                  <UI.Strong>
                    <UI.Icon>
                      <Users />
                    </UI.Icon>
                    {' '}
                    {originalTeam.title}
                  </UI.Strong>
                </UI.Checkbox>
              )}
            </UI.FormGrid>
          )}
        </>
      )}
      {team?.id && (
        <>
          {allowIndividuals && (
            <UI.Checkbox checked onChange={() => participateIndividually()} sc={{ box: true, strong: true }}>
              <UI.Icon>
                <Users />
              </UI.Icon>
                {' '}
                {team.title}
            </UI.Checkbox>
          )}
          {!allowIndividuals && (
            <UI.Span>
              <UI.Icon>
                <Users />
              </UI.Icon>
                {' '}
                {team.title}
            </UI.Span>
          )}
        </>
      )}
    </UI.FormGrid>
  );
};

export default TeamPicker;
