import { ApolloError, MutationFunctionOptions } from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import React, { ReactNode, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { AddOrderStatus, CouponStatus, FundraisingMode } from '../../__generated__/globalTypes';
import { Charity } from '../../common/Fundraising/CharityPicker';
import {
  CheckoutStep, CouponCode, Event, Queue, SuccessStep, TicketCategory, getBusinessProducts, getKeyedSellables,
  getStandAloneProducts, getTeamFlags, getTicketsForSale, getVisibleProducts,
} from './helpers';
import { CompleteCheckout, CompleteCheckoutVariables } from './__generated__/CompleteCheckout';
import { CookieConsentContext } from '../../common/Common/CookieConsentProvider';
import { EmbedContext } from '../Common/EmbedProvider';
import { ErrorBag, containsKeyPrefix, getServerErrors } from '../../common/helpers';
import { useCheckoutSummary } from './CheckoutSummaryProvider';
import { useEvent } from './EventProvider';
import CheckoutContext from './CheckoutContext';
import CompleteCheckoutMutation from './CompleteCheckoutMutation';
import StateContext from './StateContext';
import useCheckoutStepEventHandler from './Tracking/useCheckoutStepEventHandler';
import useCheckoutStepTracking from './Tracking/useCheckoutStepTracking';
import useCheckoutTracking from './Tracking/useCheckoutTracking';
import useCheckoutUrlState from './useCheckoutUrlState';
import useFacebookPixel from '../../common/useFacebookPixel';
import useGoogleAnalytics from '../../common/useGoogleAnalytics';
import useLocale from '../../common/useLocale';
import useMemoizedValue from '../../common/useMemoizedValue';
import useMutation from '../../api/useMutation';
import useOrderAcceptedTracking from './Tracking/useOrderAcceptedTracking';
import useOrderPlacedEventHandler from './Tracking/useOrderPlacedEventHandler';
import usePaymentMethods from '../../common/usePaymentMethods';
import usePersonalisationInfo from './usePersonalisationInfo';
import usePreselectedCharity from './usePreselectedCharity';
import useProject from '../Common/useProject';
import useQuantities from './useQuantities';
import useValidators from './useValidators';

const hasErrors = (errors: ErrorBag, activeStepName: CheckoutStep): boolean => {
  if (activeStepName === CheckoutStep.Tickets) {
    return containsKeyPrefix(errors, 'tickets')
      || containsKeyPrefix(errors, 'time_slots');
  }

  if (activeStepName === CheckoutStep.Personalisation) {
    return containsKeyPrefix(errors, 'registrations.create');
  }

  if (activeStepName === CheckoutStep.Extras) {
    return containsKeyPrefix(errors, 'registrations.stand_alone_upgrades');
  }

  if (activeStepName === CheckoutStep.Team) {
    return containsKeyPrefix(errors, 'team');
  }

  if (activeStepName === CheckoutStep.Details) {
    return containsKeyPrefix(errors, 'participant') || containsKeyPrefix(errors, 'fields');
  }

  if (activeStepName === CheckoutStep.Payment) {
    return containsKeyPrefix(errors, 'coupon');
  }

  return isEmpty(errors);
};

export interface CheckoutProps {
  event: Event;
  ticketCategories: TicketCategory[];
  checkoutSteps: CheckoutStep[];
  activeStepIndex: number;
  nextRegistration: () => boolean;
  valid: boolean[];
  completeCheckout: (options?: MutationFunctionOptions<CompleteCheckout, Record<string, any>>) => (
    Promise<ExecutionResult<CompleteCheckout>>
  );
  loading?: boolean;
  error?: ApolloError;
  status?: AddOrderStatus;
  couponCode?: CouponCode;
  preselectedCharity?: Charity | null;
  queue?: Queue;
}

export interface CheckoutProviderProps {
  render: (props: CheckoutProps) => ReactNode;
}

const CheckoutProvider = memo(({ render }: CheckoutProviderProps) => {
  const { search } = useLocation();
  const { locale } = useLocale();
  const { cookieConsent } = useContext(CookieConsentContext);
  const { embedded, open: embedOpen } = useContext(EmbedContext);
  const { event, ticketCategories, refetch } = useEvent();
  const history = useHistory();
  const project = useProject();

  const state = useContext(StateContext);

  const { form, session, promotionIds, setActiveRegistration } = state;

  /** URL params on successfully placing an order. */
  const [{ orderId, orderToken, sourceUrl }] = useCheckoutUrlState();

  // Preselects a charity if a charity ID is present in the URL.
  const { preselectedCharity, hasPreselectedCharity } = usePreselectedCharity();

  /** The quantities and availabilities of tickets, products, promotions, time slots and product variants. */
  const { quantities, availability } = useQuantities({ form, event });

  /** Flags that indicate whether or not teams may be created. */
  const { allowIndividuals, allowCreateTeam, allowJoinTeam } = getTeamFlags(
    getTicketsForSale(event).filter((ticket) => !ticket.business && quantities.tickets[ticket.id] > 0) || [],
  );

  /** Tickets corresponding to all registrations in the form state. */
  const selectedTickets = useMemo(() => {
    const tickets = getTicketsForSale(event);
    const keyedTickets = getKeyedSellables(tickets);

    return uniq(promotionIds.map((id) => keyedTickets[id]).filter((ticket) => ticket));
  }, [promotionIds, event]);

  /** All products that can be bought with business tickets. */
  const businessProducts = useMemo(() => (
    getBusinessProducts(selectedTickets, event.products_for_sale)
  ), [selectedTickets, event.products_for_sale]);

  /** All products that can be bought independently of tickets. */
  const standAloneProducts = useMemo(() => (
    getStandAloneProducts(selectedTickets, [], event.products_for_sale)
  ), [selectedTickets, event.products_for_sale]);

  /** Info about which registrations are personalisable, skipped, etc. */
  const personalisation = usePersonalisationInfo({ form, event, session });

  /** The personalisation step should be shown if there are personalisable registrations. */
  const showPersonalisationStep = personalisation.personalisableRegistrations.length > 0;

  /** Show the extras if there are selectable business tickets or stand-alone products. */
  const showExtrasStep = getVisibleProducts(businessProducts).length > 0
    || getVisibleProducts(standAloneProducts).length > 0;

  /** Show the team form if creating or joining teams is allowed. */
  const showTeamStep = (allowCreateTeam || allowJoinTeam) && !event.invitation_code?.invitation.team;

  /** Show the fundraising form if fundraising is allowed. */
  const showFundraisingStep = !hasPreselectedCharity
    // Fundraising is enabled
    && event.enable_fundraising
    // Fundraiser may be creatuing during registration
    && event.fundraising_mode === FundraisingMode.during_registration
    // If fundraising is required and there is exactly 1 charity to choose from, don't show the form.
    && !(event.require_fundraising && !event.allow_other_charity && event.charities.length === 1)
    // Only show it if buying personal tickets.
    && personalisation.personalRegistrations.length > 0;

  const checkoutSteps = useMemo(() => [
    CheckoutStep.Tickets,
    ...(showPersonalisationStep ? [CheckoutStep.Personalisation] : []),
    ...(showExtrasStep ? [CheckoutStep.Extras] : []),
    ...(showTeamStep ? [CheckoutStep.Team] : []),
    CheckoutStep.Details,
    ...(showFundraisingStep ? [CheckoutStep.Fundraising] : []),
    CheckoutStep.Payment,
  ], [showPersonalisationStep, showExtrasStep, showTeamStep, showFundraisingStep]);

  const { params: { step } } = useRouteMatch<{
    step?: CheckoutStep | typeof SuccessStep;
  }>('/:eventId/:step?');

  const success = step === SuccessStep;
  const activeStepName = step || checkoutSteps[0];
  const activeStepIndex = checkoutSteps.indexOf(activeStepName as CheckoutStep);

  // Load trackers
  const { trackEvent, trackPageView } = useGoogleAnalytics({
    enabled: !embedded,
    propertyId: project.google_analytics,
    trackPageViews: false,
  });

  const fbq = useFacebookPixel({
    enabled: !embedded && cookieConsent,
    pixelId: project.facebook_pixel,
    trackPageViews: false,
  });

  // Dispatch and handle tracking events
  useCheckoutStepTracking(activeStepName);
  useCheckoutStepEventHandler({
    fbq,
    trackPageView,
  });

  const orderSummary = useOrderAcceptedTracking({
    event,
    activate: success,
    orderId,
    orderToken,
  });
  useOrderPlacedEventHandler({
    fbq,
    trackEvent,
    trackPageView,
  });

  const requireInvoiceDetails = form.registrations.business.length > 0;
  const invoiceDetails = session.invoiceDetails || requireInvoiceDetails ? form.invoice : null;

  // Used to disable the 'Pay' button while the browser is redirecting the user, to
  // prevent the user from creating another payment in the mean time.
  // Unfortunately we can't simply disable the button if completeCheckoutData is present,
  // because this state object is restored by Safari on pressing the browser back button.
  // In that case, the user should be able to resubmit the checkout.
  const [redirecting, setRedirecting] = useState(false);

  const [
    completeCheckout, { error, loading: submitting, data: completeCheckoutData },
  ] = useMutation<CompleteCheckout, CompleteCheckoutVariables>(
    CompleteCheckoutMutation,
    {
      variables: {
        input: {
          ...form,
          participant: {
            ...form.participant, // Contains mail opt-ins
            email: personalisation.buyer.email,
            first_name: personalisation.buyer.first_name,
            last_name: personalisation.buyer.last_name,
          },
          registrations: {
            create: form.registrations.create.map((registration, index) => ({
              ...registration,
              // Unset the assignee fields when the registration is unassigned or skipped.
              ...(!registration.participant || !personalisation.personalisedFormIndices.includes(index) ? {
                participant: null,
                details: null,
                fields: null,
              } : {}),
            })),
            business: form.registrations.business,
            stand_alone_upgrades: form.registrations.stand_alone_upgrades,
          },
          invoice: invoiceDetails,
          payment_method: form.payment_method.payment_method ? form.payment_method : null,
          team: form.team,
          // URL to redirect back to the site when using the embed script.
          source_url: sourceUrl,
          locale,
        },
      },
      onCompleted: ({ completeCheckout: { redirect_url, status } }) => {
        if (status === AddOrderStatus.SUCCESS) {
          if (redirect_url) {
            // Disable the 'Pay' button while redirecting.
            setRedirecting(true);

            if (embedded) {
              // Use window.parent, because in Cypress window.top is the spec runner window.
              window.parent.location.href = redirect_url;
            } else {
              window.location.href = redirect_url;
            }
          }
        }
      },
      onError: (apolloError) => {
        const serverErrors = getServerErrors(apolloError);

        const errorStepName = checkoutSteps.filter(
          (stepName) => hasErrors(serverErrors, stepName),
        )[0];

        if (errorStepName) {
          history.push(`/${event.id}/${errorStepName}${search}`);
        }

        // Refetch event, tickets might be sold out (refetchQueries doesn't run on error)
        refetch();

        throw new Error();
      },
    },
  );

  // After 10 seconds, re-enable the 'Pay' button. The user is most likely long gone at this point,
  // but if they use the back button of their browser, they should be able to resubmit the form.
  useEffect(() => {
    if (redirecting) {
      const timer = window.setTimeout(() => {
        setRedirecting(false);
      }, 10000);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [redirecting]);

  const loading = submitting || redirecting;

  const status = completeCheckoutData?.completeCheckout.status;

  const { checkoutSummary } = useCheckoutSummary();

  const paymentMethods = usePaymentMethods({
    // Do not take country from the invoice details if it's only present because it has been pre-filled.
    country: (invoiceDetails?.zip_code && invoiceDetails?.country)
      || personalisation.personalRegistrations[0]?.details?.country,
    enabledPaymentMethods: event.payment_methods,
    bankTransferAllowed: !!invoiceDetails?.company_name || !event.require_company_for_bank_transfer,
  });

  const validators = useValidators({
    form,
    session,
    event,
    personalisation,
    standAloneProducts,
    requireInvoiceDetails,
    checkoutSummary,
    paymentMethods,
    error,
  });

  /**
   * An array whose elements indicate whether the checkout step is valid. As
   * soon as one step is found to be invalid, all future steps become invalid.
   * The memoization ensures that a new object is only returned when any of
   * the flags change. Without this memoization, the entire CheckoutPage would
   * rerender on each change event (ticket added, etc.).
   */
  const valid = useMemoizedValue(checkoutSteps.reduce((valid, name) => (
    [...valid, !valid.includes(false) && validators[name].valid]
  ), [] as boolean[]));

  const shouldGoToNextRegistration = activeStepName === CheckoutStep.Personalisation
    && personalisation.nextIndex < personalisation.personalisableRegistrations.length;

  const nextRegistration = useCallback(() => {
    if (shouldGoToNextRegistration) {
      setActiveRegistration(personalisation.nextIndex);

      return true;
    }

    return false;
  }, [shouldGoToNextRegistration, personalisation.nextIndex, setActiveRegistration]);

  /**
   * By memoizing the entire context value, we prevent that children get rerendered when this component rerenders,
   * if the elements of the context value did not change.
   */
  const context = useMemo(
    () => ({
      ...state,
      businessProducts,
      standAloneProducts,
      personalisation,
      allowIndividuals,
      allowCreateTeam,
      allowJoinTeam,
      requireInvoiceDetails,
      validators,
      quantities,
      availability,
      error,
      paymentMethods,
      orderSummary,
    }),
    [
      state,
      businessProducts,
      standAloneProducts,
      personalisation,
      allowIndividuals,
      allowCreateTeam,
      allowJoinTeam,
      requireInvoiceDetails,
      validators,
      quantities,
      availability,
      error,
      paymentMethods,
      orderSummary,
    ],
  );

  useCheckoutTracking({
    form,
    queue: checkoutSummary.queue,
    step: activeStepName,
    stepIndex: activeStepIndex,
    valid,
    personalisation,
    embedded,
    embedOpen,
  });

  // Make sure that these props are memoized to prevent unnecessary rerenders
  const props = {
    event,
    ticketCategories,
    checkoutSteps,
    activeStepIndex,
    nextRegistration,
    completeCheckout,
    loading,
    error,
    status,
    valid,
    preselectedCharity,
    couponCode: checkoutSummary.coupon_status === CouponStatus.active ? checkoutSummary.coupon_code : undefined,
    queue: checkoutSummary.queue,
  };

  return (
    <CheckoutContext.Provider value={context}>
      {render(props)}
    </CheckoutContext.Provider>
  );
});

CheckoutProvider.displayName = 'CheckoutProvider';

export default CheckoutProvider;
