import { ChangeEvent, DetailedHTMLProps, FocusEvent, InputHTMLAttributes, ReactElement } from 'react';
import { FormManager } from 'react-form-state-manager';

import { BackgroundProps, Size, SpacingProps, UIProps } from '../../theme/mixins';
import { DateTimeFormat } from '../../config';
import SC from './SC';

export type HtmlInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps extends BackgroundProps, SpacingProps {
  size?: Size;
  placeholder?: boolean;
  color?: string;
}

export interface DatePickerProps extends SC<UIProps> {
  selected: string;
  internalFormat?: DateTimeFormat;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  maxDate?: string;
  minTime?: string;
  maxTime?: string;
  placeholder?: string;
  dateFormat?: string;
  timeFormat?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  id?: string;
  onChange: (value: string | null, event: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (event: FocusEvent) => void;
}

export interface EditorProps {
  form: FormManager;
  name: string;
  ariaLabel?: string;
  translateButton?: ReactElement<HTMLElement>;
  variables?: string[];
  variablesLimit?: number;
}

export interface GoogleLocationInputProps {
  value: GoogleLocation;
  onChange: (location: GoogleLocation) => any;
  id?: string | null;
  placeholder?: string | null;
}

interface GoogleLocation {
  id: string | null;
  title: string | null;
  description: string | null;
  address: string | null;
  coordinates: number[];
}

export interface PhoneNumberInputProps extends SC<UIProps> {
  value: string;
  country: string;
  onChange: (result: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
}

export const ENTER_KEY = 13;
export const BACKSPACE_KEY = 8;
