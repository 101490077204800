import React, { ReactNode, createContext, useContext } from 'react';

import { Gender } from '../__generated__/globalTypes';

export interface Project {
  id: string;
  organisation_name: string;
  organisation_country?: string;
  brand_color?: string;
  genders: Gender[];
}

export const ProjectContext = createContext<Project | null>(null);

interface ProjectProviderProps {
  project: Project;
  children: ReactNode;
}

export const ProjectProvider = ({ project, children }: ProjectProviderProps) => (
  <ProjectContext.Provider value={project}>
    {children}
  </ProjectContext.Provider>
);

const useProject = () => useContext(ProjectContext);

export default useProject;
