import { format as dateFnFormat, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { de, enUS, fr, nl } from 'date-fns/locale';
import { isValid, parse, startOfDay } from 'date-fns';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import subMinutes from 'date-fns/subMinutes';

import { LocaleContext } from './LocaleProvider';
import config, { DateTimeFormat } from '../config';

const localeCodes = {
  en: 'en-US',
  nl: 'nl',
  de: 'de',
  fr: 'fr',
};

const dateFnLocales = {
  en: enUS,
  nl,
  de,
  fr,
};

const decimalSeparators = {
  en: '.',
  nl: ',',
  de: ',',
  fr: ',',
};

interface ParseDateOptions {
  format?: DateTimeFormat;
  timezone?: string | null;
}

interface FormatDateOptions {
  format?: DateTimeFormat;
  timezone?: string;
}

interface TodayOptions {
  timezone?: string;
}

const useLocale = () => {
  const { t } = useTranslation('common');
  const locale = useContext(LocaleContext);

  /**
   * Creates a Date object from a formatted string.
   *
   * @param timezone The timezone of the given date. Project timezone by default.
   *                 If you pass `null`, no conversion will be made.
   */
  const parseDate = useCallback((
    date: string,
    {
      format = 'internal_date_time',
      timezone = locale.timezone,
    }: ParseDateOptions = {},
  ) => {
    const parsedDate = parse(date, locale.dateFormats[format], new Date());

    if (!timezone) {
      return parsedDate;
    }

    return zonedTimeToUtc(parsedDate, timezone);
  }, [locale.dateFormats, locale.timezone]);

  /**
   * Converts a Date object into a formatted string.
   *
   * @param timezone The timezone in which the date should be formatted. Project timezone by
   *                 default. If you pass `null`, no conversion will be made.
   */
  const formatDate = (
    date: Date,
    {
      format = 'display_date_time',
      timezone = locale.timezone,
    }: FormatDateOptions = {},
  ) => {
    const parsedDate = timezone ? utcToZonedTime(date, timezone) : date;

    return dateFnFormat(parsedDate, locale.dateFormats[format], {
      locale: dateFnLocales[locale.locale],
      timeZone: timezone,
    });
  };

  /**
   * Creates a Date object initialized to today's date in the project's timezone, in UTC.
   *
   * @param timezone The timezone of the given date. Project timezone by default.
   */
  const today = ({ timezone = locale.timezone }: TodayOptions = {}) => {
    const offset = getTimezoneOffset(timezone) / 1000 / 60;
    return subMinutes(startOfDay(utcToZonedTime(new Date(), timezone)), offset);
  };

  const dateIsValid = (date: string, format: DateTimeFormat = 'internal_date_time') => (
    isValid(parse(date, locale.dateFormats[format], new Date()))
  );

  const formatNumber = (
    value: number,
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 2, useGrouping: true },
  ) => (
    value.toLocaleString(localeCodes[locale.locale], options)
  );

  const parseNumber = (value: string) => (
    parseFloat(value
      // Strip everything that is not a digit, decimal separator, or dash
      .replace(new RegExp(`[^\\d${decimalSeparators[locale.locale]}-]`, 'g'), '')
      // Make sure the decimal separator is a dot
      .replace(/[,]/g, '.'))
  );

  const formatInteger = (
    value: number,
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 0, useGrouping: true },
  ) => (
    value.toLocaleString(localeCodes[locale.locale], options)
  );

  const parseInteger = (value: string) => (
    Math.floor(parseNumber(value))
  );

  const formatCurrency = (
    value: number,
    {
      currency = 'EUR',
      style = 'currency',
      minimumFractionDigits = 2,
      maximumFractionDigits = 2,
      ...options
    }: Intl.NumberFormatOptions = {},
  ) => (
    (value / 100)
      .toLocaleString(localeCodes[locale.locale], {
        currency, style, minimumFractionDigits, maximumFractionDigits, ...options,
      })
      .replace(/\s/g, ' ')
      .replace('€', '€ ')
      .replace('€  ', '€ ')
      .replace('€ -', '- € ')
      .replace('-€', '- €')
      .trim()
  );

  const parseCurrency = (value: any) => {
    // Remove everything but digits, commas and dots.
    const stripped = value.replace(/[^\d,.]/g, '');

    // Check if it ends with one or two decimals.
    const oneDecimal = stripped.match(/(,|\.)\d{1}$/);
    const twoDecimals = stripped.match(/(,|\.)\d{2}$/);

    // Remove all non-digits
    const digits = parseInteger(stripped.replace(/[^\d]/g, ''));

    const result = twoDecimals ? digits : (oneDecimal ? digits * 10 : digits * 100);

    const sign = value.startsWith('-') ? -1 : 1;

    return sign * result;
  };

  // List of locales, sorted by language name.
  const sortedLocales = sortBy(config.locales, (locale) => t(`locales.${locale}`));

  return {
    ...locale,
    sortedLocales,
    parseDate,
    formatDate,
    today,
    dateIsValid,
    formatNumber,
    parseNumber,
    formatInteger,
    parseInteger,
    formatCurrency,
    parseCurrency,
  };
};

export default useLocale;
