import { LayoutGroup, motion } from 'framer-motion';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode, Ref, RefObject, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Button } from './Button';
import { Div, Span } from './Generic';

interface ToggleBarProps {
  children: ReactNode;
}

const ToggleBar = ({ children }: ToggleBarProps) => (
  <ToggleBarContainer>
    <LayoutGroup id="ToggleBarLayout">
      {children}
    </LayoutGroup>
  </ToggleBarContainer>
);

const ToggleBarContainer = styled(Div)`
  ${({ theme }) => css`
    display: inline-block;
    padding: 4px;
    border-radius: ${theme.borderRadiuses.xl}px;
    background: ${theme.transparentize(theme.colors.gray[900], 0.2)};
    backdrop-filter: saturate(180%) blur(20px);

    > ${Span} {
      display: inline-block;
      position: relative;

      ${Button} {
        border-radius: ${theme.borderRadiuses.xl - 3}px;
        position: relative;
        z-index: 2;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: ${theme.colors.white} !important;

        &.active {
          color: ${theme.colors.gray[800]} !important;
        }
      }
    }
  `}
`;

// eslint-disable-next-line max-len
interface ToggleBarButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  active?: boolean;
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
}

export const ToggleBarButton = motion(
  forwardRef(({ active = false, children, ...props }: ToggleBarButtonProps, ref: Ref<any>) => (
    <Span ref={ref}>
      <Button {...props} className={active ? 'active' : undefined}>
        {children}
      </Button>
      {active && <ToggleBarButtonBackdrop initial={false} layoutId="backdrop" />}
    </Span>
  )),
);

const ToggleBarButtonBackdrop = motion(
  styled(Span)`
    ${({ theme }) => css`
      padding: 12px;
      background: ${theme.colors.white};
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${theme.borderRadiuses.xl - 3}px;
      display: block;
    `}
  `,
);

export default ToggleBar;
