import { useEffect } from 'react';

import { CheckoutStepPayload, EventType, dispatch } from '../events';
import { useEvent } from '../EventProvider';

const useCheckoutStepTracking = (step: string) => {
  const { event } = useEvent();

  useEffect(() => {
    dispatch(EventType.CheckoutStep, {
      event: {
        id: event.id,
        title: event.title,
      },
      step,
    } as CheckoutStepPayload);
  }, [event, step]);
};

export default useCheckoutStepTracking;
