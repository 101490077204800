import Color from 'color';
import React from 'react';

import { Div } from './Generic';
import { GridContainer } from './Container';
import { Strong } from './Text';
import theme from '../../theme';

const BrandColors = () => {
  const colors = ['primary', 'secondary', 'tertiary', 'gray'];
  const shades = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  return (
    <GridContainer sc={{ columns: '1fr 1fr 1fr 1fr', gutter: 0, padding: 4, background: 'white' }}>
      {colors.map((color) => (
        <Div key={color}>
          {shades.map((shade) => (
            <Div
              key={shade}
              sc={{
                background: `${color}.${shade}`,
                padding: 2,
                textAlign: 'center',
              }}
              style={{ fontSize: '.75rem' }}
            >
              <Strong style={{
                color: theme.getTitleColor(theme.getColor(`${color}.${shade}`)),
              }}
              >
                {color.toUpperCase()}
                {' • '}
                {shade}
              </Strong>
              <br />
              {(new Color(((theme.colors as any)[color] as any)[shade] as string)).hex()}
            </Div>
          ))}
        </Div>
      ))}
    </GridContainer>
  );
};

export default BrandColors;
