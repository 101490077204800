import gql from 'graphql-tag';

export default gql`
  query GetExternalValidationResult($input: ExternalValidationInput!) {
    participantFieldValidation(input: $input) {
      success
      message
      suggestion
    }
  }
`;
