import styled, { css } from 'styled-components';

import { Div } from './Generic';
import SC from './SC';

interface FadeInProps {
  when?: boolean;
}

const FadeIn = styled(Div)<SC<FadeInProps>>`
  ${({ sc: { when = true } = {} }) => css`
    ${when && css`
      animation: fade-in 0.25s ease-in-out;
    `}

    @keyframes fade-in {
      0% { opacity: 0; }
      40% { opacity: 0; }
      100% { opacity: 1; }
    }
  `}
`;

export default FadeIn;
