import { ApolloError } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  GetExternalValidationResult, GetExternalValidationResultVariables,
} from './__generated__/GetExternalValidationResult';
import { ParticipantField, ParticipantFieldEntryInput } from '../ParticipantFieldInputProps';
import { RegistrationInput } from '../../../__generated__/globalTypes';
import GetExternalValidationResultQuery from './GetExternalValidationResultQuery';
import useLocale from '../../useLocale';
import useQuery from '../../../api/useQuery';

export interface UseExternalValidationProps {
  field: ParticipantField;
  input: ParticipantFieldEntryInput;
  enabled: boolean;
  timeout?: number;
  registration?: RegistrationInput;
}

/**
 * Only runs if a registration is given.
 */
const useExternalValidation = ({
  field,
  input,
  enabled,
  timeout = 500,
  registration,
}: UseExternalValidationProps) => {
  const { locale } = useLocale();

  const [data, setData] = useState<GetExternalValidationResult | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);

  const currentVariables = useMemo(() => ({
    input: {
      participant_field: { id: field.id },
      value: input.value,
      choices: input.choices,
      registration: registration ? {
        id: registration.id,
        ticket: registration.ticket,
        time_slot: registration.time_slot,
        purchase: registration.purchase,
        upgrades: registration.upgrades,
        team: registration.team,
        participant: registration.participant,
        details: registration.details,
        charity: registration.charity,
        fields: registration.fields,
      } : null,
      locale,
    },
  }), [field.id, input.value, input.choices, registration, locale]);

  /**
   * Debounced variables are initially null so that the query is not run
   * with outdated variables when `enabled` becomes true.
   */
  const [debouncedVariables, setDebouncedVariables] = useState<typeof currentVariables | null>(null);

  const { loading } = useQuery<GetExternalValidationResult, GetExternalValidationResultVariables>(
    GetExternalValidationResultQuery,
    {
      variables: debouncedVariables,
      skip: !debouncedVariables,
      onCompleted: (data) => {
        setData(data);
        setError(null);
      },
      onError: (error) => {
        setData(null);
        setError(error);
      },
    },
  );

  const valueRef = useRef<string | null>(currentVariables.input.value);
  const wasAutoCompletedRef = useRef(false);

  useEffect(() => {
    if (data) {
      const { suggestion } = data.participantFieldValidation;
      const { value } = currentVariables.input;
      // The current value was autocompleted if it was previously empty and it now equals the latest suggestion
      wasAutoCompletedRef.current = suggestion && !valueRef.current && value === suggestion;
      valueRef.current = value;
    }
  }, [currentVariables, data]);

  // Only run validation (after a delay) if the current value wasn't autocompleted (and validation is enabled)
  const shouldRunValidation = !wasAutoCompletedRef.current && enabled && field.has_external_validation
    && currentVariables.input.registration;

  useEffect(() => {
    if (shouldRunValidation) {
      const timer = window.setTimeout(() => {
        setDebouncedVariables(currentVariables);
      }, timeout);

      return () => window.clearTimeout(timer);
    }

    wasAutoCompletedRef.current = false;
    setDebouncedVariables(null);

    return () => undefined;
  }, [currentVariables, timeout, shouldRunValidation]);

  useEffect(() => {
    // Unset the validation result after resetting the value.
    if (!currentVariables.input.value && (currentVariables.input.choices || []).length === 0) {
      setError(null);
      setData(null);
    }
  }, [currentVariables, error]);

  const result = data?.participantFieldValidation;

  return {
    ...result,
    error: debouncedVariables?.input.value || debouncedVariables?.input.choices?.length > 0 ? error : undefined,
    // Start validating before the validation request actually started (when the variables are debounced)
    validating: loading || (shouldRunValidation && currentVariables !== debouncedVariables),
    input: debouncedVariables?.input,
  };
};

export default useExternalValidation;
