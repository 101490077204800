import gql from 'graphql-tag';

export default gql`
  query GetRegistrationsForSale($id: ID!, $tickets: [String!], $limit: Int!) {
    event(id: $id) {
      id
      registrations_for_sale_count
      filtered_registrations_for_sale_count: registrations_for_sale_count(tickets: $tickets)
      sold_registrations_count
      tickets_for_resale {
        id
        title
      }
      registrations_for_sale(tickets: $tickets, limit: $limit) {
        id
        ticket {
          id
          title
        }
        ticket {
          id
          title
        }
        time_slot {
          id
          start_date
          start_time
          title
          multi_date
        }
        promotion {
          id
          title
        }
        upgrades {
          id
          product {
            id
            title
            is_ticket_fee
          }
          product_variant {
            id
            title
          }
        }
        resale {
          id
          available
          total_amount
          fee
          public_url
          public_token
        }
      }
    }
  }
`;
