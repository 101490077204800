import React, { ReactNode, createContext, useCallback, useContext } from 'react';
import useFacebookPixel from '../useFacebookPixel';
import useGoogleAnalytics from '../useGoogleAnalytics';

import { CookieConsentContext } from './CookieConsentProvider';
import { inProductionEnvironment, stripSensitiveUrlParams } from '../helpers';
import config from '../../config';

interface AtletaTrackingProviderContextValue {
  track: (url: string) => void;
}

const AtletaTrackingProviderContext = createContext<AtletaTrackingProviderContextValue>(undefined);

interface AtletaTrackingProviderProps {
  facebookPixel?: boolean;
  googleAnalytics?: boolean;
  filterPath?: (url: string) => string;
  children?: ReactNode;
}

const AtletaTrackingProvider = ({
  facebookPixel = false, googleAnalytics = false, filterPath = (url) => url, children,
}: AtletaTrackingProviderProps) => {
  const { cookieConsent } = useContext(CookieConsentContext);

  const enable = inProductionEnvironment();

  const { trackPageView } = useGoogleAnalytics({
    enabled: !!(enable && googleAnalytics),
    propertyId: config.googleAnalyticsId,
    filterPath: (path) => filterPath(stripSensitiveUrlParams(path)),
    cookiePrefix: 'atleta', // Projects using cross-domain tracking shouldn't overwrite our cookie.
  });

  useFacebookPixel({
    enabled: !!(enable && facebookPixel && cookieConsent),
    pixelId: config.facebookPixelId,
  });

  const track = useCallback((path: string) => {
    trackPageView(`/atleta/${path}`);
  }, [trackPageView]);

  return (
    <AtletaTrackingProviderContext.Provider value={{ track }}>
      {children}
    </AtletaTrackingProviderContext.Provider>
  );
};

export const useAtletaTracking = () => useContext(AtletaTrackingProviderContext);

export default AtletaTrackingProvider;
