import React, { Fragment, ReactNode } from 'react';

import { Span } from './Generic';

interface DelimitProps {
  delimiter?: ReactNode;
  children: ReactNode[];
}

const Delimit = ({ delimiter, children }: DelimitProps) => (
  <>
    {children.filter((child) => child).map((child, index) => (
      <Fragment key={index}>
        {index > 0 && (delimiter || <Span sc={{ muted: true }}> • </Span>)}
        {child}
      </Fragment>
    ))}
  </>
);

export default Delimit;
