import React from 'react';

import UI from '../UI';
import useLocale from '../useLocale';

export interface DateRangeProps {
  start: string | null;
  end?: string | null;
}

/**
 * @param start UTC start date.
 * @param end UTC end date.
 */
const DateRange = ({ start, end }: DateRangeProps) => {
  const { formatDate, parseDate } = useLocale();

  const startDate = parseDate(start, { timezone: 'UTC' });
  const endDate = end ? parseDate(end, { timezone: 'UTC' }) : null;

  const sameDay = endDate && (
    formatDate(startDate, { format: 'internal_date' })
      === formatDate(endDate, { format: 'internal_date' })
  );

  const startTime = formatDate(startDate, { format: 'internal_time' });
  const endTime = endDate ? formatDate(endDate, { format: 'internal_time' }) : null;
  const sameTime = startTime === endTime;

  if (sameDay) {
    return (
      <>
        {formatDate(startDate, { format: 'display_date' })}
        {sameTime && startTime !== '00:00:00' && (
          `, ${formatDate(startDate, { format: 'display_time' })}`
        )}
        {!sameTime && (
          <>
            {', '}
            {formatDate(startDate, { format: 'display_time' })}
            <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
            {formatDate(endDate, { format: 'display_time' })}
          </>
        )}
      </>
    );
  }

  if (startDate && endDate) {
    const format = startTime === '00:00:00' && endTime === '00:00:00'
      ? 'display_date' : 'display_date_time';

    return (
      <>
        {formatDate(startDate, { format })}
        <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
        {formatDate(endDate, { format })}
      </>
    );
  }

  const format = startTime === '00:00:00' ? 'display_date' : 'display_date_time';

  return <>{formatDate(startDate, { format })}</>;
};

export default DateRange;
