import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { ErrorBag } from '../helpers';
import CharityPicker, { Charity } from './CharityPicker';
import UI from '../UI';

export interface FundraisingFormProps {
  charity?: Charity | null;
  eventId: string;
  onChange: (charity: Charity | null) => void;
  charities?: Charity[];
  allowOtherCharity?: boolean;
  required?: boolean;
  touched?: any;
  errors?: ErrorBag;
}

const FundraisingForm = ({
  charity: selectedCharity,
  eventId,
  onChange,
  charities = [],
  allowOtherCharity = true,
  required = false,
  touched,
  errors,
}: FundraisingFormProps) => {
  const { t } = useTranslation('common');

  const [showCharityPicker, setShowCharityPicker] = useState(() => (
    allowOtherCharity && (
      charities.length === 0 || (!!selectedCharity && !charities.map(({ id }) => id).includes(selectedCharity.id))
    )
  ));

  const toggleCharityPicker = () => {
    setShowCharityPicker((open) => !open);
    setCharity(null);
  };

  const skip = () => {
    setCharity(null);
    setShowCharityPicker(false);
  };

  const setCharity = (charity: Charity | null) => {
    if (charity !== selectedCharity) {
      onChange(charity);
    }

    if (charity && charities.map(({ id }) => id).includes(charity.id)) {
      // Hide the charity picker if the selected charity is a preferred charity
      setShowCharityPicker(false);
    }
  };

  if (!allowOtherCharity && charities.length === 1) {
    return (
      <>
        <UI.Card sc={{ background: 'gray.75', outline: 'gray.75', padding: 3 }}>
          <UI.GridContainer
            sc={{
              columns: charities[0].logo_url ? '40px 1fr' : '1fr',
              gutter: 0.75,
              alignVertical: !charities[0].description ? 'center' : undefined,
            }}
          >
            {charities[0].logo_url && (
              <UI.Div sc={{ mt: charities[0].description ? 0.5 : 0 }}>
                <img
                  src={charities[0].logo_url}
                  alt={charities[0].title}
                  style={{ display: 'block', width: 40 }}
                />
              </UI.Div>
            )}
            <UI.Div>
              <UI.Strong sc={{ block: true, color: 'gray.800' }}>
                {charities[0].title}
              </UI.Strong>
              {charities[0].description && (
                <UI.InputDescription sc={{ color: 'gray.500' }}>
                  {charities[0].description}
                </UI.InputDescription>
              )}
            </UI.Div>
          </UI.GridContainer>
        </UI.Card>
        <UI.InputGroup sc={{ valid: !errors?.charity, touched: !!touched?.charity }}>
          <UI.InputLabel htmlFor="FundraisingCheckbox">
            {t('fundraising_form.support_charity_label', { charity: charities[0].title })}
          </UI.InputLabel>
          <UI.InputDescription>
            {t('fundraising_form.fundraising_description')}
          </UI.InputDescription>
          <UI.Checkbox
            checked={selectedCharity?.id === charities[0].id}
            onChange={() => setCharity(selectedCharity?.id !== charities[0].id ? charities[0] : null)}
            id="FundraisingCheckbox"
          >
            {t('fundraising_form.yes_create_fundraising_page')}
          </UI.Checkbox>
          <UI.ErrorMessages attribute={t('fundraising_form.charity')} errors={errors?.charity} />
        </UI.InputGroup>
      </>
    );
  }

  return (
    <>
      {(!required || charities.length > 0) && (
        <UI.InputGroup sc={{ valid: !errors?.charity, touched: !!touched?.charity }}>
          {!required && (
            <UI.InputLabel htmlFor="Charity_0">
              {t('fundraising_form.support_charity_optional_label')}
            </UI.InputLabel>
          )}
          {required && (
            <UI.InputLabel>
              {t('fundraising_form.support_charity_required_label')}
              {' '}
              <UI.RequiredMark />
            </UI.InputLabel>
          )}
          <UI.InputDescription>
            {t('fundraising_form.fundraising_description')}
          </UI.InputDescription>
          {charities.length > 0 && (
            <UI.FormGrid sc={{ gutter: charities.length > 0 ? 0.25 : 0, mt: 2 }}>
              {charities.map((charity, index) => (
                <UI.Radio
                  checked={selectedCharity?.id === charity.id}
                  onChange={() => setCharity(charity)}
                  sc={{
                    box: true,
                    radioTop: selectedCharity?.id === charity.id && charity.logo_url ? 23 : undefined,
                    radioPositionV: selectedCharity?.id !== charity.id && charity.logo_url ? 'center' : undefined,
                  }}
                  id={`Charity_${index}`}
                  key={charity.id}
                >
                  <UI.GridContainer
                    sc={{
                      columns: charity.logo_url ? '44px 1fr' : '1fr',
                      alignVertical: !charity.description ? 'center' : undefined,
                      gutter: 0.25,
                    }}
                  >
                    {charity.logo_url && (
                      <UI.Div sc={{ padding: 0.75 }}>
                        <img
                          src={charity.logo_url}
                          alt={charity.title}
                          style={{ display: 'block', objectFit: 'contain', width: 35, height: 35 }}
                        />
                      </UI.Div>
                    )}
                    <UI.Div style={{ minWidth: 0 }}>
                      <UI.Span style={{ fontWeight: 500 }}>
                        {charity.title}
                      </UI.Span>
                      {charity.description && (
                        <UI.InputDescription sc={{ color: 'gray.500' }}>
                          {selectedCharity?.id !== charity.id ? (
                            <UI.Truncate>
                              {charity.description}
                            </UI.Truncate>
                          ) : charity.description}
                        </UI.InputDescription>
                      )}
                    </UI.Div>
                  </UI.GridContainer>
                </UI.Radio>
              ))}
              {allowOtherCharity && (
                <UI.Radio
                  checked={showCharityPicker}
                  onChange={() => toggleCharityPicker()}
                  sc={{ box: charities.length > 0 }}
                >
                  {t('fundraising_form.choose_another_charity')}
                </UI.Radio>
              )}
              {!required && (
                <UI.Radio
                  checked={!showCharityPicker && !selectedCharity}
                  onChange={() => skip()}
                  sc={{ box: true }}
                >
                  {t('fundraising_form.no_thank_you')}
                </UI.Radio>
              )}
            </UI.FormGrid>
          )}
          {allowOtherCharity && charities.length > 0 && (
            <UI.ErrorMessages attribute={t('fundraising_form.charity')} errors={errors?.charity} />
          )}
        </UI.InputGroup>
      )}
      {showCharityPicker && (
        <CharityPicker
          charity={selectedCharity}
          eventId={eventId}
          onChange={setCharity}
          required={required}
          description={required && (charities.length === 0 || !allowOtherCharity) && (
            <UI.InputDescription>
              {t('fundraising_form.fundraising_description')}
            </UI.InputDescription>
          )}
          errors={errors}
          touched={touched}
        />
      )}
    </>
  );
};

export default FundraisingForm;
