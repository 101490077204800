import { FlattenSimpleInterpolation, css } from 'styled-components';

import { ArrayResponsiveProp, ResponsiveProp, SimpleProp } from './helpers';

export interface SpacingProps {
  mt?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  mr?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  mb?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  ml?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  mx?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  my?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  margin?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  pt?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  pr?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  pb?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  pl?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  px?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  py?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  padding?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  spaceX?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  spaceY?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
}

export function useSpacing(this: any, props: SpacingProps): FlattenSimpleInterpolation {
  return css`
    ${typeof props.mt !== 'undefined' && this.responsiveProp(props.mt, (mt: number) => css`
      margin-top: ${(mt / 4) * this.gutter}px !important;
    `)}

    ${typeof props.mr !== 'undefined' && this.responsiveProp(props.mr, (mr: number) => css`
      margin-right: ${(mr / 4) * this.gutter}px !important;
    `)}

    ${typeof props.mb !== 'undefined' && this.responsiveProp(props.mb, (mb: number) => css`
      margin-bottom: ${(mb / 4) * this.gutter}px !important;
    `)}

    ${typeof props.ml !== 'undefined' && this.responsiveProp(props.ml, (ml: number) => css`
      margin-left: ${(ml / 4) * this.gutter}px !important;
    `)}

    ${typeof props.mx !== 'undefined' && this.responsiveProp(props.mx, (mx: number) => css`
      margin-left: ${(mx / 4) * this.gutter}px !important;
      margin-right: ${(mx / 4) * this.gutter}px !important;
    `)}

    ${typeof props.my !== 'undefined' && this.responsiveProp(props.my, (my: number) => css`
      margin-top: ${(my / 4) * this.gutter}px !important;
      margin-bottom: ${(my / 4) * this.gutter}px !important;
    `)}

    ${typeof props.margin !== 'undefined' && this.responsiveProp(props.margin, (margin: number) => css`
      margin: ${(margin / 4) * this.gutter}px !important;
    `)}

    ${typeof props.pt !== 'undefined' && this.responsiveProp(props.pt, (pt: number) => css`
      padding-top: ${(pt / 4) * this.gutter}px !important;
    `)}

    ${typeof props.pr !== 'undefined' && this.responsiveProp(props.pr, (pr: number) => css`
      padding-right: ${(pr / 4) * this.gutter}px !important;
    `)}

    ${typeof props.pb !== 'undefined' && this.responsiveProp(props.pb, (pb: number) => css`
      padding-bottom: ${(pb / 4) * this.gutter}px !important;
    `)}

    ${typeof props.pl !== 'undefined' && this.responsiveProp(props.pl, (pl: number) => css`
      padding-left: ${(pl / 4) * this.gutter}px !important;
    `)}

    ${typeof props.px !== 'undefined' && this.responsiveProp(props.px, (px: number) => css`
      padding-left: ${(px / 4) * this.gutter}px !important;
      padding-right: ${(px / 4) * this.gutter}px !important;
    `)}

    ${typeof props.py !== 'undefined' && this.responsiveProp(props.py, (py: number) => css`
      padding-top: ${(py / 4) * this.gutter}px !important;
      padding-bottom: ${(py / 4) * this.gutter}px !important;
    `)}

    ${typeof props.padding !== 'undefined' && this.responsiveProp(props.padding, (padding: number) => css`
      padding: ${(padding / 4) * this.gutter}px !important;
    `)}

    ${typeof props.spaceX !== 'undefined' && this.responsiveProp(props.spaceX, (spaceX: number) => css`
      > *:not(:last-child) {
        margin-right: ${(spaceX / 4) * this.gutter}px !important;
      }
    `)}

    ${typeof props.spaceY !== 'undefined' && this.responsiveProp(props.spaceY, (spaceY: number) => css`
      > *:not(:last-child) {
        margin-bottom: ${(spaceY / 4) * this.gutter}px !important;
      }
    `)}
  `;
}

export interface FlexProps {
  flex?: {
    direction?: SimpleProp<string> | ResponsiveProp<string> | ArrayResponsiveProp<string>;
    justifyContent?: SimpleProp<string> | ResponsiveProp<string> | ArrayResponsiveProp<string>;
    alignItems?: SimpleProp<string> | ResponsiveProp<string> | ArrayResponsiveProp<string>;
    wrap?: SimpleProp<string> | ResponsiveProp<string> | ArrayResponsiveProp<string>;
  };
  flexSelf?: SimpleProp<string> | ResponsiveProp<string>;
}

export interface FlexSelfProps {
  flexSelf?: SimpleProp<string> | ResponsiveProp<string>;
}

export interface BackgroundProps {
  background?: string;
}

export function useBackground(
  this: any, { background }: BackgroundProps,
): FlattenSimpleInterpolation | null {
  if (background) {
    return css`
      background: ${this.getColor(background)} !important;
    `;
  }

  return null;
}

export interface GridChildProps {
  order?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  // eslint-disable-next-line max-len
  column?: SimpleProp<string | number> | ResponsiveProp<string | number> | ArrayResponsiveProp<string | number>;
  // eslint-disable-next-line max-len
  row?: SimpleProp<string | number> | ResponsiveProp<string | number> | ArrayResponsiveProp<string | number>;
}

export function useGridChild(this: any, props: GridChildProps) {
  return css`
    ${typeof props.order !== 'undefined' && this.responsiveProp(props.order, (order: number) => css`
      order: ${order || 'initial'};
    `)}

    ${typeof props.column !== 'undefined' && this.responsiveProp(props.column, (column: string | number) => css`
      grid-column: ${column || '1fr'};
    `)}

    ${typeof props.row !== 'undefined' && this.responsiveProp(props.row, (row: string | number) => css`
      grid-row: ${row || '1fr'};
    `)}
  `;
}

export type GridAlignment = 'start' | 'center' | 'end';

export interface GridProps {
  columns?: SimpleProp<string> | ArrayResponsiveProp<string>;
  autoColumns?: SimpleProp<boolean> | ArrayResponsiveProp<boolean>;
  rowTemplate?: string;
  gutter?: SimpleProp<number> | ArrayResponsiveProp<number>;
  alignVertical?: SimpleProp<GridAlignment> | ArrayResponsiveProp<GridAlignment>;
  alignHorizontal?: SimpleProp<GridAlignment> | ArrayResponsiveProp<GridAlignment>;
  rowMaxHeight?: SimpleProp<string> | ResponsiveProp<string>;
  separatorV?: boolean;
}

export function useGrid(this: any, props: GridProps): FlattenSimpleInterpolation | null {
  return css`
    ${typeof props?.rowMaxHeight !== 'undefined' && this.responsiveProp(props?.rowMaxHeight, (height: string) => css`
      grid-auto-rows: minmax(auto, ${height});

      > * {
        height: 100%;
      }
    `)};

    ${props?.rowTemplate && css`
      grid-template-rows: ${props?.rowTemplate};
    `};

    ${typeof props?.alignVertical !== 'undefined' && this.responsiveProp(props.alignVertical, (alignVertical: GridAlignment) => css`
      align-items: ${alignVertical};
    `)}

    ${typeof props?.alignHorizontal !== 'undefined' && this.responsiveProp(props.alignHorizontal, (alignHorizontal: GridAlignment) => css`
      justify-content: ${alignHorizontal};
    `)}

    ${typeof props?.columns !== 'undefined' && this.responsiveProp(props?.columns, (cols: string) => css`
      grid-template-columns: ${cols};
    `)}

    ${typeof props?.autoColumns && this.responsiveProp(props?.autoColumns, (autoColumns: string) => autoColumns && css`
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
    `)}

    ${props?.separatorV && css`
      > *:not(:first-child) {
        border-top: 1px solid ${this.colors.gray[200]};
      }
    `}

    ${this.responsiveProp(props?.gutter, (gutter?: number) => css`
      @media ${this.devices.sm} {
        grid-gap: ${this.gutter * (typeof gutter === 'number' ? gutter : 0.75)}px;

        ${props?.separatorV && css`
          > *:not(:first-child) {
            padding-top: ${this.gutter * (typeof gutter === 'number' ? gutter : 0.75)}px;
          }
        `}
      }

      @media ${this.devices.md} {
        grid-gap: ${this.gutter * (typeof gutter === 'number' ? gutter : 1)}px;

        ${props?.separatorV && css`
          > *:not(:first-child) {
            padding-top: ${this.gutter * (typeof gutter === 'number' ? gutter : 1)}px;
          }
        `}
      }
    `)}
  `;
}

export function useFlex(
  this: any,
  { flex }: FlexProps,
): FlattenSimpleInterpolation | null {
  if (flex) {
    return this.responsiveProp(flex, (props: FlexProps['flex']) => css`
      display: flex;

      ${typeof props.direction !== 'undefined' && this.responsiveProp(props.direction, (direction: string) => css`
        flex-direction: ${direction} !important;
      `)}

      ${typeof props.wrap !== 'undefined' && this.responsiveProp(props.wrap, (wrap: string) => css`
        flex-wrap: ${wrap} !important;
      `)}

      ${typeof props.justifyContent !== 'undefined' && this.responsiveProp(props.justifyContent, (justifyContent: string) => css`
        justify-content: ${justifyContent} !important;
      `)}

      ${typeof props.alignItems !== 'undefined' && this.responsiveProp(props.alignItems, (alignItems: string) => css`
        align-items: ${alignItems} !important;
      `)}
    `);
  }

  return null;
}

export function useFlexSelf(
  this: any, { flexSelf }: FlexSelfProps,
): FlattenSimpleInterpolation | null {
  if (flexSelf) {
    return this.responsiveProp(flexSelf, (flexProp: string) => css`
      flex: ${flexProp};
    `);
  }

  return null;
}

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Tuple<T> = [T, T, T, T, T];

export function useSizes(
  this: any, size: Size, property: string, sizes: Tuple<string>,
): FlattenSimpleInterpolation {
  return css`
    ${size === 'xs' && css`
      ${property}: ${sizes[0]};
    `}

    ${size === 'sm' && css`
      ${property}: ${sizes[1]};
    `}

    ${(!size || size === 'md') && css`
      ${property}: ${sizes[2]};
    `}

    ${size === 'lg' && css`
      ${property}: ${sizes[3]};
    `}

    ${size === 'xl' && css`
      ${property}: ${sizes[4]};
    `}
  `;
}

export interface TextProps {
  textAlign?: SimpleProp<string> | ArrayResponsiveProp<string>;
  big?: SimpleProp<boolean> | ArrayResponsiveProp<boolean>;
  small?: SimpleProp<boolean> | ResponsiveProp<boolean> | ArrayResponsiveProp<boolean>;
  upper?: boolean;
  color?: string;
  deleted?: boolean;
  italic?: boolean;
  noWrap?: boolean;
  fontWeight?: number;
  fontSize?: SimpleProp<number> | ArrayResponsiveProp<number>;
  code?: SimpleProp<boolean> | ArrayResponsiveProp<boolean>;
}

export interface WidthProps {
  width?: SimpleProp<number | string> | ArrayResponsiveProp<number | string>;
  maxWidth?: SimpleProp<number | string> | ArrayResponsiveProp<number | string>;
}

export function useWidth(this: any, props: WidthProps): FlattenSimpleInterpolation {
  return css`
    ${props.width && this.responsiveProp(props.width, (width: number | string) => css`
      width: ${this.evaluateWidth(width)};
    `)}

    ${props.maxWidth && this.responsiveProp(props.maxWidth, (width: number | string) => css`
      max-width: ${this.evaluateWidth(width)};
    `)}
  `;
}

export function useText(this: any, props: TextProps): FlattenSimpleInterpolation {
  return css`
    ${typeof props.textAlign !== 'undefined' && this.responsiveProp(props.textAlign, (textAlign: string) => css`
      text-align: ${textAlign};
    `)}

    ${typeof props.big !== 'undefined' && this.responsiveProp(props.big, (big: boolean) => css`
      font-size: ${big ? 1.25 : 1}em;
    `)}

    ${typeof props.small !== 'undefined' && this.responsiveProp(props.small, (small: boolean) => css`
      font-size: ${small ? '0.875' : 1}em;
    `)}

    ${props.fontWeight && css`
      font-weight: ${props.fontWeight} !important;
    `}

    ${props.upper && css`
      text-transform: uppercase;
    `}

    ${props.color && css`
      color: ${this.getColor(props.color)} !important;
    `}

    ${props.deleted && css`
      text-decoration: line-through;
    `}

    ${props.italic && css`
      font-style: italic;
    `}

    ${props.noWrap && css`
      white-space: nowrap;
    `}

    ${props.fontSize && this.responsiveProp(props.fontSize, (sizeIndex: number) => css`
      font-size: ${(Object.values(this.fontSizes) as [any])[sizeIndex]}rem;
    `)}

    ${props.code && css`
      color: ${this.colors.code};
      font-family: ${this.fontFamilies.code};
      font-size: 0.9em;
    `}
  `;
}

export interface DisplayProps {
  block?: SimpleProp<boolean> | ResponsiveProp<boolean> | ArrayResponsiveProp<boolean>;
  inlineBlock?: SimpleProp<boolean> | ResponsiveProp<boolean> | ArrayResponsiveProp<boolean>;
  inline?: SimpleProp<boolean> | ResponsiveProp<boolean> | ArrayResponsiveProp<boolean>;
}

export function useDisplay(this: any, props: DisplayProps): FlattenSimpleInterpolation {
  return css`
    ${typeof props.block !== 'undefined' && this.responsiveProp(props.block, (value: boolean) => css`
      ${value && 'display: block !important;'}
    `)}

    ${typeof props.inlineBlock !== 'undefined' && this.responsiveProp(props.inlineBlock, (value: boolean) => css`
      ${value && 'display: inline-block !important;'}
    `)}

    ${typeof props.inline !== 'undefined' && this.responsiveProp(props.inline, (value: boolean) => css`
      ${value && 'display: inline !important;'}
    `)}
  `;
}

export interface PositionProps {
  relative?: boolean;
  static?: boolean;
  absolute?: boolean;
}

export function usePosition(this: any, props: PositionProps): FlattenSimpleInterpolation {
  return css`
    ${props.relative && css`
      position: relative !important;
    `}

    ${props.static && css`
      position: static !important;
    `}

    ${props.absolute && css`
      position: absolute !important;
    `}
  `;
}

export interface BorderProps {
  borderRadius?: SimpleProp<Size> | ResponsiveProp<Size> | ArrayResponsiveProp<Size>;
}

export function useBorder(this: any, props: BorderProps): FlattenSimpleInterpolation {
  return css`
    ${props.borderRadius && css`
      ${typeof props.borderRadius && this.responsiveProp(props.borderRadius, (borderRadius: Size) => css`
        border-radius: ${this.borderRadiuses[borderRadius]}px !important;
      `)}
    `}
  `;
}
export interface BoxShadowProps {
  boxShadow?: SimpleProp<Size> | ResponsiveProp<Size> | ArrayResponsiveProp<Size>;
}

export function useBoxShadow(this: any, props: BoxShadowProps): FlattenSimpleInterpolation {
  return css`
    ${props.boxShadow && css`
      ${typeof props.boxShadow && this.responsiveProp(props.boxShadow, (boxShadow: Size) => css`
        box-shadow: ${this.shadows[boxShadow]} !important;
      `)}
    `}
  `;
}

export interface UIProps extends BackgroundProps, BorderProps, BoxShadowProps, SpacingProps, FlexProps,
  TextProps, DisplayProps, WidthProps, PositionProps, GridChildProps {
  pullLeft?: boolean;
  pullRight?: boolean;
  strong?: boolean;
  muted?: boolean;
  loading?: boolean;
  deleting?: boolean;
  trimLast?: boolean;
  spin?: boolean;
}

export function useMixins(this: any, props: UIProps): FlattenSimpleInterpolation {
  return css`
    ${props && this.useBorder(props)}
    ${props && this.useBoxShadow(props)}
    ${props && this.useDisplay(props)}
    ${props && this.useSpacing(props)}
    ${props && this.useFlex(props)}
    ${props && this.useFlexSelf(props)}
    ${props && this.useBackground(props)}
    ${props && this.useText(props)}
    ${props && this.useWidth(props)}
    ${props && this.usePosition(props)}
    ${props && this.useGridChild(props)}

    ${props && props.pullLeft && css`
      float: left !important;
    `}

    ${props && props.pullRight && css`
      float: right !important;
    `}

    ${props && props.strong && css`
      font-weight: 600 !important;

      span > svg {
        // Icons
        stroke-width: 2.5;
      }
    `}

    ${props && props.muted && css`
      opacity: 0.5 !important;
    `}

    ${props?.loading && css`
      transition: opacity 0.1s ease-in-out;
      opacity: 0.6;
    `}

    ${props?.deleting && css`
      opacity: 0.3;
      transition: opacity 0.1s ease-in-out;
      pointer-events: none;
    `}

    ${props && props.trimLast && css`
      > :last-child {
        margin-bottom: 0 !important;
      }
    `}

    ${props?.spin && css`
        animation: spin linear 0.75s infinite;
    `}

    @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  `;
}

export default undefined;
