import React from 'react';
import parsePhoneNumber, { format } from 'libphonenumber-js';

import { PhoneNumberProps } from './helpers';
import useProject from '../useProject';

/**
 * Display the national format of a phone number if given country or the current
 * active project's country is equal to the country of the phone number.
 * Otherwise, display the international format of a phone number.
 *
 *  International: +31 88 366 5433
 *  National: 088 366 5433
 */
const PhoneNumber = ({ phoneNumber, country }: PhoneNumberProps) => {
  const project = useProject();

  const activeCountry = country || project.organisation_country;

  /** Info is undefined if the phone number is invalid */
  const info = parsePhoneNumber(phoneNumber);

  if (info) {
    return <>{format(phoneNumber, activeCountry === info.country ? 'NATIONAL' : 'INTERNATIONAL')}</>;
  }

  return <>{phoneNumber}</>;
};

export default PhoneNumber;
