import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';

import { decodeUrl, encodeUrl } from './helpers';

interface UrlState {
  [key: string]: any;
  [key: number]: any;
}

type UpdateUrlState<T = UrlState> = (value: T) => T;

export type SetUrlState<T = UrlState> = (
  newValue: T | UpdateUrlState<T>, replace?: boolean
) => void;

interface UseUrlProps<T = any> {
  parse: (rawState: UrlState) => T;
  onChange?: (urlState: T, rawState: any) => void;
}

const subtractObject = (base: any, other: any) => (
  Object.keys(other).reduce((result: any, key: string) => {
    if (!isEqual(base[key], other[key])) {
      result[key] = base[key];
    }

    return result;
  }, {})
);

/**
 * Compares two strings of query params and returns whether they are the same if order of the params
 * is not important.
 */
const encodedParamsAreEqual = (a: string, b: string): boolean => {
  const aParts = a.split('&');
  const bParts = b.split('&');

  return aParts.length === bParts.length && difference(aParts, bParts).length === 0;
};

function useUrlState<T = UrlState>({ parse, onChange }: UseUrlProps<T>) {
  const history = useHistory();
  const { search } = useLocation();

  const urlState = parse(decodeUrl(search.substr(1)));
  const searchRef = useRef(search);
  const defaultState = useMemo(() => parse({}), [parse]);

  useEffect(() => history.listen((location, action) => {
    if (searchRef.current !== location.search) {
      searchRef.current = location.search;

      const decodedState = decodeUrl(location.search.substr(1));

      if (action !== 'REPLACE') {
        onChange?.(parse(decodedState), decodedState);
      }
    }
    // No useEffect dependencies to prevent that the onChange handler becomes stale
  }));

  const mergeUrlParams = useCallback(
    (updateState: T | UpdateUrlState<T>) => {
      const { search } = history.location;
      const oldState = parse(decodeUrl(search.substr(1)));

      const newState = isFunction(updateState)
        ? updateState(oldState)
        : { ...oldState, ...updateState };

      const currentUrlState = subtractObject(newState, defaultState);

      return encodeUrl(currentUrlState);
    },
    [defaultState, history, parse],
  );

  const setUrlState = useCallback(
    (updateState: T | UpdateUrlState<T>, replace: boolean = false) => {
      const { search } = history.location;
      const encodedState = mergeUrlParams(updateState);

      if (!encodedParamsAreEqual(encodedState, search.substr(1))) {
        if (replace) {
          history.replace({
            search: encodedState,
          });
        } else {
          history.push({
            search: encodedState,
          });
        }
      }
    },
    [mergeUrlParams, history],
  );

  const parseUrlState = useCallback(
    (search: string) => parse(decodeUrl(search.replace('?', ''))),
    [parse],
  );
  const helpers = {
    parseUrlState,
    mergeUrlParams,
  };

  return [
    urlState,
    setUrlState,
    helpers,
  ] as [
    T,
    SetUrlState<T>,
    any,
  ];
}

export default useUrlState;
