import { CheckoutStep, SuccessStep } from './helpers';

export enum EventType {
  Initialized = 'Atleta:Initialized',
  WidgetOpened = 'Atleta:WidgetOpened',
  WidgetClosed = 'Atleta:WidgetClosed',
  CheckoutStep = 'Atleta:CheckoutStep',
  OrderPlaced = 'Atleta:OrderPlaced',
  OpenStep = 'Atleta:OpenStep',
}

interface Payload {}

export interface CheckoutStepPayload extends Payload {
  event: {
    id: string;
    title: string;
  };
  step: string;
}

export interface OrderPlacedPayload extends Payload {
  event: {
    id: string;
    title: string;
  };
  order: {
    id: string;
    amount: number;
    vat_amount: number;
    coupon: string | null;
  };
  tickets: {
    id: string,
    title: string,
    amount: number,
    quantity: number,
    time_slot: string | null,
  }[],
  products: {
    id: string,
    title: string,
    amount: number,
    quantity: number,
    product_variant: string | null,
  }[];
}

export interface OpenStepPayload extends Payload {
  event: {
    id: string;
  };
  step: CheckoutStep | typeof SuccessStep;
  params?: any;
}

export const dispatch = (type: EventType, payload?: Payload) => {
  window.parent.postMessage({
    type,
    payload,
  }, '*');
};
