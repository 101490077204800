import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import gql from 'graphql-tag';

import { AuLicenseData, parseConfig } from '../helpers';
import { GetAtletiekunieClubs } from './__generated__/GetAtletiekunieClubs';
import ParticipantFieldInputProps from '../ParticipantFieldInputProps';
import UI from '../../UI';
import useIntegerInput from '../../useIntegerInput';
import useQuery from '../../../api/useQuery';

export const GetAtletiekunieClubsQuery = gql`
  query GetAtletiekunieClubs {
    integrations {
      atletiekunie {
        clubs {
          code
          name
        }
      }
    }
  }
`;

const AuLicenseInput = ({
  value, onChange, onBlur, field, touched, errors, validating,
}: ParticipantFieldInputProps) => {
  const { t } = useTranslation('common');
  const integerInput = useIntegerInput();
  const idRef = useRef(Math.round(Math.random() * 1000000));

  const { data } = useQuery<GetAtletiekunieClubs>(GetAtletiekunieClubsQuery);

  const clubs = data?.integrations?.atletiekunie?.clubs;

  const licenseData: AuLicenseData = value.value ? JSON.parse(value.value) : {
    club_code: null,
    member_id: null,
  };

  const form = useForm({
    values: licenseData,
  });

  const parsedConfig = parseConfig(field.config);
  const requireMemberId = parsedConfig.require_member_id || false;

  /**
   * Used so that we can trigger change events only when the value has changed.
   */
  const valueRef = useRef(value.value);

  useEffect(() => {
    let newValue: string | null = null;

    if (form.values.club_code || form.values.member_id) {
      // Find selected club to append the name
      const selected = (clubs && clubs.filter((club) => (
        form.values.club_code === club.code
      ))) || [];

      newValue = JSON.stringify({
        club_code: form.values.club_code,
        member_id: form.values.member_id,
        club_name: selected.length > 0 ? selected[0].name : null,
      });
    }

    if (newValue !== valueRef.current) {
      onChange({ value: newValue });
      valueRef.current = newValue;
    }
  }, [form.values.club_code, form.values.member_id, clubs, onChange]);

  const handleBlur = () => {
    if (form.touched.club_code && form.touched.member_id) {
      onBlur?.();
    }
  };

  const clubCodes = clubs ? clubs.map((club) => club.code) : [];

  return (
    <UI.InputGroup sc={{ valid: !errors?.value, touched }}>
      <UI.FormGrid>
        <UI.InputGroup>
          <UI.InputLabel htmlFor={`Field_${idRef.current}_ClubCode`}>
            {field.title}
            {' '}
            {field.required && <UI.RequiredMark />}
            {validating && (
              <>
                {' '}
                <UI.Icon>
                  <UI.Loader sc={{ brand: 'gray.400' }} />
                </UI.Icon>
              </>
            )}
          </UI.InputLabel>
          {field.description && (
            <UI.InputDescription>
              <UI.HTML html={field.description} />
            </UI.InputDescription>
          )}
          <UI.Select
            {...form.select('club_code', [null, ...clubCodes], { onBlur: handleBlur })}
            id={`Field_${idRef.current}_ClubCode`}
            sc={{ hasValue: !!form.values.club_code }}
          >
            <option value={0}>{t('atletiekunie.club')}</option>
            {(clubs || []).map((club, index) => (
              <option {...form.option(index + 1)}>{club.name}</option>
            ))}
          </UI.Select>
          {requireMemberId && (
            <UI.DebouncedInput
              {...form.text('member_id', { inputHandler: integerInput(), onBlur: handleBlur })}
              format={integerInput().format}
              maxLength={7}
              inputMode="numeric"
              match={/[\d]/g}
              placeholder={t('atletiekunie.member_id_placeholder')}
              id={`Field_${idRef.current}_MemberId`}
              sc={{ mt: 2 }}
            />
          )}
        </UI.InputGroup>
      </UI.FormGrid>
      <UI.ErrorMessages attribute={field.title} errors={errors?.value} />
    </UI.InputGroup>
  );
};

export default AuLicenseInput;
