import * as yup from 'yup';
import { format } from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';
import isObject from 'lodash/isObject';

import { ErrorBag, addPrefix, rejectPrefix } from './helpers';
import config from '../config';
import useMemoizedValue from './useMemoizedValue';

yup.addMethod(yup.string, 'gte', function handle(min: string | yup.TestContext, message = '') {
  return this.test({
    message,
    name: 'gte',
    exclusive: true,
    params: {
      min: isObject(min) ? min.path : min,
    },
    test(value) {
      return !value || value >= this.resolve(min);
    },
  });
});

yup.addMethod(
  yup.string,
  'afterOrEqual',
  function handle(date: string | yup.TestContext, message = '') {
    return this.test({
      message,
      name: 'after_or_equal',
      exclusive: true,
      params: {
        date: isObject(date) ? date.path : date,
      },
      test(value) {
        return !value || value >= this.resolve(date);
      },
    });
  },
);

yup.addMethod(
  yup.string,
  'after',
  function handle(date: string | yup.TestContext, message = '') {
    return this.test({
      message,
      name: 'after',
      exclusive: true,
      params: {
        date: isObject(date) ? date.path : date,
      },
      test(value) {
        return !value || value > this.resolve(date);
      },
    });
  },
);

yup.addMethod(yup.string, 'before', function handle(date: string | yup.TestContext, message = '') {
  return this.test({
    message,
    name: 'before',
    exclusive: true,
    params: {
      date: isObject(date) ? date.path : date,
    },
    test(value) {
      return !value || value < this.resolve(date);
    },
  });
});

yup.addMethod(
  yup.string,
  'beforeOrEqual',
  function handle(date: string | yup.TestContext, message = '') {
    return this.test({
      message,
      name: 'before_or_equal',
      exclusive: true,
      params: {
        date: isObject(date) ? date.path : date,
      },
      test(value) {
        return !value || value <= this.resolve(date);
      },
    });
  },
);

yup.addMethod(yup.string, 'future', function handle(date: string | yup.TestContext, message = '') {
  return this.test({
    message,
    name: 'future',
    exclusive: true,
    params: {
      date: isObject(date) ? date.path : date,
    },
    test(value) {
      // Does not take into account project's timezone, hopefully that won't be necessary
      return !value || value > format(new Date(), config.locale.en.dateFormats.internal_date_time);
    },
  });
});

yup.addMethod(yup.string, 'past', function handle(date: string | yup.TestContext, message = '') {
  return this.test({
    message,
    name: 'past',
    exclusive: true,
    params: {
      date: isObject(date) ? date.path : date,
    },
    test(value) {
      // Does not take into account project's timezone, hopefully that won't be necessary
      return !value || value < format(new Date(), config.locale.en.dateFormats.internal_date_time);
    },
  });
});

// Use minAmount() instead of min() to get a nicely formatted money amount in the error message.
yup.addMethod(
  yup.number,
  'minAmount',
  function handle(min: number | yup.TestContext, message = '') {
    return this.test({
      message,
      name: 'gte.money',
      exclusive: true,
      params: {
        min,
      },
      test(value) {
        return typeof value === 'undefined' || value === null || value >= min;
      },
    });
  },
);

// Use maxAmount() instead of max() to get a nicely formatted money amount in the error message.
yup.addMethod(
  yup.number,
  'maxAmount',
  function handle(max: number | yup.TestContext, message = '') {
    return this.test({
      message,
      name: 'lte.money',
      exclusive: true,
      params: {
        max,
      },
      test(value) {
        return typeof value === 'undefined' || value === null || value <= max;
      },
    });
  },
);

// Converts yup rules to our own rules
const rules: any = {
  typeError: 'required',
  'min.string': 'min.string',
  'min.array': 'min.list',
  'min.number': 'min.numeric',
  'max.string': 'max.string',
  'max.array': 'max.list',
  'max.number': 'max.numeric',
  'gt.string': 'gt.string',
  'gte.string': 'gte.string',
  'lt.string': 'lt.string',
  'lte.string': 'lte.string',
};

/**
 * Converts yup's ValidationErrors to our own error format:
 * {
 *   'foo.bar': {
 *     'min.string': { min: 2 }
 *   }
 * }
 */
function convertErrors(
  schema: yup.SchemaOf<any>, error: yup.ValidationError, accumulated: any = {}, path: string = null,
) {
  if (typeof error !== 'object' || !error.inner) {
    // Yup caught a runtime error, probably in a custom validation rule
    throw error;
  }

  error.inner.forEach((innerError) => (
    convertErrors(
      schema,
      innerError,
      accumulated,
      (path || '') + (path !== null ? '.' : '')
        + (innerError.path || '').replace(/\[/g, '.').replace(/\]/g, ''),
    )
  ));

  if (error.inner.length === 0) {
    accumulated[path] = accumulated[path] || {};

    const field = yup.reach(schema, path);

    if (field) {
      // Accessing pseudo-private property, should be tested properly.
      // eslint-disable-next-line no-underscore-dangle
      const dataType = (field as any)._type;

      // Convert yup rule to our own rule
      const rule = rules[`${error.type}.${dataType}`] || rules[error.type] || error.type || 'invalid';

      accumulated[path][rule] = error.params;
    }
  }

  return accumulated;
}

interface UseValidationProps<T=any> {
  schema: yup.SchemaOf<T>;
  values: T;
  externalErrors?: ErrorBag;
}

interface UseValidation {
  valid: boolean;
  errors: any;
  validating: string[];
  updateCustomErrors: (errors: ErrorBag, prefix: string) => void;
  updateValidating: (validating: string[], prefix: string) => void;
}

/**
 * Given a yup schema and some values, this hook will run the schema validation whenever values
 * change. Returns error messages and a valid flag.
 */
const useValidation = (
  { schema, values, externalErrors }: UseValidationProps,
): UseValidation => {
  const memoizedValues = useMemoizedValue(values);
  const memoizedExternalErrors = useMemoizedValue(externalErrors);

  const valuesRef = useRef(memoizedValues);
  const externalErrorsRef = useRef(memoizedExternalErrors);

  const currentExternalErrors = useMemo(() => {
    // When the external errors change, remember which values triggered them
    if (externalErrorsRef.current !== memoizedExternalErrors) {
      valuesRef.current = memoizedValues;
      externalErrorsRef.current = memoizedExternalErrors;
    }

    // Show the external errors until the values change
    return valuesRef.current === memoizedValues ? memoizedExternalErrors : undefined;
  }, [memoizedValues, memoizedExternalErrors]);

  const [customErrors, setCustomErrors] = useState<ErrorBag>({});
  const [validating, setValidating] = useState<string[]>([]);

  // Replace all errors that start with the prefix.
  const updateCustomErrors = useCallback((errors: ErrorBag, prefix: string) => (
    setCustomErrors((value) => ({
      ...rejectPrefix(value, prefix),
      ...addPrefix(errors, prefix),
    }))
  ), []);

  // Replace all validating attributes that start with the prefix.
  const updateValidating = useCallback((validating: string[], prefix: string) => (
    setValidating((value) => ([
      ...value.filter((attribute) => !attribute.startsWith(`${prefix}.`)),
      ...validating.map((attribute) => `${prefix}.${attribute}`),
    ]))
  ), []);

  return useMemo(() => {
    let valid = true;
    let errors = {};

    try {
      schema.validateSync(memoizedValues, {
        abortEarly: false,
      });
    } catch (error) {
      valid = false;
      errors = convertErrors(schema, error);
    }

    if (Object.keys(customErrors).length > 0 || validating.length > 0) {
      valid = false;
    }

    return {
      valid,
      errors: { ...errors, ...currentExternalErrors, ...customErrors },
      validating,
      updateCustomErrors,
      updateValidating,
    };
  }, [currentExternalErrors, customErrors, validating, updateCustomErrors, updateValidating, schema, memoizedValues]);
};

export default useValidation;
