import React from 'react';

/* eslint-disable max-len */
const Finance = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.75,24h-12A4.26,4.26,0,0,1,3.5,19.75v-7a.75.75,0,0,1,1.5,0v7A2.76,2.76,0,0,0,7.75,22.5h12a2.76,2.76,0,0,0,2.75-2.75v-12A2.76,2.76,0,0,0,19.75,5h-7a.75.75,0,0,1,0-1.5h7A4.26,4.26,0,0,1,24,7.75v12A4.26,4.26,0,0,1,19.75,24Z" fill="currentColor" />
    <path d="M7.75,3.49H5V.74a.75.75,0,0,0-1.5,0V3.49H.75A.75.75,0,0,0,.75,5H3.5V7.74a.75.75,0,0,0,1.5,0V5H7.75a.75.75,0,0,0,0-1.5Z" fill="currentColor" opacity="0.5" />
    <g opacity="0.5"><path d="M18.75,19a.76.76,0,0,1-.75-.75V11.78a.75.75,0,0,1,1.5,0v6.46A.76.76,0,0,1,18.75,19Z" fill="currentColor" /></g>
    <g opacity="0.5"><path d="M13.75,19a.76.76,0,0,1-.75-.75V9.62a.75.75,0,0,1,1.5,0v8.62A.76.76,0,0,1,13.75,19Z" fill="currentColor" /></g>
    <g opacity="0.5"><path d="M8.75,19A.76.76,0,0,1,8,18.24V14.55a.75.75,0,0,1,1.5,0v3.69A.76.76,0,0,1,8.75,19Z" fill="currentColor" /></g>
  </svg>
);

export default Finance;
