import { isFuture } from 'date-fns';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Queue as QueueInterface } from './helpers';
import { useEvent } from './EventProvider';
import { useTracking } from '../../common/Tracking';
import StateContext from './StateContext';
import UI from '../../common/UI';
import useDocumentTitle from '../../common/useDocumentTitle';
import useLocale from '../../common/useLocale';

interface QueueStatus {
  token: string | null;
  position: number | null;
  polling_interval: number;
}

export interface QueueProps {
  queue: QueueInterface;
  initialPosition?: number | null;
}

const Queue = ({ queue, initialPosition = null }: QueueProps) => {
  const { t } = useTranslation();
  const { formatDate, parseDate, formatInteger } = useLocale();
  const { refetch: refetchEvent } = useEvent();
  const { track } = useTracking();

  const { session, setQueueToken: leaveQueue } = useContext(StateContext);

  const defaultQueueStatus: QueueStatus = useMemo(() => ({
    token: session.queueToken,
    position: initialPosition,
    polling_interval: 0, // Initially, fetch the token without delay
  }), [session.queueToken, initialPosition]);

  const [queueStatus, setQueueStatus] = useState(defaultQueueStatus);

  const resetQueueStatus = useCallback(
    () => setQueueStatus({ ...defaultQueueStatus, polling_interval: 10000 }),
    [defaultQueueStatus],
  );

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [maxPosition, setMaxPosition] = useState(queueStatus.position || 1);

  useDocumentTitle((title) => `${title}${queueStatus.position !== null ? ` (${queueStatus.position})` : ''}`);

  // Poll the queue token
  useEffect(() => {
    if (queueStatus.token) {
      // Stop polling once we receive a token
      return undefined;
    }

    const timer = window.setTimeout(() => {
      if (typeof process !== 'undefined' && process.env.JEST_WORKER_ID !== undefined) {
        // Jest tests don't support window.fetch
        return;
      }

      // Try to get a token.
      window
        .fetch(queue.token_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data: QueueStatus) => {
              if (typeof data !== 'object' || !('token' in data)) {
                // Invalid JSON response
                throw new Error();
              }

              setQueueStatus(data);
              setLastUpdated(new Date());

              if (data.position) {
                setMaxPosition((previousPosition) => Math.max(previousPosition, data.position));
              }
            }).catch(() => {
              // Couldn't parse response
              resetQueueStatus();
            });
          } else {
            // Bad HTTP response (e.g.: 4xx, 5xx)
            resetQueueStatus();
          }
        }).catch(() => {
          // Network error (e.g.: user is offline)
          resetQueueStatus();
        });
    }, window.Cypress ? 500 : queueStatus.polling_interval);

    return () => window.clearTimeout(timer);
  }, [queue, queueStatus, resetQueueStatus]);

  // When the token is received, the user can leave the queue.
  // First refetch the event, so that we can show the latest data,
  // then deactivate the queue by refetching the checkout summary.
  useEffect(() => {
    if (queueStatus.token) {
      // Wait at least 5 seconds because the GetEventQuery response is cached for 5 seconds.
      // Otherwise, the user might still see no tickets while the sale has just started.
      const delay = 5000;

      const timer = window.setTimeout(() => {
        refetchEvent().then(() => {
          leaveQueue(queueStatus.token);
        });
      }, delay);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [queueStatus, refetchEvent, leaveQueue]);

  // When the user is still in the queue 30 seconds after receiving a token,
  // start polling for a token again, because something probably went wrong.
  // For example, fetching the CheckoutSummary might have failed.
  useEffect(() => {
    if (queueStatus.token) {
      const timer = window.setTimeout(() => {
        resetQueueStatus();
        track?.('Checkout:QueueRedirectFailed', queueStatus);
      }, 30000);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [queueStatus, resetQueueStatus, track]);

  const startingAt = useMemo(() => queue.starting_at && parseDate(queue.starting_at, {
    format: 'internal_date_time', timezone: 'UTC',
  }), [queue.starting_at, parseDate]);

  const shouldShowStartTime = useCallback(() => (startingAt ? isFuture(startingAt) : false), [startingAt]);

  const [showStartTime, setShowStartTime] = useState(shouldShowStartTime);

  useEffect(() => {
    if (showStartTime) {
      const timer = window.setInterval(() => {
        setShowStartTime(shouldShowStartTime());
      }, 1000);

      return () => window.clearInterval(timer);
    }

    return undefined;
  }, [showStartTime, shouldShowStartTime]);

  return (
    <UI.Card>
      <UI.H3 sc={{ mb: 2 }}>
        <UI.Icon sc={{ mr: 1 }}>
          <UI.Loader />
        </UI.Icon>
        {' '}
        {!showStartTime && t('queue_active')}
        {showStartTime && t('queue_starting_soon', {
          time: formatDate(startingAt, { format: 'display_time', timezone: null }),
        })}
      </UI.H3>
      <UI.Paragraph>
        {!showStartTime && t('queue_active_please_wait')}
        {showStartTime && t('queue_starting_soon_please_wait')}
      </UI.Paragraph>
      {!showStartTime && (
        <>
          <ProgressBar currentPosition={queueStatus.position || maxPosition} maxPosition={maxPosition} />
          <UI.Paragraph sc={{ mt: 2 }}>
            {t('current_position')}
            {' '}
            {queueStatus.position !== null && formatInteger(queueStatus.position)}
            {queueStatus.position === null && t('unknown')}
          </UI.Paragraph>
        </>
      )}
      <UI.Paragraph sc={{ muted: true, mb: 0 }}>
        {t('last_update')}
        {' '}
        {formatDate(lastUpdated, { format: 'internal_time', timezone: null })}
      </UI.Paragraph>
    </UI.Card>
  );
};

interface ProgressBarProps {
  currentPosition: number;
  maxPosition: number;
}

export const ProgressBar = ({ currentPosition, maxPosition }: ProgressBarProps) => {
  const progress = Math.min(100, ((maxPosition - currentPosition) / maxPosition) * 100);

  return (
    <UI.Div
      sc={{ background: 'secondary.100' }}
      style={{ height: 8, borderRadius: '4px', overflow: 'hidden' }}
    >
      <UI.Div
        sc={{ background: 'secondary' }}
        style={{
          height: '100%',
          width: `${progress}%`,
          transition: 'all 0.25s ease-in-out',
        }}
      />
    </UI.Div>
  );
};

export default Queue;
