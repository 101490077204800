import styled, { css } from 'styled-components';

import UI from '../UI';

interface SellableLabelProps {
  error?: boolean;
  type?: 'ticket' | 'product';
}

const SellableLabel = styled(UI.Label)<SellableLabelProps>`
  ${({ error = false, type = 'ticket', theme }) => css`
    background: ${theme.getColor(error ? 'gray.100' : (type === 'ticket' ? 'secondary.100' : 'transparent'))};
    color: ${theme.getColor(error ? 'gray.600' : (type === 'ticket' ? 'secondary.600' : 'gray.600'))};
    ${type === 'product' && css`
      box-shadow: inset 0 0 0 1px ${theme.colors.gray[200]};
    `}
    font-size: 0.875em;
    font-weight: 500;
    display: inline-block;
  `}
`;

export default SellableLabel;
