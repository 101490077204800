import React, { ReactNode, useCallback, useState } from 'react';

interface CookieConsentContextValue {
  cookieConsent: boolean | null;
  acceptCookies: () => void;
  denyCookies: () => void;
}

export const CookieConsentContext = React.createContext({} as CookieConsentContextValue);

interface CookieConsentProviderProps {
  children: ReactNode;
}

const CookieConsentProvider = ({ children }: CookieConsentProviderProps) => {
  const cookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)cookieConsent\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );

  const [cookieConsent, setCookieConsent] = useState(
    cookie === '1' ? true : (cookie === '0' ? false : null),
  );

  const acceptCookies = useCallback(() => {
    document.cookie = 'cookieConsent=1;path=/;Max-Age=31536000'; // Remember choice for 365 days

    setCookieConsent(true);
  }, [document, setCookieConsent]);

  const denyCookies = useCallback(() => {
    document.cookie = 'cookieConsent=0;path=/'; // Remember choice for current session

    setCookieConsent(false);
  }, [document, setCookieConsent]);

  return (
    <CookieConsentContext.Provider value={{ cookieConsent, acceptCookies, denyCookies }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentProvider;
