import gql from 'graphql-tag';

import { GetAddress } from './__generated__/GetAddress';
import useQuery from '../api/useQuery';

const GetAddressQuery = gql`
  query GetAddress($country: String!, $zip_code: String!, $house_number: String) {
    getAddress(country: $country, zip_code: $zip_code, house_number: $house_number) {
      id
      country
      zip_code
      house_number
      street
      city
    }
  }
`;

interface UseAddressCompletionProps {
  country: string | null;
  zipCode: string | null;
  houseNumber: string | null;
  onCompleted: (address: Address) => void;
}

export interface Address {
  country: string | null;
  zipCode: string | null;
  houseNumber: string | null;
  street: string | null;
  city: string | null;
}

const useAddressCompletion = (
  { country, zipCode, houseNumber, onCompleted }: UseAddressCompletionProps,
) => {
  useQuery<GetAddress>(GetAddressQuery, {
    variables: {
      country,
      zip_code: zipCode,
      house_number: houseNumber,
    },
    skip: !country || !zipCode || country !== 'NL',
    onCompleted: ({ getAddress: address }) => {
      onCompleted({
        country: address.country,
        zipCode: address.zip_code,
        houseNumber: address.house_number,
        street: address.street,
        city: address.city,
      });
    },
  });
};

export default useAddressCompletion;
