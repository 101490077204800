import { Info } from 'react-feather';
import React, { useEffect, useState } from 'react';

import { ErrorBag, getServerErrors } from '../helpers';
import { ParticipantFieldType } from '../../__generated__/globalTypes';
import AddressInput from './Types/AddressInput';
import AuLicenseInput from './Types/AuLicenseInput';
import IntegerInput from './Types/IntegerInput';
import OptionInput from './Types/OptionInput';
import ParticipantFieldInputProps from './ParticipantFieldInputProps';
import SelectionInput from './Types/SelectionInput';
import ShortTextInput from './Types/ShortTextInput';
import TimeInput from './Types/TimeInput';
import UI from '../UI';
import useExternalValidation from './Types/useExternalValidation';
import useMemoizedValue from '../useMemoizedValue';

const ParticipantFieldInput = ({
  value, onChange, field, shouldRunExternalValidation, externalValidationTimeout,
  registration, updateCustomErrors, updateValidating, ...props
}: ParticipantFieldInputProps) => {
  const [focused, setFocused] = useState(false);
  const [key, setKey] = useState(1);

  const hasValue = !!value.value || value.choices?.length > 0;

  const validation = useExternalValidation({
    field,
    input: value,
    enabled: shouldRunExternalValidation && (hasValue || !focused),
    timeout: externalValidationTimeout,
    registration,
  });

  // Watch externalValidationErrors for changes without updateCustomErrors as dependency,
  // because updateCustomErrors might be unstable.
  const externalValidationErrors = useMemoizedValue(
    validation.error ? getServerErrors(validation.error, undefined, false) : undefined,
  );

  const [validationErrorsChanged, setValidationErrorsChanged] = useState(false);

  useEffect(() => {
    setValidationErrorsChanged(true);
  }, [externalValidationErrors, setValidationErrorsChanged]);

  useEffect(() => {
    if (validationErrorsChanged) {
      updateCustomErrors?.(externalValidationErrors || {});
      setValidationErrorsChanged(false);
    }
  }, [validationErrorsChanged, updateCustomErrors, externalValidationErrors]);

  // Watch 'validating' for changes without updateValidating as dependency,
  // because updateValidating might be unstable.
  const { validating } = validation;

  const [validatingChanged, setValidatingChanged] = useState(false);

  useEffect(() => {
    setValidatingChanged(true);
  }, [validating, setValidatingChanged]);

  useEffect(() => {
    if (validatingChanged) {
      updateValidating?.(validating ? ['external_validation'] : []);
      setValidatingChanged(false);
    }
  }, [validatingChanged, updateValidating, validating]);

  const errors: ErrorBag = {
    ...externalValidationErrors,
    ...props.errors,
  };

  const touched = props.touched || (hasValue && !!validation.error);

  useEffect(() => {
    if (!focused && !value.value && validation.suggestion) {
      // Autocomplete the input if it is not focused and does not have a value
      onChange({
        ...value,
        value: validation.suggestion,
      });

      // Remount the input to show the new value
      setKey((key) => key + 1);
    }
  }, [focused, value, validation.suggestion, onChange]);

  const handleFocus = () => {
    setFocused(true);
    props.onFocus?.();
  };

  const handleBlur = () => {
    setFocused(false);
    props.onBlur?.();
  };

  const inputs = {
    [ParticipantFieldType.short_text]: ShortTextInput,
    [ParticipantFieldType.integer]: IntegerInput,
    [ParticipantFieldType.option]: OptionInput,
    [ParticipantFieldType.selection]: SelectionInput,
    [ParticipantFieldType.iban]: ShortTextInput,
    [ParticipantFieldType.time]: TimeInput,
    [ParticipantFieldType.address]: AddressInput,
    [ParticipantFieldType.au_license]: AuLicenseInput,
    [ParticipantFieldType.knwu_license]: ShortTextInput,
    [ParticipantFieldType.kwbn_member]: ShortTextInput,
    [ParticipantFieldType.ntfu_member]: ShortTextInput,
  };

  const Input = inputs[field.type];

  return (
    <>
      <Input
        {...props}
        field={field}
        onChange={onChange}
        value={value}
        registration={registration}
        errors={errors}
        touched={touched}
        onFocus={handleFocus}
        onBlur={handleBlur}
        validating={validating}
        key={key}
      />
      {hasValue && validation.success && validation.message && (
        <UI.Info icon={<Info />}>
          {validation.message}
        </UI.Info>
      )}
    </>
  );
};

export default ParticipantFieldInput;
