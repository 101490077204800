import { useTranslation } from 'react-i18next';
import React, { Fragment, useCallback, useEffect } from 'react';

import { PaymentMethodInput } from '../../__generated__/globalTypes';
import UI from '../UI';
import usePaymentMethods, { PaymentMethod, idealIssuers } from '../usePaymentMethods';

interface PaymentMethodSelectionProps {
  value: Partial<PaymentMethodInput>;
  onChange: (value: Partial<PaymentMethodInput>) => void;
  onBlur?: (key: keyof Partial<PaymentMethodInput>) => void;
  paymentMethods: string[];
}

const PaymentMethodSelection = ({ value, onChange, onBlur, paymentMethods }: PaymentMethodSelectionProps) => {
  const { t } = useTranslation('common');
  const { getPaymentMethodImageUrl, getPaymentMethodTitle } = usePaymentMethods();

  const setPaymentMethod = useCallback(
    (paymentMethod: string | null) => {
      onChange({
        ...value,
        payment_method: paymentMethod,
        issuer: paymentMethod !== PaymentMethod.Ideal ? null : value.issuer,
      });
    },
    [onChange, value],
  );

  const setIssuer = (issuer: string) => {
    onChange({
      ...value,
      issuer,
    });
  };

  const selectedPaymentMethod = value.payment_method;

  // If there is exactly 1 payment method, select it.
  useEffect(() => {
    if (paymentMethods.length === 1 && value.payment_method !== paymentMethods[0]) {
      setPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, value.payment_method, setPaymentMethod]);

  return (
    <>
      {paymentMethods.length > 0 && (
        <UI.FormGrid sc={{ gutter: 0.25 }}>
          {paymentMethods.map((paymentMethod) => (
            <UI.Radio
              onChange={() => setPaymentMethod(paymentMethod)}
              onBlur={() => onBlur?.('payment_method')}
              checked={paymentMethod === selectedPaymentMethod}
              sc={{ box: true, radioTop: 13 }}
              style={{ flex: 1 }}
              key={paymentMethod}
            >
              <UI.FlexContainer sc={{ alignItems: 'center' }}>
                <UI.Span sc={{ mr: 2 }}>
                  <img
                    src={getPaymentMethodImageUrl(paymentMethod)}
                    alt={getPaymentMethodTitle(paymentMethod)}
                    style={{ width: 32, height: 24, display: 'block' }}
                  />
                </UI.Span>
                <UI.Span sc={{ fontWeight: 500 }}>
                  {getPaymentMethodTitle(paymentMethod)}
                </UI.Span>
              </UI.FlexContainer>

              {paymentMethod === PaymentMethod.Ideal && (
                <UI.AnimateHeight
                  isVisible={paymentMethod === selectedPaymentMethod && paymentMethod === PaymentMethod.Ideal}
                >
                  <UI.Div sc={{ pt: 2 }}>
                    <UI.Select
                      value={value.issuer || ''}
                      onChange={(event) => setIssuer(event.target.value || null)}
                      onBlur={() => onBlur?.('issuer')}
                    >
                      {!value.issuer && (
                      <option value="">{t('payments.select_your_bank')}</option>
                      )}
                      {idealIssuers.map((issuer) => (
                        <option value={issuer.id} key={issuer.id}>{issuer.title}</option>
                      ))}
                    </UI.Select>
                  </UI.Div>
                </UI.AnimateHeight>
              )}
            </UI.Radio>
          ))}
        </UI.FormGrid>
      )}
      {paymentMethods.length === 0 && (
        <UI.Warning>
          {t('payments.no_payment_method_available')}
        </UI.Warning>
      )}
    </>
  );
};

export default PaymentMethodSelection;
