import { scroller } from 'react-scroll';
import { useCallback } from 'react';
import { useTheme } from 'styled-components';

import { Theme } from '../theme';
import { isInViewport } from './helpers';
import useMediaDevice from './useMediaDevice';

interface ScrollToOptions {
  offset?: number;
  gutter?: number;
  ignoreNavBar?: boolean;
  scrollContainer?: string;
  duration?: number;
}

const useScroll = () => {
  const theme = useTheme() as Theme;
  const device = useMediaDevice();

  const scrollTo = useCallback((name: string, options: ScrollToOptions = {}) => {
    if (document.querySelector(`#${name}`)) {
      const offset = -(device.isSmall ? theme.scrollOffset.sm : theme.scrollOffset.md)
        - (options.offset || 0)
        - (options.gutter || 0) * theme.gutter;

      scroller.scrollTo(name, {
        duration: options.duration || ((scrollDistance: number) => Math.min(Math.abs(scrollDistance) / 4, 250)),
        containerId: options.scrollContainer,
        offset,
        smooth: true,
        delay: 0,
      });
    }
  }, [device.isSmall, theme.scrollOffset.sm, theme.scrollOffset.md, theme.gutter]);

  const scrollToTop = useCallback((options: ScrollToOptions = {}) => {
    scrollTo('app', {
      ignoreNavBar: true,
      ...options,
    });
  }, [scrollTo]);

  const scrollToIfInvisible = useCallback((name: string, options: ScrollToOptions = {}) => {
    const element = document.querySelector(`#${name}`);

    if (element && !isInViewport(element)) {
      scrollTo(name, options);
    }
  }, [scrollTo]);

  return {
    scrollTo,
    scrollToTop,
    scrollToIfInvisible,
  };
};

export default useScroll;
