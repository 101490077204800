import styled, { css } from 'styled-components';

import { Span } from './Generic';
import Communication from './Icons/Communication';
import Customization from './Icons/Customization';
import Dashboard from './Icons/Dashboard';
import Finance from './Icons/Finance';
import Mailchimp from './Icons/Mailchimp';
import Registration from './Icons/Registration';
import Resale from './Icons/Resale';
import SC from './SC';
import Ticket from './Icons/Ticket';

export interface IconProps {
  size?: number;
  strong?: boolean;
}

export const Icon = styled(Span)<SC<IconProps>>`
  ${({ sc: { size = 1, strong } = {} }) => css`
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;

    > * {
      height: ${size}em;
      width: ${size}em;
      display: block;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    strong & svg {
      stroke-width: 2.5;
    }

    ${strong && css`
      svg {
        stroke-width: 2.5;
      }
    `}
  `}
`;

export const Icons = {
  Ticket,
  Resale,
  Finance,
  Communication,
  Customization,
  Registration,
  Dashboard,
  Mailchimp,
};

export default undefined;
