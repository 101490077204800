import { Locale } from '../__generated__/globalTypes';

export const routes: { [locale in Locale]: { [key: string]: string; } } = {
  [Locale.en]: {
    contact: 'contact',
    create_registration_form: 'create-registration-form',
    cycling_events: 'cycling-event',
    diggelvuur: 'diggelvuur',
    enforce: 'enforce',
    feature_deposit_spread_payments: 'down-payments',
    feature_discounts: 'discounts',
    feature_donations: 'donations',
    feature_embed_widget: 'embed-widget',
    feature_generate_start_numbers: 'ticket-numbers',
    feature_group_registration: 'group-registration',
    feature_invitation_code: 'invitation-codes',
    feature_participants_dashboard: 'participants-dashboard',
    feature_reach_participants: 'communication',
    feature_scan_app: 'scan-app',
    feature_ticket_registrations: 'tickets-registrations',
    feature_time_slots: 'time-slots',
    features: 'features',
    get_in_contact: 'get-in-contact',
    ice: 'international-cycling-events',
    kopwerk: 'kopwerk',
    le_champion: 'le-champion',
    online_ticket_sales_system: 'online-ticket-sales-system',
    pricing: 'pricing',
    privacy: 'privacy',
    registration_for_sports_events: 'registration-for-sports-events',
    running_events: 'running-event',
    sell_tickets_online: 'sell-tickets-online',
    terms_of_service: 'terms-of-service',
    triathlons: 'triathlon',
    walking_events: 'walking-event',
    integrations: 'integrations',
    integration_facebook: 'integration-facebook',
    integration_google_analytics: 'integration-google-analytics',
    integration_knwu: 'integration-knwu',
    integration_atletiekunie: 'integration-atletiekunie',
    integration_ntfu: 'integration-ntfu',
    integration_mollie: 'integration-mollie',
    integration_meta_pixel: 'integration-meta-pixel',
    integration_wordpress: 'integration-wordpress',
    integration_onecommunity: 'integration-onecommunity',
    integration_hardlopen: 'integration-hardlopen',
    integration_mailchimp: 'integration-mailchimp',
    services: 'services',
    service_websites: 'services/websites',
    service_ticket_scanner_rental: 'services/ticket-scanner-rental',
    service_design: 'services/design',
  },
  [Locale.nl]: {
    contact: 'contact',
    create_registration_form: 'inschrijfformulier-maken',
    cycling_events: 'fietsevenement',
    diggelvuur: 'diggelvuur',
    enforce: 'enforce',
    feature_deposit_spread_payments: 'aanbetalingen',
    feature_discounts: 'kortingen',
    feature_donations: 'donaties',
    feature_embed_widget: 'embed-widget',
    feature_generate_start_numbers: 'startnummers',
    feature_group_registration: 'groepsregistratie',
    feature_invitation_code: 'uitnodigingscodes',
    feature_participants_dashboard: 'deelnemersdashboard',
    feature_reach_participants: 'communicatie',
    feature_scan_app: 'scan-app',
    feature_ticket_registrations: 'tickets-inschrijvingen',
    feature_time_slots: 'tijdslots',
    features: 'functies',
    get_in_contact: 'neem-contact-op',
    ice: 'international-cycling-events',
    kopwerk: 'kopwerk',
    le_champion: 'le-champion',
    online_ticket_sales_system: 'online-kaartverkoop-systeem',
    pricing: 'prijzen',
    privacy: 'privacy',
    registration_for_sports_events: 'registratie-sportevenement',
    running_events: 'hardloopevenement',
    sell_tickets_online: 'online-ticketverkoop-sportevenement',
    terms_of_service: 'algemene-voorwaarden',
    triathlons: 'triatlon',
    walking_events: 'wandelevenement',
    integrations: 'integraties',
    integration_facebook: 'integratie-facebook',
    integration_google_analytics: 'integratie-google-analytics',
    integration_knwu: 'integratie-knwu',
    integration_atletiekunie: 'integratie-atletiekunie',
    integration_ntfu: 'integratie-ntfu',
    integration_mollie: 'integratie-mollie',
    integration_meta_pixel: 'integratie-meta-pixel',
    integration_wordpress: 'integratie-wordpress',
    integration_onecommunity: 'integratie-onecommunity',
    integration_hardlopen: 'integratie-hardlopen',
    integration_mailchimp: 'integration-mailchimp',
    services: 'diensten',
    service_websites: 'diensten/websites',
    service_ticket_scanner_rental: 'diensten/ticket-scanner-verhuur',
    service_design: 'diensten/vormgeving',
  },
  [Locale.de]: {},
  [Locale.fr]: {},
};

routes[Locale.de] = routes[Locale.en];
routes[Locale.fr] = routes[Locale.en];

export default undefined;

