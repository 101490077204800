import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CookieConsentContext } from './Common/CookieConsentProvider';

export interface UseGoogleAnalytics {
  enabled?: boolean;
  propertyId?: string | null;
  trackPageViews?: boolean;
  filterPath?: (path: string) => string;
  cookiePrefix?: string;
}

const useGoogleAnalytics = ({
  enabled = true,
  propertyId = null,
  trackPageViews = true,
  filterPath = (path) => path,
  cookiePrefix,
}: UseGoogleAnalytics) => {
  const history = useHistory();

  const { cookieConsent } = useContext(CookieConsentContext);

  const adStorageConsent = cookieConsent ? 'granted' : 'denied';
  const analyticsStorageConsent = 'granted';

  const active = enabled && !!propertyId;

  const gtag = useCallback((action: string, event: string, data?: string | object) => {
    // @ts-ignore
    if (active && typeof window.gtag === 'function') {
      // @ts-ignore
      window.gtag(action, event, data);
    }
  }, [active, window]);

  // Load Google Analytics
  useEffect(() => {
    let loaderScript: HTMLScriptElement = null;
    let gtagScript: HTMLScriptElement = null;

    if (active) {
      loaderScript = document.createElement('script');
      gtagScript = document.createElement('script');

      loaderScript.setAttribute('nonce', window.NONCE_ID);
      loaderScript.async = true;
      loaderScript.src = `https://www.googletagmanager.com/gtag/js?id=${propertyId}`;

      // Load gtag.js without sending a page view.
      // Enable linker to allow for cross-domain tracking.
      gtagScript.setAttribute('nonce', window.NONCE_ID);
      gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('consent', 'default', {
        ad_storage: '${adStorageConsent}',
        analytics_storage: '${analyticsStorageConsent}',
      });

      gtag('js', new Date());

      gtag('config', '${propertyId}', {
        cookie_prefix: '${cookiePrefix || ''}',
        send_page_view: false,
        linker: {
          accept_incoming: true,
        },
      });
      `;

      // Add right after <head>
      document.head.insertBefore(loaderScript, document.head.childNodes[0]);
      document.head.insertBefore(gtagScript, document.head.childNodes[0]);
    }

    return () => {
      if (loaderScript) {
        document.head.removeChild(loaderScript);
      }

      if (gtagScript) {
        document.head.removeChild(gtagScript);
      }
    };
  }, [active]);

  // Update cookie consent
  useEffect(() => {
    gtag('consent', 'update', {
      ad_storage: adStorageConsent,
      analytics_storage: analyticsStorageConsent,
    });
  }, [adStorageConsent, analyticsStorageConsent]);

  // Track events
  const trackEvent = useCallback((event: string, data: object) => gtag('event', event, {
    ...data,
    send_to: propertyId,
  }), [gtag, propertyId]);

  // Track page views
  const trackPageView = useCallback((manualPath?: string) => {
    const website = `${document.location.protocol}//${document.location.hostname}`;
    const path = filterPath(manualPath || (window.location.pathname + window.location.search));

    trackEvent('page_view', {
      // GA4
      page_location: website + path,
      // UA
      page_path: path,
    });
  }, [document, window, trackEvent, filterPath]);

  useEffect(() => {
    if (trackPageViews) {
      trackPageView();
    }

    return history.listen(() => {
      if (trackPageViews) {
        trackPageView();
      }
    });
  }, [trackPageViews]);

  return {
    trackEvent,
    trackPageView,
  };
};

export default useGoogleAnalytics;
