import React, {
  DetailedHTMLProps, FormHTMLAttributes, ReactNode, Ref, RefObject, SyntheticEvent, forwardRef,
} from 'react';

import { UIProps } from '../../theme/mixins';
import SC from './SC';

type HtmlFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export interface FormProps extends SC<UIProps>, HtmlFormProps {
  onSubmit: (event: SyntheticEvent) => any;
  children: ReactNode;
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLFormElement) => void) | RefObject<HTMLFormElement>;
}

const Form = forwardRef(
  ({ children, onSubmit, ...props }: FormProps, ref: Ref<HTMLFormElement>) => {
    const submit = (event: SyntheticEvent) => {
      event.preventDefault();

      if (onSubmit) {
        onSubmit(event);
      }
    };

    return (
      <form onSubmit={submit} {...props} ref={ref} noValidate>
        {children}
      </form>
    );
  },
);

export default Form;
