import { Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

import { CheckoutStep, SuccessStep } from './helpers';
import { ProjectProvider } from '../../common/useProject';
import CheckoutPage from './CheckoutPage';
import CheckoutProvider from './CheckoutProvider';
import CheckoutSummaryProvider from './CheckoutSummaryProvider';
import ErrorBoundary from './ErrorBoundary';
import EventProvider, { useEvent } from './EventProvider';
import StateProvider from './StateProvider';
import UI from '../../common/UI';

const SuccessPage = lazy(() => import('./Success/SuccessPage'));

const Main = ({
  eventId,
  step,
}: {
  eventId: string;
  step?: CheckoutStep | typeof SuccessStep | null;
}) => (
  <StateProvider eventId={eventId} step={step as any} key={eventId}>
    {/* Put a key on StateProvider because we want to force a re-render so that state
    is re-initialized when the event ID is programmatically changed in the URL.
    This can happen when the checkout is embedded on a website. */}
    <CheckoutSummaryProvider>
      <EventProvider>
        <Body />
      </EventProvider>
    </CheckoutSummaryProvider>
  </StateProvider>
);

const Body = () => {
  const { event } = useEvent();
  const { project } = event;

  return (
    <ErrorBoundary redirect={event.checkout_url}>
      <ProjectProvider project={project}>
        <CheckoutProvider render={(props) => (
          <Suspense fallback={<UI.PageLoader />}>
            <Switch>
              <Route path="/:eventId/thank-you">
                <SuccessPage />
              </Route>
              <Route>
                <CheckoutPage {...props} />
              </Route>
            </Switch>
          </Suspense>
        )}
        />
      </ProjectProvider>
    </ErrorBoundary>
  );
};

export default Main;
