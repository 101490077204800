import { useEffect } from 'react';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

const useDocumentTitle = (
  title: ((currentTitle: string) => string | string[]) | string | string[],
) => {
  useEffect(() => {
    if (title === null) {
      return undefined;
    }

    const originalTitle = document.title;
    const newTitle = isFunction(title) ? title(document.title) : title;
    const parts = isArray(newTitle) ? newTitle : [newTitle];
    const joinedTitle = parts.join(' · ');

    document.title = joinedTitle;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};

export default useDocumentTitle;
