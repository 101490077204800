const failSafe = (callback: () => any) => {
  try {
    return callback();
  } catch (e) {
    return false;
  }
};

export default {
  getItem: (key: string): string | null => (
    failSafe(() => window.sessionStorage?.getItem(key)) || null
  ),
  setItem: (key: string, value: string | null): void => (
    failSafe(() => window.sessionStorage?.setItem(key, value)) || undefined
  ),
  removeItem: (key: string): void => (
    failSafe(() => window.sessionStorage?.removeItem(key)) || undefined
  ),
  clear: () => failSafe(() => window.sessionStorage?.clear()),
};
