import { Clock } from 'react-feather';
import React from 'react';

import {
  GetCheckoutSummary_checkoutSummary_products, GetCheckoutSummary_checkoutSummary_tickets,
} from '../__generated__/GetCheckoutSummary';
import SellableLabel from '../Common/SellableLabel';
import UI from '../UI';
import useLocale from '../useLocale';
import useTimeSlotFormatter from '../Tickets/useTimeSlotFormatter';

interface PurchaseLineProps {
  ticket?: GetCheckoutSummary_checkoutSummary_tickets;
  product?: GetCheckoutSummary_checkoutSummary_products;
  hideIcon?: boolean;
}

const PurchaseLine = ({ ticket, product, hideIcon }: PurchaseLineProps) => {
  const { formatCurrency } = useLocale();
  const formatTimeSlot = useTimeSlotFormatter();
  const quantity = ticket?.quantity || product?.quantity;
  const purchase = ticket?.purchase || product?.purchase;

  return (
    <UI.GridContainer sc={{ columns: `30px ${!hideIcon ? '14px' : ''} 1fr fit-content(100px)`, gutter: 0.5 }}>
      <UI.Div>
        {`${quantity}× `}
      </UI.Div>
      {!hideIcon && (
        <UI.Div>
          {ticket && (
            <UI.Icon>
              <UI.Icons.Ticket />
            </UI.Icon>
          )}
        </UI.Div>
      )}
      <UI.Div>
        <UI.Div>
          <UI.Span sc={{ strong: !!ticket, mr: 1 }}>
            {ticket?.purchase.ticket.title || product?.purchase.product.title}
          </UI.Span>
          {product?.purchase.product_variant && (
            <SellableLabel type="product">
              {product.purchase.product_variant.title}
            </SellableLabel>
          )}
        </UI.Div>
        <UI.Delimit>
          {purchase.promotion.title && (
            <UI.Span sc={{ muted: true }}>
              {purchase.promotion.title}
            </UI.Span>
          )}
          {ticket?.purchase.time_slot && (
            <UI.Span sc={{ muted: true }}>
              <UI.Icon><Clock /></UI.Icon>
              {' '}
              <UI.Delimit>
                {formatTimeSlot(ticket.purchase.time_slot)}
                {ticket.purchase.time_slot.title}
              </UI.Delimit>
            </UI.Span>
          )}
          {purchase.coupon_code?.value}
        </UI.Delimit>
      </UI.Div>
      <UI.Div sc={{ textAlign: 'right', noWrap: true }}>
        {formatCurrency(quantity * purchase.amount)}
      </UI.Div>
    </UI.GridContainer>
  );
};

export default PurchaseLine;
