import { Trans, useTranslation } from 'react-i18next';
import React, { ChangeEvent, useRef, useState } from 'react';
import levenshtein from 'js-levenshtein';

import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';
import useDebouncedEffect from '../../useDebouncedEffect';

export interface EmailInputValue {
  email?: string | null;
}

export const getEmailSuggestion = (email: string | null): string | null => {
  const parts = (email || '').split('@');
  const domain = (parts[1] || '').toLowerCase();

  if (!domain) {
    // The email address is empty or does not contain an @ symbol.
    return null;
  }

  // Top 100 most popular email domains in Atleta, as of January 2023.
  // eslint-disable-next-line max-len
  const commonDomains = ['gmail.com', 'hotmail.com', 'live.nl', 'outlook.com', 'ziggo.nl', 'kpnmail.nl', 'quicknet.nl', 'icloud.com', 'yahoo.com', 'hotmail.nl', 'home.nl', 'upcmail.nl', 'hetnet.nl', 'planet.nl', 'gmx.de', 'web.de', 'me.com', 'xs4all.nl', 'casema.nl', 'chello.nl', 'msn.com', 'kpnplanet.nl', 'hotmail.co.uk', 'atleta.cc', 'klm.com', 'googlemail.com', 'tcs.com', 't-online.de', 'hotmail.fr', 'telfort.nl', 'telenet.be', 'zeelandnet.nl', 'yahoo.co.uk', 'live.com', 'online.nl', 'gmx.net', 'zonnet.nl', 'yahoo.fr', 'orange.fr', 'yahoo.de', 'ah.nl', 'caiway.nl', 'aol.com', 'deloitte.nl', 'hotmail.de', 'nl.abnamro.com', 'mac.com', 'solcon.nl', 'btinternet.com', 'vodafoneziggo.com', 'amsterdam.nl', 'seppworks.nl', 'protonmail.com', 'freenet.de', 'rituals.com', 'hccnet.nl', 'adyen.com', 'tele2.nl', 'philadelphia.nl', 'nike.com', 'philips.com', 'skynet.be', 'wanadoo.fr', 'rabobank.nl', 'wxs.nl', 'live.co.uk', 'outlook.de', 'kabelfoon.nl', 'ing.com', 'free.fr', 'gscmail.nl', 't-mobilethuis.nl', 'tatasteeleurope.com', 'lechampion.nl', 'politie.nl', 'tiscali.nl', 'arcor.de', 'emolife.nl', 'versatel.nl', 'danone.com', 'nn.nl', 'uwv.nl', 'hotmail.be', 'knid.nl', 'nwz.nl', 'live.fr', 'onsbrabantnet.nl', 'sky.com', 'libero.it', 'inholland.nl', 'kpmg.nl', 'externals.adidas.com', 'wp.pl', 'ymail.com', 'hotmail.it', 'mail.com', 'mail.ru', 'cargill.com', 'pwc.com', 'laposte.net'];

  if (commonDomains.indexOf(domain) >= 0) {
    // The domain is a common domain, no suggestion needed.
    // This avoids suggesting gmail.com when ymail.com is given.
    return null;
  }

  // Check if the domain differs by 1 character addition/deletion/change of any of the common domains.
  const suggestedDomain = commonDomains.find((commonDomain) => (
    levenshtein(domain, commonDomain) === 1
  ));

  if (suggestedDomain) {
    return `${parts[0]}@${suggestedDomain}`;
  }

  return null;
};

const EmailInput = ({
  value, onChange, onFocus, onBlur, description, touched, required = true, autoFocus = false, errors,
}: ParticipantAttributeInputProps<EmailInputValue>) => {
  const { t } = useTranslation('common');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => (
    onChange({ email: event.target.value })
  );

  const emailRef = useRef<HTMLInputElement>();
  const [suggestion, setSuggestion] = useState<string | null>(null);

  useDebouncedEffect(() => {
    setSuggestion(getEmailSuggestion(value.email));
  }, 500, [value.email]);

  const selectSuggestion = (email: string) => {
    emailRef.current.value = email;
    setSuggestion(null);

    onChange({ email });
  };

  return (
    <UI.InputGroup sc={{ valid: !errors?.email, touched: touched?.email }}>
      <UI.InputLabel htmlFor="ParticipantEmail">
        {t('participant_attributes.email_address')}
        {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
        )}
      </UI.InputLabel>
      {description && (
        <UI.InputDescription>
          {description}
        </UI.InputDescription>
      )}
      <UI.DebouncedInput
        value={value.email}
        onChange={handleChange}
        onFocus={() => onFocus?.('email')}
        onBlur={() => onBlur?.('email')}
        inputMode="email"
        autoFocus={autoFocus}
        id="ParticipantEmail"
        ref={emailRef}
      />
      <UI.ErrorMessages
        attribute={t('participant_attributes.email_address')}
        errors={errors?.email}
      />
      {suggestion && !errors?.email && (
        <UI.Info sc={{ mt: 2 }}>
          <Trans ns="common" i18nKey="email_input.did_you_mean" values={{ suggestion }}>
            <UI.A onClick={() => selectSuggestion(suggestion)} />
          </Trans>
        </UI.Info>
      )}
    </UI.InputGroup>
  );
};

export default EmailInput;
