import React from 'react';

/* eslint-disable max-len */
const Dashboard = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g opacity="0.5">
      <polygon points="17.29 13.6 11.85 16.77 6.37 13.6 11.81 10.43 17.29 13.6" fill="currentColor" />
      <polygon points="22.75 3.95 17.3 7.12 11.82 3.95 17.27 0.78 22.75 3.95" fill="currentColor" />
      <polygon points="11.8 3.95 6.36 7.12 0.88 3.95 6.32 0.78 11.8 3.95" fill="currentColor" />
    </g>
    <path d="M23.39,4A.74.74,0,0,0,23,3.3L17.54.13a.74.74,0,0,0-.76,0l-5.08,3L6.59.13a.75.75,0,0,0-.75,0L.37,3.3A.74.74,0,0,0,0,4H0v6.48a.77.77,0,0,0,.37.65l5.14,3,0,6a.76.76,0,0,0,.37.65l5.48,3.17a.75.75,0,0,0,.38.1.69.69,0,0,0,.37-.1h0l5.44-3.17a.79.79,0,0,0,.38-.65V14L23,11.08a.76.76,0,0,0,.37-.65l0-6.48ZM5.49,10.09V12.3L1.5,10V5.25l1.16.67L5.5,7.55ZM2.26,4l4-2.3,4,2.3L6.25,6.25ZM7,10.38V7.55L9.39,6.16l1.55-.9V10L7,12.29Zm4,9.36V22l-4-2.3V14.9l4,2.3Zm.76-3.84-4-2.3,3.95-2.3,4,2.3-3.11,1.81Zm4.68,3.75-4,2.29V17.2l3.94-2.29Zm0-7.35-4-2.31V5.25l4,2.3Zm.76-6.05-4-2.3,3.95-2.3,4,2.3-1.51.88ZM21.87,10l-3.94,2.29V7.55l3.95-2.29Z" fill="currentColor" />
  </svg>
);

export default Dashboard;
