import React, { Suspense, lazy } from 'react';
import styled from 'styled-components';

import { Div } from './Generic';
import { EditorProps } from './helpers';
import useInput from './useInput';

const LazyEditor = lazy(() => import('./Editor'));

const EditorFallback = styled(Div)`
  width: 100%;
  min-height: 152px;
  ${useInput}
`;

const Editor = (props: EditorProps) => (
  <Suspense fallback={<EditorFallback style={{ marginBottom: props.variables?.length > 0 ? 42 : 0 }} />}>
    <LazyEditor {...props} />
  </Suspense>
);

export default Editor;
