import styled, { css } from 'styled-components';

import { Div } from './Generic';
import SC from './SC';

interface TooltipProps {
  alignH?: 'left' | 'center' | 'right';
  alignV?: 'top' | 'bottom';
}

export const Tooltip = styled(Div)<SC<TooltipProps>>`
  ${({ theme, sc: { background, alignH = 'left', alignV = 'top' } = {} }) => css`
    background: ${theme.transparentize(theme.getColor(background || 'gray.800'), 0.2)} !important;
    color: ${theme.getTitleColor(theme.getColor(background || 'gray.800'))};
    position: relative;
    border-radius: ${theme.borderRadiuses.sm}px;
    padding: 6px 8px;
    font-size: ${12 / 14}em;
    line-height: 1.2;
    white-space: nowrap;
    z-index: 10;
    position: absolute;

    ${alignH === 'left' && css`
      left: -8px;
    `}

    ${alignH === 'center' && css`
      left: 50%;
      transform: translateX(-50%);
    `}

    ${alignH === 'right' && css`
      right: -8px;
    `}

    ${alignV === 'top' && css`
      bottom: 100%;
    `}

    ${alignV === 'bottom' && css`
      top: 100%;
    `}

    ${background === 'white' && css`
      box-shadow: 0 1px 2px rgba(0, 0, 0, .05), 0 2px 6px rgba(0, 0, 0, .05);
    `}

    &:after {
      content: ' ';
      position: absolute;

      ${alignH === 'left' && css`
        left: ${theme.gutter / 3}px;
      `}

      ${alignH === 'center' && css`
        left: 50%;
        margin-left: -8px;
      `}

      ${alignH === 'right' && css`
        right: ${theme.gutter / 3}px;
      `}

      top: ${alignV === 'bottom' ? '-8px' : 'auto'};
      bottom: ${alignV === 'top' ? '-8px' : 'auto'};
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-${alignV === 'top' ? 'top' : 'bottom'}: 8px solid ${theme.transparentize(theme.getColor(background || 'gray.800'), 0.2)};
    }
  `}
`;

export const TooltipContainer = styled(Div)`
  position: relative;
  z-index: 201;

  ${Tooltip} {
    display: none;
    transition: all 0.15s ease-in-out;
  }

  &:hover ${Tooltip} {
    display: block;
    opacity: 1;
  }
`;

export default undefined;
