import { assetUrl } from './common/helpers';

// @ts-ignore
// eslint-disable-next-line
window.__webpack_nonce__ = window.NONCE_ID;

/**
 * Update Webpack's public path to point to the CDN if necessary.
 * This is where Webpack's loader will look for lazy loaded modules.
 * Should NOT be set on the window object...
 */
// @ts-ignore
// eslint-disable-next-line
__webpack_public_path__ = assetUrl('compiled/');
