import { ThemeContext } from 'styled-components';
import { useContext, useEffect, useState } from 'react';

/**
 * Tracks device sizes
 */
const useMediaDevice = () => {
  const { breakpoints } = useContext(ThemeContext);
  const [dimensions, setDimensions] = useState([
    document.body.clientWidth,
    document.body.clientHeight,
  ]);

  useEffect(() => {
    const handleResize = () => setDimensions([
      document.body.clientWidth, document.body.clientHeight,
    ]);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isIphone = navigator.userAgent.match(/(iPhone|iPad)/);

  /** Taken from https://stackoverflow.com/a/4819886 */
  const isTouchDevice = 'ontouchstart' in window
    || navigator.maxTouchPoints > 0;

  return {
    isSmall: breakpoints.sm <= dimensions[0] && breakpoints.md > dimensions[0],
    isMedium: breakpoints.md <= dimensions[0] && breakpoints.lg > dimensions[0],
    isLarge: breakpoints.lg < dimensions[0],
    isPortrait: dimensions[0] < dimensions[1],
    width: dimensions[0],
    isIphone,
    isTouchDevice,
  };
};

export default useMediaDevice;
