import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface UseFacebookPixelProps {
  enabled: boolean;
  pixelId: string | null;
  trackPageViews?: boolean;
}

const useFacebookPixel = ({
  enabled,
  pixelId,
  trackPageViews = true,
}: UseFacebookPixelProps) => {
  const history = useHistory();

  const active = enabled && !!pixelId;

  const fbq = useCallback((action: string, event: string, data?: object) => {
    // @ts-ignore
    if (active && typeof window.fbq === 'function') {
      // @ts-ignore
      window.fbq(action, event, data);
    }
  }, [active]);

  useEffect(() => {
    let script: HTMLScriptElement = null;

    if (active) {
      script = document.createElement('script');

      script.setAttribute('nonce', window.NONCE_ID);

      // Initialize Facebook Pixel without sending a PageView event
      // and without listening to HTML5's history.pushState.
      script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      fbq.disablePushState = ${trackPageViews ? 'false' : 'true'};
      fbq('init', ${pixelId});
      `;

      // Add right after <head>
      document.head.insertBefore(script, document.head.childNodes[0]);
    }

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [active, pixelId, trackPageViews]);

  useEffect(() => {
    const sendPageView = () => {
      fbq('track', 'PageView');
    };

    if (trackPageViews) {
      sendPageView();
    }

    return history.listen(() => {
      if (trackPageViews) {
        sendPageView();
      }
    });
  }, [trackPageViews, history, fbq]);

  return fbq;
};

export default useFacebookPixel;
