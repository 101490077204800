import React, { ReactNode, useRef, useState } from 'react';
import findIndex from 'lodash/findIndex';
import styled, { css } from 'styled-components';

import { Div } from './Generic';
import { SelectInput, SelectWrapper } from './Select';
import { UIProps } from '../../theme/mixins';
import Dropdown from './Dropdown';
import SC from './SC';
import useFocusIndex from '../useFocusIndex';
import useOnClickOutside from '../useOnClickOutside';

interface DropdownSelectProps<T = any> {
  value: T;
  options: T[];
  onChange: (value: T) => void;
  renderOption?: (option: T | null, preview: boolean) => ReactNode;
  optionId?: (option: T) => boolean;
  ariaLabelledBy?: string;
}

function DropdownSelect<T=any>({
  value, options, onChange, renderOption, optionId, ariaLabelledBy,
}: DropdownSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  useOnClickOutside(containerRef, () => setIsOpen(false));

  const handleChange = (option: T) => {
    onChange(option);
    setIsOpen(false);
  };

  const getIdentifier = (option: T) => (optionId ? optionId(option) : option);

  const activeIndex = findIndex(options, (option) => (
    getIdentifier(option) === getIdentifier(value)
  ));

  return (
    <Div sc={{ relative: true }} ref={containerRef}>
      <SelectWrapper>
        <SelectInput
          aria-labelledby={ariaLabelledBy}
          as="button"
          type="button"
          style={{ textAlign: 'left' }}
          onClick={() => setIsOpen((open) => !open)}
        >
          {renderOption ? renderOption(value, true) : `${value}`}
        </SelectInput>
      </SelectWrapper>

      {isOpen && (
        <Dropdown
          sc={{ open: isOpen }}
          style={{ width: '100%' }}
        >
          <SelectOptions
            options={options}
            activeIndex={activeIndex}
            onSelect={handleChange}
            onCancel={() => setIsOpen(false)}
            renderOption={renderOption}
          />
        </Dropdown>
      )}
    </Div>
  );
}

interface SelectOptionProps extends UIProps {
  focus?: boolean;
  active?: boolean;
}

const SelectOption = styled(Div) <SC<SelectOptionProps>>`
  ${({ sc: { focus, active } = {}, theme }) => css`
    padding: ${theme.gutter / 2}px;
    border-radius: ${theme.borderRadiuses.sm}px;
    cursor: pointer;
    outline: none;

    & + & {
      margin-top: 1px;
    }

    ${active && css`
      background: ${theme.colors.gray[100]};
    `}

    ${focus && css`
      background: ${theme.colors.secondary[500]};
      color: ${theme.colors.white};
    `}
  `}
`;

interface SelectOptionsProps<T = any> {
  options: T[];
  activeIndex?: number;
  onSelect: (option: T) => any;
  onCancel: () => void;
  renderOption?: (option: T | null, preview: boolean) => ReactNode;
}

const SelectOptionsContainer = styled.div`
  ${({ theme }) => css`
    min-width: 200px;
    max-height: 450px;
    overflow-y: scroll;
    padding: ${theme.gutter / 4}px;
  `}
`;

export const SelectOptions = ({ options, activeIndex, onSelect, onCancel, renderOption }: SelectOptionsProps) => {
  const [focusIndex, setFocusIndex] = useFocusIndex({
    initialIndex: activeIndex,
    maxIndex: options.length - 1,
    onSelect: (index) => onSelect(options[index]),
    onCancel,
  });

  return (
    <SelectOptionsContainer>
      {options.map((option, index) => (
        <SelectOption
          tabIndex={-1}
          onClick={() => onSelect(options[index])}
          onFocus={() => setFocusIndex(index)}
          onMouseEnter={() => setFocusIndex(index)}
          sc={{ active: activeIndex === index, focus: focusIndex === index }}
          key={index}
        >
          {renderOption ? renderOption(option, false) : option}
        </SelectOption>
      ))}
    </SelectOptionsContainer>
  );
};

export default DropdownSelect;
