import React, { ChangeEvent, useRef } from 'react';

import ParticipantFieldInputProps from '../ParticipantFieldInputProps';
import UI from '../../UI';

const ShortTextInput = ({
  value, onChange, onBlur, onFocus, field, touched, errors, validating,
}: ParticipantFieldInputProps) => {
  const idRef = useRef(Math.round(Math.random() * 1000000));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ value: event.target.value });
  };

  return (
    <UI.InputGroup sc={{ valid: !errors?.value, touched }}>
      <UI.InputLabel htmlFor={`Field_${idRef.current}`}>
        {field.title}
        {' '}
        {field.required && <UI.RequiredMark />}
      </UI.InputLabel>
      {field.description && (
        <UI.InputDescription>
          <UI.HTML html={field.description} />
        </UI.InputDescription>
      )}
      <UI.InputWrapper>
        <UI.DebouncedInput
          value={value.value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          id={`Field_${idRef.current}`}
        />
        {validating && (
          <UI.Icon>
            <UI.Loader sc={{ brand: 'gray.400' }} />
          </UI.Icon>
        )}
      </UI.InputWrapper>
      <UI.ErrorMessages attribute={field.title} errors={errors?.value} />
    </UI.InputGroup>
  );
};

export default ShortTextInput;
