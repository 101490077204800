import { SVGMotionProps, motion } from 'framer-motion';
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';

interface AnimateHeightProps {
  duration?: number;
  ease?: string;
  variants?: any;
  isVisible: boolean;
  children: ReactNode;
  style?: any;
}

/**
 * Shamelessly stolen/adapted from https://codesandbox.io/s/animate-height-framer-motion-yn59l
 */
export const AnimateHeight = ({
  duration,
  ease = 'easeOut',
  variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  isVisible,
  children,
  ...props
}: AnimateHeightProps) => {
  const ref = useRef(null);
  const height = useHeight(ref);
  const [animating, setAnimating] = useState(false);
  const [showing, setShowing] = useState(isVisible);

  const isVisibleRef = useRef(isVisible);

  useEffect(() => {
    if (isVisibleRef.current !== isVisible && height > 0) {
      setShowing(isVisible);
      setAnimating(true);
      isVisibleRef.current = isVisible;
    }
  }, [isVisible, height]);

  if (!isVisible && !animating && !showing) {
    return null;
  }

  return (
    <Container
      initial={showing ? 'open' : 'collapsed'}
      animate={showing ? 'open' : 'collapsed'}
      style={{ overflow: animating ? 'hidden' : undefined }}
      variants={variants}
      inherit={false}
      transition={{
        ease,
        duration: typeof duration === 'number'
          ? duration
          : getAutoHeightDuration(height) / 1000,
      }}
      onAnimationComplete={() => setAnimating(false)}
      {...props}
    >
      <div ref={ref}>{children}</div>
    </Container>
  );
};

interface MotionPathProps extends Omit<SVGMotionProps<SVGPathElement>, 'from' | 'to'> {
  isActive: boolean;
  from: any;
  to: any;
}

export const MotionPath = ({
  from,
  to,
  isActive,
  ...props
}: MotionPathProps) => (
  <motion.path {...props} animate={isActive ? to : from} initial={from} />
);

interface MotionGroupProps extends Omit<SVGMotionProps<SVGGElement>, 'from' | 'to'> {
  isActive: boolean;
  from: any;
  to: any;
}

export const MotionGroup = ({
  from,
  to,
  isActive,
  ...props
}: MotionGroupProps) => (
  <motion.g {...props} animate={isActive ? to : from} initial={from} />
);

const Container = motion.div;

function getAutoHeightDuration(height: number) {
  if (!height) {
    return 0;
  }

  const constant = height / 48;
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

function useHeight(ref: RefObject<any>) {
  const [height, setHeight] = useState(0);

  function onResize([entry]: ResizeObserverEntry[]) {
    setHeight(entry.contentRect.height);
  }

  useEffect(() => {
    const callback = debounce(onResize, 25);
    const observer = new ResizeObserver(callback);
    const element = ref?.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      callback.cancel();
      observer.disconnect();
    };
  });

  return height;
}

export default undefined;
