import { AlertTriangle, Calendar, ExternalLink, Globe, MapPin, X } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { assetUrl, getUrlWithoutPrefix, resizedImageUrl } from '../../common/helpers';
import { mix } from '../../theme/helpers';
import { routes } from '../../homepage/routes';
import CookieConsentBar from '../../common/Common/CookieConsentBar';
import DateRange from '../../common/Common/DateRange';
import LanguageSelector from '../../common/Common/LanguageSelector';
import UI from '../../common/UI';
import config from '../config';
import useLocale from '../../common/useLocale';

interface Event {
  id: string;
  title: string;
  logo: {
    id: string;
    url: string;
  } | null;
  checkout_url: string;
  start_date: string;
  end_date: string;
  location: {
    id: string | null;
    description: string | null;
  } | null;
  website_url: string | null;
  email: string | null;
  brand_color: string | null;
  active: boolean;
}

interface Project {
  id: string;
  google_analytics: string | null;
  facebook_pixel: string | null;
  organisation_name: string;
  organisation_address: string | null;
  organisation_zip_code: string | null;
  organisation_city: string | null;
  organisation_country: string | null;
  organisation_email: string | null;
  privacy_statement: string | null;
}

interface LayoutProps {
  event: Event & {
    project: Project;
  };
  embedded?: boolean;
  children?: ReactNode;
}

const Layout = ({
  event,
  embedded,
  children,
}: LayoutProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {!embedded && (event.project.google_analytics || event.project.facebook_pixel) && (
        <CookieConsentBar />
      )}

      {!event.active && (
        <UI.Card sc={{ background: 'tertiary', color: 'white' }}>
          <UI.H4 sc={{ color: 'white' }}>
            <UI.Icon>
              <AlertTriangle />
            </UI.Icon>
            {' '}
            {t('preview_mode')}
          </UI.H4>
          {t('preview_mode_description')}
        </UI.Card>
      )}

      <EventHeader event={event} embedded={embedded} />

      {children}

      <UI.HR style={{ borderColor: 'rgba(0, 0, 0, 0.10)' }} />

      <EventFooter event={event} />

      <UI.HR style={{ borderColor: 'rgba(0, 0, 0, 0.10)' }} />

      <AtletaFooter />
    </Container>
  );
};

export const Container = styled(UI.GridContainer)`
  ${({ theme }) => css`
    min-width: 320px;
    max-width: ${theme.breakpoints.md}px;
    margin: 0 auto;
    padding: ${theme.gutter * 0.5}px;

    @media ${theme.devices.md} {
      padding: ${theme.gutter}px;
    }
  `}
`;

export const EventHeader = ({
  event,
  embedded,
}: {
  event: Event;
  embedded?: boolean;
}) => {
  const { search } = useLocation();

  return (
    <UI.GridContainer sc={{ columns: event.logo ? '105px 1fr' : '1fr', gutter: 0.75, alignVertical: 'center' }}>
      {event.logo && (
        <UI.Div>
          <UI.A href={!embedded ? `${event.checkout_url}${search}` : undefined}>
            <UI.ProfilePic
              sc={{ background: 'white', size: 105 }}
            >
              <img src={resizedImageUrl(event.logo.url, 300)} alt={event.title} />
            </UI.ProfilePic>
          </UI.A>
        </UI.Div>
      )}
      <UI.Div>
        <UI.H1 sc={{ fontSize: 2, mb: 1 }} style={{ fontWeight: 600 }}>
          <UI.A href={!embedded ? `${event.checkout_url}${search}` : undefined} sc={{ color: 'gray.900' }}>
            {event.title}
          </UI.A>
        </UI.H1>
        <EventInfo event={event} />
      </UI.Div>
    </UI.GridContainer>
  );
};

export interface EventInfoProps {
  event: {
    start_date: string;
    end_date: string;
    location: {
      id: string;
      description: string;
    } | null;
    website_url?: string | null;
  };
}

export const EventInfo = ({ event }: EventInfoProps) => (
  <UI.GridContainer sc={{ gutter: 0.1, fontSize: 1 }}>
    <UI.GridContainer sc={{ columns: '20px 1fr', gutter: 0.25 }}>
      <UI.Div>
        <UI.Icon sc={{ muted: true }}>
          <Calendar />
        </UI.Icon>
      </UI.Div>
      <UI.Div sc={{ color: 'gray.500' }} style={{ fontWeight: 500 }}>
        <DateRange start={event.start_date} end={event.end_date} />
      </UI.Div>
    </UI.GridContainer>

    {(event.location.id || event.location.description) && (
      <UI.GridContainer sc={{ columns: '20px 1fr', gutter: 0.25 }}>
        <UI.Div>
          <UI.Icon sc={{ muted: true }}>
            <MapPin />
          </UI.Icon>
        </UI.Div>
        {event.location.id && (
          <UI.Div style={{ fontWeight: 500 }}>
            <UI.A
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location.description)}&query_place_id=${encodeURIComponent(event.location.id)}`}
              target="_blank"
              rel="noopener noreferrer"
              sc={{ color: 'gray.500' }}
            >
              {event.location.description.split(',')[0]}
            </UI.A>
          </UI.Div>
        )}
        {!event.location.id && (
          <UI.Div style={{ fontWeight: 500 }}>
            {event.location.description}
          </UI.Div>
        )}
      </UI.GridContainer>
    )}

    {event.website_url && (
      <UI.GridContainer sc={{ columns: '20px 1fr', gutter: 0.25 }}>
        <UI.Div>
          <UI.Icon sc={{ muted: true }}>
            <Globe />
          </UI.Icon>
        </UI.Div>
        <UI.Div style={{ fontWeight: 500, minWidth: 0 }}>
          <UI.Truncate sc={{ color: 'link' }}>
            <UI.A href={event.website_url} target="_blank" rel="noopener noreferrer" sc={{ color: 'gray.500' }}>
              {getUrlWithoutPrefix(event.website_url)}
            </UI.A>
          </UI.Truncate>
        </UI.Div>
      </UI.GridContainer>
    )}
  </UI.GridContainer>
);

export interface OrganizerInfoProps {
  event: Event & {
    project: Project;
  };
}

export const OrganizerInfo = ({ event }: OrganizerInfoProps) => {
  const { project } = event;

  return (
    <>
      <strong>
        {project.organisation_name}
      </strong>
      {!event.email && project.organisation_email && (
        <UI.Div sc={{ mt: 2 }}>
          <UI.A href={`mailto:${project.organisation_email}`}>
            {project.organisation_email}
          </UI.A>
        </UI.Div>
      )}
      {event.email && (
        <UI.Div sc={{ mt: 2 }}>
          <a href={`mailto:${event.email}`}>
            {event.email}
          </a>
        </UI.Div>
      )}
    </>
  );
};

interface EventFooterProps {
  event: Event & {
    project: Project;
  };
}

export const EventFooter = ({
  event,
}: EventFooterProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { project } = event;

  const [showPrivacyStatement, setShowPrivacyStatement] = useState(false);

  return (
    <>
      <UI.Div sc={{ textAlign: 'center' }}>
        <OrganizerInfo event={event} />

        {!showPrivacyStatement && (
          <UI.Div sc={{ mt: 2 }}>
            <UI.A
              onClick={() => setShowPrivacyStatement((show) => !show)}
              role="button"
            >
              {t('privacy_statement')}
            </UI.A>
          </UI.Div>
        )}
      </UI.Div>

      {showPrivacyStatement && (
        <PrivacyStatementContainer sc={{ outline: 'gray.250', mx: [4, 0] }}>
          <UI.A
            onClick={() => setShowPrivacyStatement(false)}
            sc={{ pullRight: true, color: 'gray.400', inlineBlock: true, padding: 0.5 }}
          >
            <UI.Icon>
              <X />
            </UI.Icon>
          </UI.A>
          {project.privacy_statement && (
          <>
            <UI.HTML html={project.privacy_statement} />
            <UI.HR />
          </>
          )}
          <UI.H3>
            {t('atleta')}
          </UI.H3>
          <UI.Paragraph>
            {t('atleta_privacy_statement_intro', { organizer: project.organisation_name })}
          </UI.Paragraph>
          <UI.A
            href={`${config.baseUrl}/${routes[locale].privacy}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('read_atletas_privacy_statement')}
            {' '}
            <UI.Icon>
              <ExternalLink />
            </UI.Icon>
          </UI.A>
        </PrivacyStatementContainer>
      )}
    </>
  );
};

const PrivacyStatementContainer = styled(UI.Card)`
  font-size: .9em;

  h1 {
    font-weight: 600;
    font-size: 1.5em;
  }

  h2 {
    font-weight: 600;
    font-size: 1.333em;
  }

  h3 {
    font-weight: 600;
    font-size: 1.25em;
  }
`;

export const AtletaFooter = () => {
  const { t } = useTranslation();

  return (
    <UI.FlexContainer sc={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <UI.Div>
        <LanguageSelector />
      </UI.Div>
      <UI.Div>
        <a href={config.baseUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={assetUrl('images/logo-black.svg')}
            alt={t('atleta')}
            width={65}
            height={14}
            style={{ opacity: 0.3 }}
          />
        </a>
      </UI.Div>
    </UI.FlexContainer>
  );
};

export const TitleBar = styled(UI.Div)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 110px 1fr 110px;
    background: ${mix(theme.colors.secondary[150], theme.colors.gray[200], 0.3)};
    color: ${mix(theme.colors.secondary[300], theme.colors.gray[400], 0.5)};
    margin-bottom: -10px;
    z-index: -1;
    height: 60px;
    line-height: 50px;
    border-top-left-radius: ${theme.borderRadiuses.xl}px;
    border-top-right-radius: ${theme.borderRadiuses.xl}px;

    // Back button
    > ${UI.Div}:first-child a {
      display: inline-block;
      color: ${mix(theme.colors.secondary[500], theme.colors.gray[600], 0.5)};
      height: 50px;
      padding: 0 ${theme.gutter * 0.75}px;
      transition: all 0.15s ease-in-out;

      &:hover {
        color: ${mix(theme.colors.secondary[600], theme.colors.gray[700], 0.5)};
      }
    }

    // Step title
    > ${UI.Div}:nth-child(2) {
      color: ${mix(theme.colors.secondary[800], theme.colors.gray[900], 0.5)};
      text-align: center;
      font-weight: 500;
    }

    // Step number
    > ${UI.Div}:nth-child(3) {
      color: ${mix(theme.colors.secondary[500], theme.colors.gray[600], 0.5)};
      text-align: right;
      padding: 0 ${theme.gutter * 0.75}px;
      font-size: 0.875rem;
    }

    @media ${theme.devices.md} {
      // Back button
      > ${UI.Div}:first-child ${UI.Link} {
        padding: 0 ${theme.gutter}px;
      }

      // Step number
      > ${UI.Div}:nth-child(3) {
        padding: 0 ${theme.gutter}px;
      }
    }
  `}
`;

export default Layout;
