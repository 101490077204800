import { ChevronDown, ChevronUp, Clock } from 'react-feather';
import { FormManager } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useState } from 'react';
import sortBy from 'lodash/sortBy';

import { Charity } from '../Fundraising/CharityPicker';
import {
  EditRegistrationInput, FindOrAddTeamInput, Gender, ParticipantAttributes, ParticipantFieldScope, ParticipantFieldType,
  RegistrationInput,
} from '../../__generated__/globalTypes';
import { ErrorBag, Update } from '../helpers';
import AssignmentForm from './AssignmentForm';
import EditUpgradeForm from './EditUpgradeForm';
import FundraisingForm from '../Fundraising/FundraisingForm';
import TeamPicker from '../Teams/TeamPicker';
import UI from '../UI';
import useTimeSlotFormatter from '../Tickets/useTimeSlotFormatter';

interface Event {
  id: string;
  enable_fundraising: boolean;
  require_fundraising: boolean;
  allow_other_charity: boolean;
  charities: Charity[];
}

interface ParticipantField {
  id: string;
  title: string;
  description: string | null;
  type: ParticipantFieldType;
  scope: ParticipantFieldScope;
  required_promotions: {
    id: string;
  }[];
  enabled_choices: {
    id: string;
    title: string;
    position: number;
    enabled: boolean;
  }[];
  required: boolean;
  config: string | null;
  has_external_validation: boolean;
}

interface ParticipantFieldEntry {
  id: string | null;
  field: {
    id: string;
  };
  value: string | null;
  choice_entries: {
    id: string | null;
    choice: {
      id: string;
      title: string;
      position: number;
      enabled: boolean;
    };
  }[];
}

export interface Registration {
  id: string;
  assigned?: boolean;
  is_reassignable?: boolean;
  required_attributes: ParticipantAttributes[];
  editable_attributes: ParticipantAttributes[];
  date_of_birth?: string | null;
  gender?: Gender | null;
  nationality?: string | null;
  street?: string | null;
  house_number?: string | null;
  zip_code?: string | null;
  city?: string | null;
  extra_address_line?: string | null;
  country?: string | null;
  phone?: string | null;
  emergency_phone?: string | null;
  participant?: {
    email: string;
    first_name: string;
    last_name: string;
  } | null;
  ticket: {
    allow_individuals: boolean;
    allow_create_team: boolean;
    allow_join_team: boolean;
    upcoming_time_slots: {
      id: string;
      start_date: string;
      start_time: string | null;
      title: string | null;
      multi_date: boolean;
      is_sold_out: boolean;
    }[];
  };
  time_slot: {
    id: string;
    start_date: string;
    start_time: string | null;
    title: string | null;
    multi_date: boolean;
  } | null;
  charity?: {
    id: string;
    title: string;
    logo_url: string | null;
    description: string | null;
  } | null;
  default_charity?: {
    id: string;
    title: string;
    logo_url: string | null;
    description: string | null;
  } | null;
  team?: {
    id: string;
    title: string;
  } | null;
  participant_fields: ParticipantField[];
  participant_field_entries?: ParticipantFieldEntry[];
  upgrades: {
    id: string;
    product: {
      id: string;
      title: string;
      is_ticket_fee: boolean;
      donation: boolean;
      active_product_variants: {
        id: string;
        title: string;
        is_sold_out: boolean;
        position: number;
      }[];
    };
    promotion: {
      id: string;
      title: string | null;
    };
    product_variant: {
      id: string;
      title: string;
      is_sold_out: boolean;
      position: number;
    } | null;
    participant_fields: ParticipantField[];
    participant_field_entries?: ParticipantFieldEntry[];
  }[];
}

export interface EditRegistrationFormProps {
  form: FormManager<EditRegistrationInput>;
  registration: Registration;
  event: Event;
  candidates?: RegistrationInput[];
  errors?: ErrorBag;
  updateCustomErrors?: (errors: ErrorBag, prefix?: string) => void;
  updateValidating?: (validating: string[], prefix?: string) => void;
}

const EditRegistrationForm = ({
  form, registration, event, candidates = [], errors, updateCustomErrors, updateValidating,
}: EditRegistrationFormProps) => {
  const { t } = useTranslation('common');
  const formatTimeSlot = useTimeSlotFormatter();

  const { ticket } = registration;

  let timeSlots = [...ticket.upcoming_time_slots];

  if (registration.time_slot) {
    const timeSlotIds = timeSlots.map(({ id }) => id);

    if (!timeSlotIds.includes(registration.time_slot.id)) {
      timeSlots.push({
        ...registration.time_slot,
        is_sold_out: false,
      });

      timeSlots = sortBy(timeSlots, (timeSlot) => [timeSlot.start_date, timeSlot.start_time]);
    }
  }

  const allowTeam = (ticket.allow_create_team || ticket.allow_join_team)
    && (!registration.team || ticket.allow_individuals);
  const allowTimeSlot = (timeSlots.length === 1 && !registration.time_slot) || timeSlots.length > 1;

  const handleTeamChange = (team: FindOrAddTeamInput | null) => {
    form.set((registration) => ({
      ...registration,
      team,
    }));
  };

  const handleAssignmentChange = (update: Update<EditRegistrationInput>) => {
    form.set((registration) => ({
      ...registration,
      ...update(registration),
    }));
  };

  const {
    charities, allowOtherCharity, showFundraisingSection, requireCharity,
  } = getFundraisingSettings(registration, event);

  const [showFundraisingForm, setShowFundraisingForm] = useState(requireCharity);

  const toggleFundraisingForm = () => {
    setShowFundraisingForm((show) => !show);
  };

  const [charity, setCharity] = useState(registration.charity);

  const handleCharityChange = (charity: Charity | null) => {
    setCharity(charity);

    form.set((values) => ({
      ...values,
      charity: charity ? { id: charity.id } : null,
    }));
  };

  return (
    <UI.FormGrid>
      {allowTimeSlot && (
        <>
          <UI.Legend>
            {t('edit_registration_form.time_slot')}
          </UI.Legend>
          <UI.InputGroup sc={{ valid: !errors['time_slot.id'], touched: form.getTouched('time_slot') }}>
            <UI.InputWrapper>
              <UI.Icon sc={{ muted: true }}>
                <Clock />
              </UI.Icon>
              <UI.Select
                {...form.select('time_slot.id', timeSlots.map(({ id }) => id))}
                sc={{ hasValue: !!form.values.time_slot, pl: 5 }}
                id="TimeSlot"
              >
                {!form.values.time_slot?.id && (
                  <option value={-1} disabled>
                    {t('edit_registration_form.make_a_choice')}
                  </option>
                )}
                {timeSlots.map((timeSlot, index) => (
                  <option
                    {...form.option(index)}
                    disabled={timeSlot.id !== registration.time_slot?.id && timeSlot.is_sold_out}
                  >
                    {formatTimeSlot(timeSlot)}
                    {timeSlot.title && ` • ${timeSlot.title}`}
                    {timeSlot.id !== registration.time_slot?.id && timeSlot.is_sold_out
                      && ` • ${t('edit_registration_form.unavailable')}`}
                  </option>
                ))}
              </UI.Select>
            </UI.InputWrapper>
            <UI.ErrorMessages attribute={t('edit_registration_form.time_slot')} errors={errors['time_slot.id']} />
          </UI.InputGroup>
          <UI.HR sc={{ dashed: true }} />
        </>
      )}

      {allowTeam && (
        <>
          <UI.Legend>
            {t('edit_registration_form.team')}
          </UI.Legend>
          <TeamPicker
            team={form.values.team}
            eventId={event.id}
            allowIndividuals={ticket.allow_individuals}
            allowCreateTeam={ticket.allow_create_team}
            allowJoinTeam={ticket.allow_join_team}
            onChange={handleTeamChange}
            errors={errors}
            touched={form.touched.tean}
            touch={(field: string) => form.setTouched(`team.${field}`, true)}
            untouch={() => form.setTouched('team.title', false)}
          />
          <UI.HR sc={{ dashed: true }} />
        </>
      )}

      <UI.Legend>
        {t('edit_registration_form.personal_details')}
      </UI.Legend>

      <AssignmentForm
        registration={form.values}
        onChange={handleAssignmentChange}
        onBlur={(field) => form.setTouched(field, true)}
        touched={form.touched}
        reassignable={registration.is_reassignable}
        registrationAttributes={
          registration.assigned ? registration.editable_attributes : registration.required_attributes
        }
        candidates={candidates}
        allowAssignLater={false}
        errors={errors}
        enabledParticipantFields={registration.participant_fields}
        existingFieldEntries={registration.participant_field_entries}
        updateCustomErrors={updateCustomErrors}
        updateValidating={updateValidating}
      />

      {form.values.upgrades.map((value, index) => (
        <Fragment key={value.id}>
          <UI.HR sc={{ dashed: true }} />
          <EditUpgradeForm
            form={form}
            upgrade={registration.upgrades.find(({ id }) => id === value.id)}
            formKey={`upgrades.${index}`}
            errors={errors}
            registration={form.values}
          />
        </Fragment>
      ))}

      {showFundraisingSection && (
        <>
          <UI.HR sc={{ dashed: true }} />
          <UI.Legend>
            <UI.A role="button" onClick={() => toggleFundraisingForm()}>
              {t('edit_registration_form.fundraising_page')}
              {' '}
              <UI.Icon>
                {showFundraisingForm ? <ChevronUp /> : <ChevronDown />}
              </UI.Icon>
            </UI.A>
          </UI.Legend>
          <UI.AnimateHeight isVisible={showFundraisingForm}>
            <UI.FormGrid>
              <FundraisingForm
                charity={charity}
                eventId={event.id}
                onChange={handleCharityChange}
                charities={charities}
                allowOtherCharity={allowOtherCharity}
                required={event.require_fundraising}
                errors={errors}
                touched={form.touched}
              />
            </UI.FormGrid>
          </UI.AnimateHeight>
        </>
      )}
    </UI.FormGrid>
  );
};

export const getFundraisingSettings = (
  registration: { id: string; assigned?: boolean; default_charity?: Charity | null; },
  event: Event,
) => {
  const charities = registration.default_charity ? [registration.default_charity] : event.charities;
  const allowOtherCharity = !registration.default_charity && event.allow_other_charity;

  const showFundraisingSection = event.enable_fundraising
    // If fundraising is optional and the registration is assigned, don't show the form.
    && !(!event.require_fundraising && registration.assigned)
    // If fundraising is required and there is exactly 1 charity to choose from, don't show the form.
    && !(event.require_fundraising && !allowOtherCharity && charities.length === 1)
    // If the registration is still being claimed and it has a default charity, don't show the form
    // (the default charity will automatically be selected by the server).
    && !(!registration.assigned && registration.default_charity);

  const requireCharity = showFundraisingSection && event.require_fundraising;

  return { charities, allowOtherCharity, showFundraisingSection, requireCharity };
};

export default EditRegistrationForm;
