import useLocale from './useLocale';

interface UseCurrencyInputOptions {
  max?: number;
  showDecimals?: boolean;
  clipMaxValue?: boolean;
}

const useCurrencyInput = ({
  max = 2 ** 31 - 1,
  showDecimals = true,
  clipMaxValue = true,
}: UseCurrencyInputOptions = {}) => {
  const { formatCurrency, parseCurrency, thousandsSeparator, decimalSeparator } = useLocale();

  const formatProps = {
    style: 'decimal',
    useGrouping: false,
    maximumFractionDigits: showDecimals ? 2 : 0,
  };

  const value = (parsedValue: number | null) => {
    if (parsedValue != null) {
      return formatCurrency(parsedValue, formatProps);
    }

    return '';
  };

  const parse = (value: string) => {
    if (value) {
      let parsedValue = parseCurrency(value);

      if (!showDecimals) {
        parsedValue = Math.round(parsedValue / 100) * 100;
      }

      if (!Number.isNaN(parsedValue)) {
        parsedValue = clipMaxValue ? Math.min(parsedValue, max) : parsedValue;
        return parsedValue;
      }
    }

    return null;
  };

  const format = (value: string) => {
    if (value) {
      let parsedValue = parseCurrency(value);

      if (!Number.isNaN(parsedValue)) {
        parsedValue = clipMaxValue ? Math.min(parsedValue, max) : parsedValue;
        return formatCurrency(parsedValue, formatProps);
      }
    }

    return '';
  };

  const match = new RegExp(`[0-9${thousandsSeparator}${decimalSeparator}]`, 'g');

  return {
    value,
    parse,
    format,
    match,
  };
};

export default useCurrencyInput;
