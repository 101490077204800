import gql from 'graphql-tag';

export default gql`
  query GetCharities($project_id: ID!, $page: Int, $per_page: Int, $search: String, $event_id: ID, $random: Boolean) {
    project(id: $project_id) {
      id
      charities(page: $page, per_page: $per_page, search: $search, event_id: $event_id, random: $random) {
        data {
          id
          title
          logo_url
          description
        }
        current_page
        last_page
        per_page
        total
        has_more_pages
      }
    }
  }
`;
