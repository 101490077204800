import styled, { css } from 'styled-components';

import { Span } from './Generic';
import { UIProps } from '../../theme/mixins';
import ProfilePic from './ProfilePic';
import SC from './SC';

export const Paragraph = styled.p<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    margin: 0 0 1em 0;

    ${theme.useMixins(props)}
  `}
`;

export const Sup = styled.sup<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    font-size: .6em;

    ${theme.useMixins(props)}
  `}
`;

export const Sub = styled.sub<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    font-size: .6em;

    ${theme.useMixins(props)}
  `}
`;

export const Strong = styled.strong<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    font-weight: 600;

    ${theme.useMixins(props)}
  `}
`;

export const H1 = styled.h1<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h1}rem;
    font-weight: 700;
    color: ${theme.getColor(color || 'gray.900')};
    line-height: 1.25;

    ${theme.useMixins(props)}
  `}
`;

export const H2 = styled.h2<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h2}rem;
    font-weight: 600;
    color: ${theme.getColor(color || 'gray.800')};
    line-height: 1.25;

    ${theme.useMixins(props)}
  `}
`;

export const H3 = styled.h3<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h3}rem;
    font-weight: 600;
    color: ${theme.getColor(color || 'gray.800')};
    line-height: 1.25;

    ${theme.useMixins(props)}
  `}
`;

export const H4 = styled.h4<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h4}rem;
    font-weight: 500;
    color: ${theme.getColor(color || 'gray.800')};
    line-height: 1.25;

    ${theme.useMixins(props)}
  `}
`;

export const H5 = styled.h5<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h5}rem;
    font-weight: 500;
    color: ${theme.getColor(color || 'gray.400')};
    line-height: 1.25;

    ${theme.useMixins(props)}
  `}
`;

export const H6 = styled.h6<SC<UIProps>>`
  ${({ sc: { color, ...props } = {}, theme }) => css`
    font-size: ${theme.fontSizes.h6}rem;
    font-weight: normal;
    color: ${theme.getColor(color || 'gray.400')};

    ${theme.useMixins(props)}
  `}
`;

export const Legend = styled(H5)`
  ${({ theme }) => css`
    font-size: 1em;
    font-weight: 400;
    color: ${theme.colors.gray[500]};

    a {
      color: ${theme.transparentize(theme.colors.gray[800], 0.2)};

      &:hover {
        color: ${theme.transparentize(theme.colors.gray[800], 0.1)};
      }
    }
  `}
`;

export const SectionHeading = styled(H3)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[900]};
    min-height: 40px;
    line-height: 40px;
    margin: 0;

    ${ProfilePic} {
      margin-right: ${theme.gutter / 2}px;
      margin-top: -5px;
    }
  `}
`;

export const Small = styled.small<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

export const Big = styled.big<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

export const Label = styled(Span)`
  background: rgba(0, 0, 0, .05);
  padding: .08em .3em .1em .3em;
  border-radius: 2px;
  display: inline-block;
`;

export const Truncate = styled(Span)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const Caret = styled(Span)`
  ${({ theme, sc: { color } = {} }) => css`
    width: 0;
    height: 0;
    margin: 0 2px 2px 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid ${theme.getColor(color || 'gray.500')};
    display: inline-block;
  `}
`;

export const Code = styled.code<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    font-family: ${theme.fontFamilies.code};
    color: ${theme.colors.code};
    font-weight: lighter;
    font-size: 0.9em;

    ${theme.useMixins(props)}
  `}
`;

export const Pre = styled.pre<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    font-family: ${theme.fontFamilies.code};
    color: ${theme.colors.code};
    font-weight: lighter;
    font-size: 0.9em;
    overflow-x: auto;
    width: 100%;
    margin: 0;
    padding: 0;

    ${theme.useMixins(props)}
  `}
`;
