import React from 'react';

import { ParticipantAttributes } from '../../__generated__/globalTypes';
import AddressInput, { AddressInputValue } from './Attributes/AddressInput';
import DateOfBirthInput, { DateOfBirthInputValue } from './Attributes/DateOfBirthInput';
import GenderInput, { GenderInputValue } from './Attributes/GenderInput';
import NationalityInput, { NationalityInputValue } from './Attributes/NationalityInput';
import ParticipantAttributeInputProps from './ParticipantAttributeInputProps';
import PhoneInput, { PhoneInputValue } from './Attributes/PhoneInput';

type ParticipantInputValue = AddressInputValue
& DateOfBirthInputValue
& NationalityInputValue
& PhoneInputValue
& GenderInputValue
& PhoneInputValue;

const ParticipantAttributeInput = (props: ParticipantAttributeInputProps<ParticipantInputValue>) => (
  <>
    {props.attribute === ParticipantAttributes.address && <AddressInput {...props} />}
    {props.attribute === ParticipantAttributes.date_of_birth && <DateOfBirthInput {...props} />}
    {props.attribute === ParticipantAttributes.nationality && <NationalityInput {...props} />}
    {props.attribute === ParticipantAttributes.emergency_phone && <PhoneInput {...props} />}
    {props.attribute === ParticipantAttributes.gender && <GenderInput {...props} />}
    {props.attribute === ParticipantAttributes.phone && <PhoneInput {...props} />}
  </>
);

export default ParticipantAttributeInput;
