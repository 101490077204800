import styled, { css } from 'styled-components';

import { UIProps } from '../../theme/mixins';
import SC from './SC';

interface OLProps extends UIProps {}

export const OL = styled.ol<SC<OLProps>>`
  ${({ sc: props = {}, theme }) => css`
    margin: 0 0 1em 0;
    padding: 0;

    ${theme.useMixins(props)}
  `}
`;

interface ULProps extends UIProps {}

export const UL = styled.ul<SC<ULProps>>`
  ${({ sc: props = {}, theme }) => css`
    margin: 0;
    padding: 0;

    ${theme.useMixins(props)}
  `}
`;

interface LIProps extends UIProps {}

export const LI = styled.li<SC<LIProps>>`
 ${({ sc: props = {}, theme }) => css`
    margin: 0 0  0 1.5em;
    padding: 0;

    ${theme.useMixins(props)}
 `}
`;
