import styled, { css } from 'styled-components';

import { Div } from './Generic';

const SubForm = styled(Div)`
  ${({ theme }) => css`
    background: ${theme.colors.gray[50]};
    border-left: 3px solid ${theme.colors.gray[100]};
    padding: ${theme.gutter}px;
  `}
`;

export default SubForm;
