import styled, { css } from 'styled-components';

import { Span } from './Generic';

const StatusIndicator = styled(Span)`
  ${({ sc: { background } = {}, theme }) => css`
    border-radius: 100%;
    width: .5em;
    height: .5em;
    display: inline-block;
    box-shadow: 0 0 0 3px ${theme.transparentize(theme.getColor(background || 'gray.400'), 0.8)};
    margin: 0 3px 1px 3px;
  `}
`;

export default StatusIndicator;
