import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, FocusEvent, useState } from 'react';

import { Div } from './Generic';
import { InputProps } from './helpers';
import { months } from '../../config';
import DebouncedInput from './DebouncedInput';
import FormGrid from './FormGrid';
import SC from './SC';
import Select from './Select';
import useLocale from '../useLocale';

interface DateInputProps extends SC<InputProps> {
  value: string | null;
  onChange?: (value: string | null) => any;
  touched?: boolean;
  onTouch?: () => any;
  name?: string;
  id?: string;
  hideYear?: boolean;
}

const DateInput = ({
  value, onChange, touched: initialTouched = false, onTouch, name = 'date', sc, id, hideYear,
}: DateInputProps) => {
  const { t } = useTranslation('common');
  const { parseDate, formatDate, dateIsValid } = useLocale();

  const [yearValue, monthValue, dayValue] = (value || '').slice(0, 10).split('-');

  const [year, setYear] = useState(yearValue);
  const [month, setMonth] = useState(monthValue);
  const [day, setDay] = useState(dayValue);

  const [touched, setTouched] = useState({
    day: initialTouched,
    month: initialTouched,
    year: initialTouched,
  });

  const filter = (val: string) => val.replace(/\D/g, '');

  const handleBlur = (key: 'day' | 'month' | 'year') => (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newTouched = { ...touched, [key]: true };

    setTouched(newTouched);

    const touchedAll = newTouched.day && newTouched.month && newTouched.year;
    const touchedSingle = newTouched[key] && !event.target.value;

    if (touchedAll || touchedSingle) {
      onTouch?.();
    }
  };

  const handleDayChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDay = filter(event.target.value);
    setDay(newDay);
    handleChange(newDay, month, year);
  };

  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    handleChange(day, newMonth, year);
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newYear = filter(event.target.value);
    setYear(newYear);
    handleChange(day, month, newYear);
  };

  const handleYearBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value) {
      const year = `${completeYear(value)}`;
      event.target.value = year;
      setYear(year);
    }

    handleBlur('year')(event);
  };

  const completeYear = (year: string) => {
    const yearNumber = parseInt(year, 10);
    const currentYear = (new Date()).getFullYear();

    if (yearNumber < (currentYear - 2000)) {
      return yearNumber + 2000;
    }

    if (yearNumber < 100) {
      return yearNumber + 1900;
    }

    return yearNumber;
  };

  const handleChange = (day: string, month: string, year: string) => {
    let newValue = null;

    if (day && month && year) {
      const dateString = `${completeYear(year)}-${month}-${day}`;

      if (dateIsValid(dateString, 'internal_date')) {
        const date = parseDate(
          `${completeYear(year)}-${month}-${day}`,
          { format: 'internal_date' },
        );

        if (date) {
          newValue = formatDate(date, { format: 'internal_date' });
        }
      }
    }

    onChange?.(newValue);
  };

  return (
    <FormGrid sc={{ columns: hideYear ? '1fr 2fr' : '2fr 4fr 3fr', gutter: 0.5 }}>
      <Div>
        <DebouncedInput
          type="text"
          value={day || ''}
          onChange={handleDayChange}
          onBlur={handleBlur('day')}
          name={`${name}.day`}
          placeholder={t('date_input.day')}
          inputMode="numeric"
          match={/[\d]/g}
          maxLength={2}
          sc={sc}
          id={id && `${id}_Day`}
        />
      </Div>
      <Div>
        <Select
          value={month || ''}
          onChange={handleMonthChange}
          onBlur={handleBlur('month')}
          name={`${name}.month`}
          sc={{ placeholder: !month, ...sc }}
          id={id && `${id}_Month`}
        >
          <option value="">{t('date_input.month')}</option>
          {months.map((key, index) => (
            <option value={`${index < 9 ? '0' : ''}${index + 1}`} key={key}>{t(`months.${key}`)}</option>
          ))}
        </Select>
      </Div>
      {!hideYear && (
        <Div>
          <DebouncedInput
            type="text"
            value={year || ''}
            onChange={handleYearChange}
            onBlur={handleYearBlur}
            name={`${name}.year`}
            placeholder={t('date_input.year')}
            inputMode="numeric"
            match={/[\d]/g}
            maxLength={4}
            sc={sc}
            id={id && `${id}_Year`}
          />
        </Div>
      )}
    </FormGrid>
  );
};

export default DateInput;
