import styled, { css } from 'styled-components';

import { UIProps } from '../../theme/mixins';
import SC from './SC';

export const Div = styled.div<SC<UIProps>>`
 ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

export const Span = styled.span<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

interface HRProps extends UIProps {
  borderWidth?: number;
  dashed?: boolean;
}

export const HR = styled.hr<SC<HRProps>>`
  ${({ sc: { borderWidth = 1, dashed, ...props } = {}, theme }) => css`
    border: solid rgba(0, 0, 0, ${dashed ? 0.14 : 0.08});
    border-width: ${borderWidth}px 0 0 0;
    margin: ${theme.gutter}px 0;
    padding: 0;

    ${dashed && css`
      border-style: dashed;
    `}

    @media ${theme.devices.md} {
      margin: ${theme.gutter * 1.5}px 0;
    }

    ${theme.useMixins(props)}
  `}
`;

export const Blockquote = styled.blockquote<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    border-left: 2px solid rgba(0, 0, 0, .12);
    padding: 0 0 0 ${theme.gutter / 2}px;
    margin: 0;

    ${theme.useMixins(props)}
  `}
`;
