import React from 'react';
import styled from 'styled-components';

import { Div } from './Generic';

export const HTMLContainer = styled(Div)`
  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  p:empty {
    margin-bottom: 1em;
  }
`;

const HTML = ({ html }: { html: string }) => (
  <HTMLContainer dangerouslySetInnerHTML={{ __html: html }} />
);

export default HTML;
