import React from 'react';

/* eslint-disable max-len */
const Registration = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.24,9.12V5.84l3.38,3.28Z" fill="currentColor" opacity="0.5" />
    <path d="M20.25,13h-7.5a.75.75,0,0,1,0-1.5h7.5a.75.75,0,0,1,0,1.5Z" fill="currentColor" opacity="0.5" />
    <path d="M17.88,16H12.75a.75.75,0,0,1,0-1.5h5.13a.75.75,0,0,1,0,1.5Z" fill="currentColor" opacity="0.5" />
    <path d="M20.25,19h-7.5a.75.75,0,0,1,0-1.5h7.5a.75.75,0,0,1,0,1.5Z" fill="currentColor" opacity="0.5" />
    <path d="M23.78,8.84h0L19.52,4.71A.76.76,0,0,0,19,4.5H14.92A5.24,5.24,0,1,0,9,10.42V15H3l-.29,0A3.1,3.1,0,0,0,0,18.13v5.12A.76.76,0,0,0,.76,24H21.25A2.75,2.75,0,0,0,24,21.26V9.38A.8.8,0,0,0,23.78,8.84ZM1.51,22.5V18.13a1.58,1.58,0,0,1,1.34-1.62A1.47,1.47,0,0,1,4,16.89,1.51,1.51,0,0,1,4.51,18v3a3,3,0,0,0,.4,1.5ZM9,20.87a1.58,1.58,0,0,1-1.34,1.62A1.5,1.5,0,0,1,6,21V18a2.93,2.93,0,0,0-.42-1.5H9ZM19.75,7l1.65,1.6H19.75ZM6,5.25A3.75,3.75,0,1,1,9.75,9,3.75,3.75,0,0,1,6,5.25Zm16.5,16a1.25,1.25,0,0,1-1.25,1.25H10.05a3.18,3.18,0,0,0,.45-1.64V10.42A5.22,5.22,0,0,0,14.92,6h3.33V9.38a.75.75,0,0,0,.75.74h3.5Z" fill="currentColor" />
    <path d="M8.83,7.42A.79.79,0,0,1,8.3,7.2L7.18,6.07A.75.75,0,0,1,8.24,5l.59.6L11,3.4A.75.75,0,1,1,12.1,4.46L9.36,7.2A.75.75,0,0,1,8.83,7.42Z" fill="currentColor" />
  </svg>
);

export default Registration;
