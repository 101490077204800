import React from 'react';

/* eslint-disable max-len */
const Resale = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path className="st0" d="M12.4,8.8l-8.8,0c-1.2,0-2.1,0.9-2.1,2.1l0,2.4c0,0,0,0,0,0c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c0,0,0,0,0,0l0,2.3c0,1.2,0.9,2.1,2.1,2.1l16.8,0c1.2,0,2.1-0.9,2.1-2.1l0-2.3c0,0,0,0,0,0c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c0,0,0,0,0,0l0-2.4c0-1.2-0.9-2.1-2.1-2.1" />
    <line className="st0" x1="19.2" y1="5.1" x2="1.5" y2="5.1" />
    <polyline className="st0" points="15.9,1.5 19.5,5.1 15.9,8.8 " />
    <line className="st0" x1="16.7" y1="19.3" x2="16.7" y2="19.3" />
    <line className="st0" x1="16.7" y1="15.6" x2="16.7" y2="15.6" />
    <line className="st0" x1="16.7" y1="12" x2="16.7" y2="12" />
  </svg>

);

export default Resale;
