import { DocumentNode } from 'graphql';

import {
  OperationVariables, QueryHookOptions, QueryResult, useQuery as useApolloQuery,
} from '@apollo/client';

function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode, options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> & { currentData: TData; } {
  options = options || {};

  options.fetchPolicy = options.fetchPolicy || 'network-only';

  // Used when the cache updates, avoids lots of refetches
  options.nextFetchPolicy = options.nextFetchPolicy || 'cache-first';

  const result = useApolloQuery<TData, TVariables>(query, options);

  return {
    ...result,
    // When a query is refetched, this prevents data from becoming undefined,
    // by showing the previous data
    data: result.data || result.previousData,
    currentData: result.data,
  };
}

export default useQuery;
