import styled, { css } from 'styled-components';

import { Span } from './Generic';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

interface ShakeProps {
  shake?: boolean;
}

const Shake = styled(Span)<SC<UIProps & ShakeProps>>`
  display: inline-block;

  ${({ sc: { shake = true } = {} }) => shake && css`
    animation: shake 6s ease-in-out infinite;
  `}

  @keyframes shake {
    0% { transform: translateX(0); }
    86% { transform: translateX(0); }
    88% { transform: translateX(-3px); }
    90% { transform: translateX(3px); }
    92% { transform: translateX(-2px); }
    94% { transform: translateX(2px); }
    96% { transform: translateX(-1px); }
    98% { transform: translateX(1px); }
  }
`;

export default Shake;
