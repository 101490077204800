import { css } from 'styled-components';

import { InputProps } from './helpers';
import SC from './SC';

const useInput = ({ sc: { background, color, placeholder } = {}, theme }: SC<InputProps>) => {
  const backgroundColor = background
    ? theme.getColor(background)
    : theme.colors.white;

  const borderColor = background && background !== 'transparent'
    ? theme.desaturate(theme.darkenColor(backgroundColor, 16), 40)
    : theme.colors.gray[250];

  return css`
    border: 1px solid ${borderColor};
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    background: ${backgroundColor};
    color: ${color ? theme.getColor(color) : theme.getTextColor(backgroundColor)};
    border-radius: ${theme.borderRadiuses.md}px;
    width: 100%;
    appearance: none;

    ::placeholder {
      color: ${theme.colors.gray[400]};
    }

    ${placeholder && css`
      color: ${theme.colors.gray[400]};
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &:focus {
      border-color: ${theme.colors.secondary[500]};
      box-shadow: 0 0 0 3px ${theme.transparentize(theme.colors.secondary[500], 0.85)};
    }

    &:disabled {
      opacity: 0.5;
    }

    ${theme.useBackground({ background })}
  `;
};

export default useInput;
