import { createGlobalStyle, css } from 'styled-components';

import { Theme } from '../theme';

const GlobalStyle = createGlobalStyle`
  ${({ theme }: { theme: Theme; }) => css`
    html {
      font-size: ${theme.fontSizes.root};

      @media (max-width: 320px) {
        // Wix scales websites to 320px on mobile, which causes the app to appear zoomed in.
        // This is partly mitigated by reducing the font size.
        font-size: 14px;
      }
    }

    body {
      margin: 0;
      background: ${theme.getColor(theme.bodyBackground)};
      touch-action: manipulation;
    }

    body, input, textarea, select, button {
      font-family: ${theme.fontFamilies.body};
      font-size: ${theme.fontSizes.body}rem;
      line-height: 1.375; // 22px
      color: ${theme.colors.body};
    }

    input, textarea, select, button {
      outline: none;
    }

    * {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      color: ${theme.colors.link};
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-left: 1.5em;
        padding: 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${theme.fontFamilies.headings};
      margin: 0;
      padding: 0;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    img {
      max-width: 100%;
    }

    hr {
      margin: 1em 0;
      border: solid rgba(0, 0, 0, .1);
      border-width: 1px 0 0 0;
      padding: 0;
    }

    strong {
      font-weight: 600;
    }
  `}
`;

export default GlobalStyle;
