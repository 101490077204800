import * as helpers from './helpers';
import * as mixins from './mixins';
import getVariables from './variables';

export interface ThemeOptions {
  secondary?: string;
  bodyBackground?: string;
  scrollOffset?: {
    sm: number,
    md: number,
  },
}

export const getTheme = (options: ThemeOptions = {}) => ({
  ...getVariables(options),
  ...helpers,
  ...mixins,
});

const defaultTheme = getTheme();

export type Theme = typeof defaultTheme;

export default defaultTheme;
