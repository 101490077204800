import gql from 'graphql-tag';

export default gql`
  mutation ResellRegistration($input: ResellRegistrationInput!) {
    resellRegistration(input: $input) {
      status
      redirect_url
    }
  }
`;
