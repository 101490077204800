import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';

import { TrackingProvider } from '../common/Tracking';
import { client } from '../api/apollo';
import AtletaTrackingProvider from '../common/Common/AtletaTrackingProvider';
import Checkout from './Checkout/Main';
import CookieConsentProvider from '../common/Common/CookieConsentProvider';
import EmbedProvider from './Common/EmbedProvider';
import GlobalStyle from '../common/GlobalStyle';
import Resale from './Resale/Main';
import RollbarProvider from '../common/Common/RollbarProvider';
import ThemeProvider from '../common/Common/ThemeProvider';
import config from './config';

const App = () => (
  <RollbarProvider>
    <ThemeProvider options={{ bodyBackground: 'transparent' }}>
      <CookieConsentProvider>
        <ApolloProvider client={client}>
          <BrowserRouter basename={config.appUrl}>
            <AtletaTrackingProvider googleAnalytics>
              <Route
                path="/:eventId?"
                render={({ match: { params: { eventId } } }) => (
                  <TrackingProvider defaultPayload={{ event: eventId }}>
                    <EmbedProvider eventId={eventId}>
                      <Switch>
                        <Route
                          path="/:eventId/resale"
                          render={({ match: { params: { eventId } } }) => (
                            <Resale eventId={eventId} />
                          )}
                        />
                        <Route
                          path="/:eventId?/:step?"
                          render={({ match: { params: { eventId, step } } }) => (
                            <Checkout eventId={eventId} step={step as any} />
                          )}
                        />
                      </Switch>
                    </EmbedProvider>
                  </TrackingProvider>
                )}
              />
            </AtletaTrackingProvider>
          </BrowserRouter>
        </ApolloProvider>
      </CookieConsentProvider>
      <GlobalStyle />
    </ThemeProvider>
  </RollbarProvider>
);

export default App;
