import React, { ReactNode } from 'react';

import UI from '../UI';

interface QuantityLabelProps {
  children: ReactNode;
}

const QuantityLabel = ({ children }: QuantityLabelProps) => (
  <UI.Span
    sc={{
      background: 'gray.200',
      inlineBlock: true,
      small: true,
      px: 0.75,
      py: 0.25,
    }}
    style={{ fontWeight: 500 }}
  >
    {children}
  </UI.Span>
);

export default QuantityLabel;
