import React from 'react';

/* eslint-disable max-len */
const Customization = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle cx="13.25" cy="15.25" r="2" fill="currentColor" opacity="0.5" />
    <circle cx="11.25" cy="3.25" r="2" fill="currentColor" opacity="0.5" />
    <circle cx="5.75" cy="9.25" r="2" fill="currentColor" opacity="0.5" />
    <circle cx="19.88" cy="21.25" r="2" fill="currentColor" opacity="0.5" />
    <path d="M23.25,2.5H13.88a2.73,2.73,0,0,0-5.26,0H.75A.75.75,0,0,0,.75,4H8.62a2.73,2.73,0,0,0,5.26,0h9.37a.75.75,0,0,0,0-1.5Zm-12,2A1.25,1.25,0,1,1,12.5,3.25,1.25,1.25,0,0,1,11.25,4.5Z" fill="currentColor" />
    <path d="M23.25,8.5H8.38a2.73,2.73,0,0,0-5.26,0H.75a.75.75,0,0,0,0,1.5H3.12a2.73,2.73,0,0,0,5.26,0H23.25a.75.75,0,0,0,0-1.5Zm-17.5,2A1.25,1.25,0,1,1,7,9.25,1.25,1.25,0,0,1,5.75,10.5Z" fill="currentColor" />
    <path d="M23.25,14.5H15.88a2.73,2.73,0,0,0-5.26,0H.75a.75.75,0,0,0,0,1.5h9.87a2.73,2.73,0,0,0,5.26,0h7.37a.75.75,0,0,0,0-1.5Zm-10,2a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,13.25,16.5Z" fill="currentColor" />
    <path d="M17.88,21.25A2,2,0,0,1,18,20.5H.75a.75.75,0,0,0,0,1.5H18A2,2,0,0,1,17.88,21.25Z" fill="currentColor" />
    <path d="M23.25,20.5h-.74a2.75,2.75,0,1,0,0,1.5h.74a.75.75,0,0,0,0-1.5Zm-3.37,2a1.25,1.25,0,1,1,1.24-1.25A1.26,1.26,0,0,1,19.88,22.5Z" fill="currentColor" />
  </svg>
);

export default Customization;
