import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import UI from '../../common/UI';

interface PageLoaderProps {
  throttled?: boolean;
}

const PageLoader = ({ throttled }: PageLoaderProps) => {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState(false);
  const message = throttled ? t('common:validation.too_many_attempts') : t('slow_fetch_message');

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setShowMessage(true);
    }, 3000);

    return () => window.clearTimeout(timer);
  }, []);

  return (
    <UI.Div sc={{ textAlign: 'center' }}>
      <UI.PageLoader />
      {showMessage && message}
    </UI.Div>
  );
};

export default PageLoader;
