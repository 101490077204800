import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { AddressValue } from './Types/AddressInput';
import {
  AuLicenseData, ParticipantField, ParticipantFieldEntry, convertToParticipantFieldEntryInput, validateFieldEntry,
} from './helpers';
import { ParticipantFieldType } from '../../__generated__/globalTypes';
import { formatTime } from '../helpers';
import Address from '../../backend/Common/Address';
import UI from '../UI';

const formatAtletiekunieLicense = (value: string | null) => {
  if (value === null) {
    return '';
  }

  const data: AuLicenseData = JSON.parse(value);

  return (
    <UI.Delimit>
      {data.member_id}
      {data.club_name}
    </UI.Delimit>
  );
};

interface ParticipantFieldValueProps {
  fieldEntry: ParticipantFieldEntry;
  field: Pick<ParticipantField, 'id' | 'type' | 'title' | 'required' | 'config' | 'enabled'>;
}

const ParticipantFieldValue = ({ fieldEntry, field }: ParticipantFieldValueProps) => {
  const { t } = useTranslation();

  const valid = validateFieldEntry({
    fieldEntry: convertToParticipantFieldEntryInput({ ...fieldEntry, field: { id: field.id } }),
    field,
  });

  const addressValue = field.type === ParticipantFieldType.address && fieldEntry.value && (
    JSON.parse(fieldEntry.value) as AddressValue
  );

  const isEmpty = (!fieldEntry.value && fieldEntry.choice_entries.length === 0)
    || (addressValue?.enabled === false);

  return (
    <UI.Div sc={{ muted: field.enabled === false }}>
      <UI.Legend sc={{ fontWeight: 500 }}>
        {field.title}
      </UI.Legend>

      {field.type === ParticipantFieldType.short_text && fieldEntry.value}
      {field.type === ParticipantFieldType.integer && fieldEntry.value}
      {field.type === ParticipantFieldType.iban && fieldEntry.value}
      {field.type === ParticipantFieldType.time && formatTime(fieldEntry.value)}
      {field.type === ParticipantFieldType.knwu_license && fieldEntry.value}
      {field.type === ParticipantFieldType.kwbn_member && fieldEntry.value}
      {field.type === ParticipantFieldType.ntfu_member && fieldEntry.value}
      {field.type === ParticipantFieldType.au_license && formatAtletiekunieLicense(fieldEntry.value)}
      {field.type === ParticipantFieldType.address && !isEmpty && <Address {...addressValue} />}

      {field.type === ParticipantFieldType.option
        && fieldEntry.choice_entries.map(({ choice }) => (
          <UI.Span sc={{ muted: !choice.enabled }} key={choice.id}>
            {choice.title}
          </UI.Span>
        ))}

      {field.type === ParticipantFieldType.selection && (
        <UI.UL sc={{ mb: 0 }}>
          {fieldEntry.choice_entries.map(({ choice }) => (
            <UI.LI sc={{ muted: !choice.enabled }} key={choice.id}>
              {choice.title}
            </UI.LI>
          ))}
        </UI.UL>
      )}

      {isEmpty && (
        <>
          {(valid || !field.enabled) && (
            <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
          )}
          {!valid && field.enabled && (
            <UI.Span sc={{ color: 'error' }}>
              <UI.Icon>
                <AlertTriangle />
              </UI.Icon>
              {' '}
              {t('not_entered')}
            </UI.Span>
          )}
        </>
      )}
    </UI.Div>
  );
};

export default ParticipantFieldValue;
