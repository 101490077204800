import { Event } from '../helpers';
import { EventType, OrderPlacedPayload, dispatch } from '../events';
import { GetOrderSummary } from './__generated__/GetOrderSummary';
import GetOrderSummaryQuery from './GetOrderSummaryQuery';
import useQuery from '../../../api/useQuery';

interface UseOrderAcceptedTrackingProps {
  event: Event;
  activate: boolean;
  orderId: string | null;
  orderToken: string | null;
}

const useOrderAcceptedTracking = (
  { event, activate, orderId, orderToken }: UseOrderAcceptedTrackingProps,
) => {
  const { data } = useQuery<GetOrderSummary>(GetOrderSummaryQuery, {
    skip: !activate || !orderId || !orderToken,
    variables: {
      input: {
        order: { id: orderId },
        token: orderToken,
      },
    },
    onCompleted: ({ orderSummary: summary }) => {
      let coupon = '';

      const getCoupon = (item: any) => {
        if (item.purchase.coupon_code) {
          coupon = item.purchase.coupon_code.value;
        }
      };

      summary.tickets.forEach(getCoupon);
      summary.products.forEach(getCoupon);

      dispatch(EventType.OrderPlaced, {
        event: {
          id: event.id,
          title: event.title,
        },
        order: {
          id: orderId,
          amount: summary.amount,
          vat_amount: summary.vat_amount,
          coupon,
        },
        tickets: summary.tickets.map((item) => ({
          id: item.purchase.ticket.id,
          title: item.purchase.ticket.title,
          amount: item.purchase.amount,
          quantity: item.quantity,
          time_slot: (item.purchase.time_slot
            && `${item.purchase.time_slot.start_date} ${item.purchase.time_slot.start_time}`
          ) || null,
        })),
        products: summary.products.map((item) => ({
          id: item.purchase.product.id,
          title: item.purchase.product.title,
          amount: item.purchase.amount,
          quantity: item.quantity,
          product_variant:
            (item.purchase.product_variant && item.purchase.product_variant.title) || null,
        })),
      } as OrderPlacedPayload);
    },
  });

  return data?.orderSummary;
};

export default useOrderAcceptedTracking;
