import React, { ReactNode, createContext, useContext, useMemo } from 'react';

import { GetCheckoutSummary, GetCheckoutSummary_checkoutSummary } from '../../common/__generated__/GetCheckoutSummary';
import StateContext from './StateContext';
import useBaseCheckoutSummary, { defaultSummary } from '../../common/useCheckoutSummary';

interface CheckoutSummaryContextValue {
  checkoutSummary: GetCheckoutSummary_checkoutSummary;
  loading: boolean;
  data?: GetCheckoutSummary;
}

export const CheckoutSummaryContext = createContext({
  checkoutSummary: defaultSummary,
  loading: false,
} as CheckoutSummaryContextValue);

export const useCheckoutSummary = () => useContext(CheckoutSummaryContext);

interface CheckoutSummaryProviderProps {
  children: ReactNode;
}

const CheckoutSummaryProvider = ({ children }: CheckoutSummaryProviderProps) => {
  const { form, session, setQueueToken } = useContext(StateContext);

  const { checkoutSummary, loading, data } = useBaseCheckoutSummary({
    event: {
      id: form.event.id,
    },
    registrations: form.registrations,
    invitationCode: form.invitation_code,
    coupon: form.coupon,
    vatId: session.invoiceDetails || form.registrations.business.length > 0 ? form.invoice?.vat_id : undefined,
    queueToken: session.queueToken,
    onChange: (data) => {
      const { queue } = data.checkoutSummary;

      if (queue) {
        // Reset the token so that the queue knows it should start polling, in case it was activated before.
        setQueueToken(null);
      }
    },
  });

  const value = useMemo(() => ({ checkoutSummary, loading, data }), [checkoutSummary, loading, data]);

  return (
    <CheckoutSummaryContext.Provider value={value}>
      {children}
    </CheckoutSummaryContext.Provider>
  );
};

export default CheckoutSummaryProvider;
