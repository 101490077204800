import useLocale from './useLocale';

interface IntegerInputProps {
  min?: number;
  max?: number;
  clipValue?: boolean;
}

const useIntegerInput = () => {
  const { formatInteger, parseInteger } = useLocale();

  return ({ min = 0, max = 2 ** 31 - 1, clipValue = true }: IntegerInputProps = {}) => ({
    filter: (value: string) => (
      value.replace(
        new RegExp(`[^0-9${min === null || min < 0 ? '-' : ''}]`, 'g'),
        '',
      )
    ),
    parse: (value: string) => {
      if (value === '') {
        return null;
      }

      const parsedValue = parseInteger(value);

      if (!Number.isNaN(parsedValue)) {
        return clipValue ? Math.min(Math.max(parsedValue, min), max) : parsedValue;
      }

      return null;
    },
    format: (value: string | number) => {
      if (value === null || value === '') {
        return '';
      }

      let parsedValue: string | number = value;
      if (typeof parsedValue === 'string') {
        parsedValue = parseInt(parsedValue, 10);
      }

      parsedValue = clipValue ? Math.min(max, Math.max(min, parsedValue)) : parsedValue;
      return formatInteger(parsedValue, { useGrouping: false });
    },
    validate: (value: number) => {
      if (max !== null && value > max) {
        return false;
      }

      if (min !== null && value < min) {
        return false;
      }

      return true;
    },
  });
};

export default useIntegerInput;
