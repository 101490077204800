import { ArrowUpRight, Check } from 'react-feather';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useRef } from 'react';
import uniqBy from 'lodash/uniqBy';

import { AssignmentType, CreateRegistrationInput, CreateUpgradeInput } from '../../../__generated__/globalTypes';
import {
  CheckoutStep, getKeyedSellables, getRequiredParticipantAttributes, getTicketsForSale, getVisibleProductsForTicket,
} from '../helpers';
import { EmbedContext } from '../../Common/EmbedProvider';
import { NestedTouchState } from '../../../common/useTouchState';
import {
  Update, concatPrefix, containsKeyPrefix, filterPrefix, filterServerErrors,
} from '../../../common/helpers';
import { calculateQuantities, calculateUpgradeAvailability } from '../useQuantities';
import { useEvent } from '../EventProvider';
import { useTracking } from '../../../common/Tracking';
import AssignmentForm from '../../../common/Registrations/AssignmentForm';
import CheckoutContext from '../CheckoutContext';
import ProductSelector from './ProductSelector';
import UI from '../../../common/UI';
import UpgradeInfoForm from './UpgradeInfoForm';

/**
 * Displays the custom fields, extras and assignment form for the registration.
 */
const RegistrationForm = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { event } = useEvent();

  const {
    form,
    editRegistration,
    setTicketUpgrades,
    editTicketUpgrade,
    validators: { [CheckoutStep.Personalisation]: { errors, updateCustomErrors, updateValidating } },
    quantities,
    availability,
    touch,
    touched,
    personalisation,
  } = useContext(CheckoutContext);

  const registrations = personalisation.personalisableRegistrations;
  const registration = personalisation.activeRegistration;

  const tickets = getTicketsForSale(event);
  const ticket = getKeyedSellables(tickets)[registration.purchase.promotion.id];

  const requiredParticipantAttributes = getRequiredParticipantAttributes(registration, event);

  // Calculate assignees: first extract all assignees with an e-mail address or name
  const allCandidates: CreateRegistrationInput[] = [];

  registrations.forEach((registration, index) => {
    if (index !== personalisation.activeIndex
      && registration.participant?.email
      && registration.participant?.first_name
      && registration.participant?.last_name
    ) {
      allCandidates.push({ ...registration });
    }
  });

  /** List of all unique assignees */
  const candidates = uniqBy(allCandidates, (candidate) => JSON.stringify(candidate));

  const assignedStatusRef = useRef<string>();

  const handleChange = (update: Update<CreateRegistrationInput>) => {
    editRegistration((registration) => ({
      ...registration,
      ...update(registration),
    }), personalisation.activeFormIndex, event);

    const status = registration.participant ? 'Assigned' : 'Unassigned';

    if (status !== assignedStatusRef.current) {
      // Track when the ticket is re-assigned, but not when assignee details are changed
      track?.(`Checkout:Registration${status}`, { index: personalisation.activeFormIndex, total: registrations.length });
      assignedStatusRef.current = status;
    }
  };

  const products = getVisibleProductsForTicket(ticket, event.products_for_sale);

  const upgradeQuantities = calculateQuantities({
    registrations: [registration],
    standAloneUpgrades: form.registrations.stand_alone_upgrades,
    ticketCategories: event.ticket_categories,
    products,
  });

  const upgradeAvailability = calculateUpgradeAvailability({
    quantities: upgradeQuantities,
    availability,
    products,
  });

  const skipCount = personalisation.nextIndex - personalisation.activeIndex - 1;

  const errorPrefix = `registrations.create.${personalisation.activeFormIndex}`;
  const requiredUpgradeError = errors[errorPrefix]?.required_upgrades;
  const donationError = errors[errorPrefix]?.indicate_donation;
  const hasUpgradeFieldErrors = containsKeyPrefix(errors, `${errorPrefix}.upgrades`);
  const hasDetailsErrors = containsKeyPrefix(errors, `${errorPrefix}.participant`)
    || containsKeyPrefix(errors, `${errorPrefix}.details`)
    || containsKeyPrefix(errors, `${errorPrefix}.fields`);

  const { scrollToSection, scrollTop } = useContext(EmbedContext);

  const scrollOffset = form.registrations.create.length + form.registrations.business.length > 1 ? 69 : 0;

  const scrollToErrorFields = () => {
    if (hasUpgradeFieldErrors) {
      scrollToSection('UpgradeInfoForm_0', scrollOffset);
    } else if (hasDetailsErrors) {
      scrollToSection('DetailsSection', scrollOffset);
    } else {
      scrollTop();
    }

    Object.keys(errors).forEach((key: string) => key.startsWith(errorPrefix) && touch(key));
  };

  const assignable = ticket.assignment_type !== AssignmentType.afterward;

  const valid = !containsKeyPrefix(filterServerErrors(errors), errorPrefix);

  const handleProductsChange = useCallback(
    (upgrades: CreateUpgradeInput[]) => setTicketUpgrades(
      () => upgrades,
      personalisation.activeFormIndex,
      event,
    ),
    [setTicketUpgrades, personalisation.activeFormIndex, event],
  );

  const handleUpgradeFieldChange = (upgradeIndex: number) => (upgrade: CreateUpgradeInput) => {
    editTicketUpgrade(
      (oldValue) => ({ ...oldValue, fields: upgrade.fields }),
      personalisation.activeFormIndex,
      upgradeIndex,
    );
  };

  const allowAssignLater = ticket.assignment_type !== AssignmentType.required;

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        {assignable && (
          <UI.FormGrid id="DetailsSection">
            <UI.Legend>
              {t('participant_details')}
            </UI.Legend>
            <UI.Div>
              <UI.InputLabel>
                {t('who_is_this_ticket_for')}
              </UI.InputLabel>
              <UI.InputDescription>
                {!allowAssignLater && t('assign_to_description')}
                {allowAssignLater && t('assign_later_description')}
              </UI.InputDescription>
            </UI.Div>
            <AssignmentForm
              registration={registration}
              onChange={handleChange}
              onBlur={(field) => touch(`registrations.create.${personalisation.activeFormIndex}.${field}`)}
              touched={touched(`registrations.create.${personalisation.activeFormIndex}`)}
              registrationAttributes={requiredParticipantAttributes}
              candidates={candidates}
              allowAssignLater={allowAssignLater}
              errors={filterPrefix(errors, `registrations.create.${personalisation.activeFormIndex}`)}
              enabledParticipantFields={event.enabled_participant_fields}
              emailDescription={t('email_will_receive_confirmation')}
              updateCustomErrors={(errors, prefix) => updateCustomErrors(errors, concatPrefix(`registrations.create.${personalisation.activeFormIndex}`, prefix))}
              updateValidating={(validating, prefix) => updateValidating(validating, concatPrefix(`registrations.create.${personalisation.activeFormIndex}`, prefix))}
            />
          </UI.FormGrid>
        )}
        {products.length > 0 && (
          <>
            <UI.HR />
            <UI.Legend>
              {t('ticket_upgrades')}
            </UI.Legend>
            <ProductSelector
              event={event}
              products={products}
              upgrades={registration.upgrades}
              onChange={handleProductsChange}
              quantities={upgradeQuantities}
              globalQuantities={quantities}
              availability={upgradeAvailability}
            />
          </>
        )}
        {registration.upgrades.map((upgrade, upgradeIndex) => upgrade.fields.length > 0 && (
          <UpgradeInfoForm
            upgrade={upgrade}
            fields={event.enabled_participant_fields}
            product={products.filter((product) => (
              product.promotions_for_sale.filter(({ id }) => id === upgrade.purchase.promotion.id).length > 0
            ))[0]}
            onChange={handleUpgradeFieldChange(upgradeIndex)}
            errors={filterPrefix(
              errors, `registrations.create.${personalisation.activeFormIndex}.upgrades.${upgradeIndex}`,
            )}
            onBlur={(key: string) => (
              touch(`registrations.create.${personalisation.activeFormIndex}.upgrades.${upgradeIndex}.${key}`))}
            touched={touched(
              `registrations.create.${personalisation.activeFormIndex}.upgrades.${upgradeIndex}`,
            ) as NestedTouchState}
            id={`UpgradeInfoForm_${upgradeIndex}`}
            key={upgradeIndex}
          />
        ))}
        {valid && skipCount > 0 && (
          <UI.FadeIn>
            <UI.Info icon={<Check />}>
              {t('n_tickets_skipped', { count: skipCount })}
            </UI.Info>
          </UI.FadeIn>
        )}
        {!valid && (
          <UI.FadeIn>
            <UI.Warning>
              <UI.FormGrid sc={{ gutter: 0.25 }}>
                {requiredUpgradeError && (
                  <UI.A
                    onClick={() => scrollToSection(`ProductSelect_${requiredUpgradeError.products[0].id}`, scrollOffset)}
                    sc={{ secondary: true }}
                    style={{ fontWeight: 500 }}
                    role="button"
                  >
                    {t('select_required_products')}
                    {' '}
                    <UI.Icon>
                      <ArrowUpRight />
                    </UI.Icon>
                  </UI.A>
                )}
                {donationError && (
                  <UI.A
                    onClick={() => scrollToSection(`ProductSelect_${donationError.products[0].id}`, scrollOffset)}
                    sc={{ secondary: true }}
                    style={{ fontWeight: 500 }}
                    role="button"
                  >
                    {t('indicate_donation')}
                    {' '}
                    <UI.Icon>
                      <ArrowUpRight />
                    </UI.Icon>
                  </UI.A>
                )}
                {(hasDetailsErrors || hasUpgradeFieldErrors) && (
                  <UI.A
                    onClick={() => scrollToErrorFields()}
                    sc={{ secondary: true }}
                    style={{ fontWeight: 500 }}
                    role="button"
                  >
                    {t('fill_required_fields')}
                    {' '}
                    <UI.Icon>
                      <ArrowUpRight />
                    </UI.Icon>
                  </UI.A>
                )}
              </UI.FormGrid>
            </UI.Warning>
          </UI.FadeIn>
        )}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default RegistrationForm;
