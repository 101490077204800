import React from 'react';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import {
  CreateRegistrationInput, CreateUpgradeInput, ParticipantFieldEntryInput,
} from '../../../__generated__/globalTypes';
import { ErrorBag, filterPrefix } from '../../../common/helpers';
import { NestedTouchState, TouchFunction } from '../../../common/useTouchState';
import { ParticipantField, Product } from '../helpers';
import ParticipantFieldInput from '../../../common/ParticipantFields/ParticipantFieldInput';
import UI from '../../../common/UI';

export interface UpgradeInfoFormProps {
  upgrade: CreateUpgradeInput;
  fields: ParticipantField[];
  product: Product;
  onChange: (upgrade: CreateUpgradeInput) => void;
  onBlur?: TouchFunction;
  errors?: ErrorBag;
  touched?: NestedTouchState;
  id?: string;
  registration?: CreateRegistrationInput;
}

/**
 * Displays inputs for custom fields that are linked to the product purchases of a registration.
 */
const UpgradeInfoForm = ({
  upgrade, fields, product, onChange, errors = {}, onBlur, touched, id, registration,
}: UpgradeInfoFormProps) => {
  const keyedFields = keyBy(fields, 'id');
  const promotion = product.promotions_for_sale.filter(({ id }) => id === upgrade.purchase.promotion.id)[0];
  const productVariant = product.active_product_variants.filter(({ id }) => id === upgrade.product_variant.id)[0];

  const handleChange = (fieldEntry: ParticipantFieldEntryInput) => {
    onChange({
      ...upgrade,
      fields: upgrade.fields.map(
        (value) => (value.participant_field.id === fieldEntry.participant_field.id ? fieldEntry : value),
      ),
    });
  };

  return (
    <UI.FadeIn id={id}>
      <UI.FormGrid>
        <UI.HR />
        <UI.Legend>
          <UI.Delimit>
            {product.title}
            {promotion.title}
            {productVariant?.title}
          </UI.Delimit>
        </UI.Legend>
        {upgrade.fields.map((value, index) => keyedFields[value.participant_field.id] && (
          <ParticipantFieldInput
            value={value}
            onChange={(newValue) => handleChange({ ...value, ...newValue })}
            onBlur={() => onBlur?.(`fields.${index}`)}
            touched={!!get(touched, `fields.${index}`)}
            field={keyedFields[value.participant_field.id]}
            errors={filterPrefix(errors, `fields.${index}`)}
            registration={registration}
            key={value.participant_field.id}
          />
        ))}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default UpgradeInfoForm;
