import React, { Suspense, lazy } from 'react';

import { GoogleLocationInputProps } from './helpers';
import { InputFallback } from './Input';

const LazyGoogleLocationInput = lazy(() => import('./GoogleLocationInput'));

const GoogleLocationInput = (props: GoogleLocationInputProps) => (
  <Suspense fallback={<InputFallback />}>
    <LazyGoogleLocationInput {...props} />
  </Suspense>
);

export default GoogleLocationInput;
