import gql from 'graphql-tag';

export const ParticipantFieldEntryFieldsFragment = gql`
  fragment ParticipantFieldEntryFields on ParticipantFieldEntry {
    id
    field {
      id
    }
    value
    choice_entries {
      id
      # We must select the full choice field for every choice entry because we can't select
      # disabled choices in any other way
      choice {
        id
        title
        position
        enabled
      }
    }
  }
`;

export const ParticipantFieldFieldsFragment = gql`
  fragment ParticipantFieldFields on ParticipantField {
    id
    title
    description
    type
    scope
    required_promotions {
      id
    }
    enabled_choices {
      id
      title
      position
      enabled
    }
    required
    config
    has_external_validation
  }
`;

export const RegistrationFieldsFragment = gql`
  fragment RegistrationFields on Registration {
    id
    qr_url
    qr_code
    sequence
    exchange_code
    exchange_amount
    locked
    complete
    ticket_complete
    upgrades_complete
    ticket_number
    start_time
    corral_name
    has_upgrades_available
    invited_email
    assigned
    required_attributes
    editable_attributes
    date_of_birth
    gender
    nationality
    street
    house_number
    zip_code
    city
    extra_address_line
    country
    phone
    emergency_phone
    ticket_url
    claim_url
    claim_token
    is_reassignable
    is_unassignable
    participant {
      id
      email
      full_name
      first_name
      last_name
    }
    owner {
      id
      email
      full_name
    }
    ticket {
      id
      title
      business
      allow_individuals
      allow_create_team
      allow_join_team
      restrictions {
        id
        gender
        min_age
        max_age
      }
      units
      upcoming_time_slots {
        id
        start_date
        start_time
        title
        multi_date
        is_sold_out
      }
    }
    time_slot {
      id
      start_date
      start_time
      title
      multi_date
      is_sold_out
    }
    charity {
      id
      title
      logo_url
      description
    }
    default_charity {
      id
      title
      logo_url
      description
    }
    promotion {
      id
      title
    }
    team {
      id
      title
    }
    participant_fields {
      ...ParticipantFieldFields
    }
    participant_field_entries {
      ...ParticipantFieldEntryFields
    }
    upgrades {
      ...UpgradeFields
    }
    resale {
      id
      resellable
    }
    charity {
      id
      title
    }
    fundraising_campaign {
      id
      url
    }
  }
`;

export const UpgradeFieldsFragment = gql`
  fragment UpgradeFields on Upgrade {
    id
    complete
    resale_amount
    product {
      id
      title
      is_ticket_fee
      donation
      active_product_variants {
        id
        title
        is_sold_out
        position
      }
    }
    promotion {
      id
      title
    }
    product_variant {
      id
      title
      is_sold_out
      position
    }
    participant_fields {
      ...ParticipantFieldFields
    }
    participant_field_entries {
      ...ParticipantFieldEntryFields
    }
  }
`;

export const TeamFieldsFragment = gql`
  fragment TeamFields on Team {
    id
    title
    members {
      id
      sequence
      ticket {
        id
        title
      }
      full_name
    }
  }
`;

export default gql`
  query GetRegistrationDetails($participant: ID!, $event: ID!, $token: String, $registration: ID!) {
    registrationSummary(participant: $participant, event: $event, token: $token) {
      id
      registration(id: $registration) {
        ...RegistrationFields
      }
    }
  }

  ${RegistrationFieldsFragment}
  ${ParticipantFieldFieldsFragment}
  ${ParticipantFieldEntryFieldsFragment}
  ${UpgradeFieldsFragment}
`;
