import styled, { css } from 'styled-components';

import { Div } from './Generic';

const Hero = styled(Div)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    box-shadow: inset 0 -1px 0 ${theme.colors.gray[200]};
  `}
`;

export default Hero;
