import React, { ImgHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Div } from './Generic';
import SC from './SC';

interface ImageProps {
  ratio?: number;
}

export const Image = styled(Div)<SC<ImageProps>>`
  ${({ sc: { ratio } = {}, theme }) => css`
    img {
      border-radius: ${theme.borderRadiuses.md}px;
      display: block;
      width: 100%;
    }

    ${ratio && css`
      position: relative;
      padding-bottom: ${100 / ratio}%;

      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        height: 100%;
      }
    `}
  `}
`;

interface ResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  ignoreWebp?: boolean;
}

/**
 * Renders WebP, JPG, and soon AVIF-compliant images.
 * Note: if the WebP does not exist, set `ignoreWebp` to true.
 */
export const ResponsiveImage = ({ src, ignoreWebp = false, ...props }: ResponsiveImageProps) => {
  const baseImage = src.replace(/\.[^/.]+$/, '');

  return (
    <picture>
      {!ignoreWebp && (
        <source srcSet={`${baseImage}.webp`} type="image/webp" />
      )}

      <img
        alt=""
        src={src}
        loading="lazy"
        {...props}
      />
    </picture>
  );
};

export default undefined;
