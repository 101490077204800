import { useCallback, useEffect, useState } from 'react';
import SessionStorage from '../../common/SessionStorage';

interface UseThrottlerProps {
  key?: string;
  interval?: number;
}

const DEFAULT_WAIT_INTERVAL = 30_000;

const usePersistentThrottler = ({ key, interval }: UseThrottlerProps = {}) => {
  const recentlyThrottled = wasRecentlyThrottled(key, interval);
  const storageKey = getKey(key);
  const [throttled, setThrottledState] = useState(recentlyThrottled);

  const setThrottled = useCallback((throttled: boolean) => {
    setThrottledState(throttled);

    if (throttled) {
      SessionStorage.setItem(storageKey, JSON.stringify((new Date()).getTime()));
    } else {
      SessionStorage.removeItem(storageKey);
    }
  }, [storageKey]);

  const waitTime = getWaitTime(key, interval);

  // Automatically unblock after waitTime ms.
  useEffect(() => {
    if (waitTime > 0) {
      const timer = window.setTimeout(() => {
        setThrottled(false);
      }, waitTime);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [waitTime, setThrottled]);

  return [throttled, setThrottled] as const;
};

/**
 * The number of ms that the user needs to wait before being unblocked.
 */
const getWaitTime = (key?: string, interval = DEFAULT_WAIT_INTERVAL) => {
  const throttledAt = JSON.parse(SessionStorage.getItem(getKey(key)));
  return typeof throttledAt === 'number' ? throttledAt + interval - (new Date()).getTime() : 0;
};

/**
 * Indicates whether the user was recently throttled.
 */
const wasRecentlyThrottled = (key?: string, interval = DEFAULT_WAIT_INTERVAL) => getWaitTime(key, interval) > 0;

const getKey = (key: string = 'default') => `${key}_throttled_at`;

export default usePersistentThrottler;
