import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useRef } from 'react';

import CountryOptions from '../../Common/CountryOptions';
import ParticipantAttributeInputProps from '../ParticipantAttributeInputProps';
import UI from '../../UI';
import useAddressCompletion, { Address as AddressType } from '../../useAddressCompletion';

export interface AddressInputValue {
  street?: string | null;
  house_number?: string | null;
  zip_code?: string | null;
  city?: string | null;
  extra_address_line?: string | null;
  country?: string | null;
}

const AddressInput = ({
  value, onChange, onBlur, touched, required = true, errors, idPrefix = 'Participant',
}: ParticipantAttributeInputProps<AddressInputValue>) => {
  const { t } = useTranslation('common');

  const handleChange = (field: keyof AddressInputValue) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({ [field]: event.target.value });
    }
  );

  // Auto-fill street and city based on zip code and house number.
  const streetRef = useRef<HTMLInputElement>();
  const cityRef = useRef<HTMLInputElement>();

  useAddressCompletion({
    country: value.country,
    zipCode: value.zip_code,
    houseNumber: value.house_number,
    onCompleted: (address: AddressType) => {
      const newValue: Partial<AddressInputValue> = {};

      if (address.street) {
        newValue.street = address.street;

        if (streetRef.current) {
          streetRef.current.value = address.street;
        }
      }

      if (address.city) {
        newValue.city = address.city;

        if (cityRef.current) {
          cityRef.current.value = address.city;
        }
      }

      if (newValue.street || newValue.city) {
        onChange(newValue);
      }
    },
  });

  return (
    <UI.FormGrid>
      <UI.InputGroup sc={{ valid: !errors?.country, touched: touched?.country }}>
        <UI.InputLabel htmlFor={`${idPrefix}Country`}>
          {t('participant_attributes.country')}
          {required && (
          <>
            {' '}
            <UI.RequiredMark />
          </>
          )}
        </UI.InputLabel>
        <UI.Select
          value={value.country || ''}
          onChange={(event) => onChange({ country: event.target.value || null })}
          onBlur={() => onBlur?.('country')}
          id={`${idPrefix}Country`}
        >
          <option value=""> </option>
          <CountryOptions />
        </UI.Select>
        <UI.ErrorMessages
          attribute={t('participant_attributes.country')}
          errors={errors?.country}
        />
      </UI.InputGroup>
      {value.country === 'NL' ? (
        <>
          <UI.FormGrid sc={{ columns: '1fr 1fr' }}>
            <UI.InputGroup
              sc={{ valid: !errors?.zip_code, touched: touched?.zip_code }}
              key="zip_code"
            >
              <UI.InputLabel htmlFor={`${idPrefix}ZipCode`}>
                {t('participant_attributes.zip_code')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.zip_code}
                onChange={handleChange('zip_code')}
                onBlur={() => onBlur?.('zip_code')}
                id={`${idPrefix}ZipCode`}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.zip_code')}
                errors={errors?.zip_code}
              />
            </UI.InputGroup>
            <UI.InputGroup
              sc={{ valid: !errors?.house_number, touched: touched?.house_number }}
              key="house_number"
            >
              <UI.InputLabel htmlFor={`${idPrefix}HouseNumber`}>
                {t('participant_attributes.house_number')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.house_number}
                onChange={handleChange('house_number')}
                onBlur={() => onBlur?.('house_number')}
                id={`${idPrefix}HouseNumber`}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.house_number')}
                errors={errors?.house_number}
              />
            </UI.InputGroup>
          </UI.FormGrid>

          <UI.FormGrid sc={{ columns: '1fr 1fr' }}>
            <UI.InputGroup
              sc={{ valid: !errors?.street, touched: touched?.street }}
              key="street"
            >
              <UI.InputLabel htmlFor={`${idPrefix}Street`}>
                {t('participant_attributes.street')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.street}
                onChange={handleChange('street')}
                onBlur={() => onBlur?.('street')}
                id={`${idPrefix}Street`}
                ref={streetRef}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.street')}
                errors={errors?.street}
              />
            </UI.InputGroup>
            <UI.InputGroup
              sc={{ valid: !errors?.city, touched: touched?.city }}
              key="city"
            >
              <UI.InputLabel htmlFor={`${idPrefix}City`}>
                {t('participant_attributes.city')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.city}
                onChange={handleChange('city')}
                onBlur={() => onBlur?.('city')}
                id={`${idPrefix}City`}
                ref={cityRef}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.city')}
                errors={errors?.city}
              />
            </UI.InputGroup>
          </UI.FormGrid>
        </>
      ) : (
        <>
          <UI.FormGrid sc={{ columns: '3fr 2fr' }}>
            <UI.InputGroup
              sc={{ valid: !errors?.street, touched: touched?.street }}
              key="street"
            >
              <UI.InputLabel htmlFor={`${idPrefix}Street`}>
                {t('participant_attributes.street')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.street}
                onChange={handleChange('street')}
                onBlur={() => onBlur?.('street')}
                id={`${idPrefix}Street`}
                ref={streetRef}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.street')}
                errors={errors?.street}
              />
            </UI.InputGroup>
            <UI.InputGroup
              sc={{ valid: !errors?.house_number, touched: touched?.house_number }}
              key="house_number"
            >
              <UI.InputLabel htmlFor={`${idPrefix}HouseNumber`}>
                {t('participant_attributes.house_number')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.house_number}
                onChange={handleChange('house_number')}
                onBlur={() => onBlur?.('house_number')}
                id={`${idPrefix}HouseNumber`}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.house_number')}
                errors={errors?.house_number}
              />
            </UI.InputGroup>
          </UI.FormGrid>

          <UI.InputGroup
            sc={{ valid: !errors?.extra_address_line, touched: touched?.extra_address_line }}
          >
            <UI.InputLabel htmlFor={`${idPrefix}ExtraAddressLine`}>
              {t('participant_attributes.extra_address_line')}
            </UI.InputLabel>
            <UI.DebouncedInput
              value={value.extra_address_line}
              onChange={handleChange('extra_address_line')}
              onBlur={() => onBlur?.('extra_address_line')}
              placeholder={t('participant_attributes.extra_address_line_placeholder')}
              id={`${idPrefix}ExtraAddressLine`}
            />
            <UI.ErrorMessages
              attribute={t('participant_attributes.extra_address_line')}
              errors={errors?.extra_address_line}
            />
          </UI.InputGroup>

          <UI.FormGrid sc={{ columns: '2fr 3fr' }}>
            <UI.InputGroup
              sc={{ valid: !errors?.zip_code, touched: touched?.zip_code }}
              key="zip_code"
            >
              <UI.InputLabel htmlFor={`${idPrefix}ZipCode`}>
                {t('participant_attributes.zip_code')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.zip_code}
                onChange={handleChange('zip_code')}
                onBlur={() => onBlur?.('zip_code')}
                id={`${idPrefix}ZipCode`}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.zip_code')}
                errors={errors?.zip_code}
              />
            </UI.InputGroup>
            <UI.InputGroup
              sc={{ valid: !errors?.city, touched: touched?.city }}
              key="city"
            >
              <UI.InputLabel htmlFor={`${idPrefix}City`}>
                {t('participant_attributes.city')}
                {required && (
                  <>
                    {' '}
                    <UI.RequiredMark />
                  </>
                )}
              </UI.InputLabel>
              <UI.DebouncedInput
                value={value.city}
                onChange={handleChange('city')}
                onBlur={() => onBlur?.('city')}
                id={`${idPrefix}City`}
                ref={cityRef}
              />
              <UI.ErrorMessages
                attribute={t('participant_attributes.city')}
                errors={errors?.city}
              />
            </UI.InputGroup>
          </UI.FormGrid>
        </>
      )}
    </UI.FormGrid>
  );
};

export default AddressInput;
