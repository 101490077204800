import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ParticipantAttributes, RegistrationDetailsInput } from '../../__generated__/globalTypes';
import Address, { containsAddress } from '../../backend/Common/Address';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import UI from '../UI';
import useLocale from '../useLocale';

interface RegistrationAttributeProps {
  registration: RegistrationDetailsInput;
  attribute: ParticipantAttributes;
  showEmptyWarning?: boolean;
}

const RegistrationAttribute = ({ registration, attribute, showEmptyWarning = false }: RegistrationAttributeProps) => {
  const { t } = useTranslation('common');
  const { formatDate, parseDate } = useLocale();

  const emptyValue = showEmptyWarning ? (
    <UI.Span sc={{ color: 'error' }}>
      <UI.Icon>
        <AlertTriangle />
      </UI.Icon>
      {' '}
      {t('participant_attributes.not_entered')}
    </UI.Span>
  ) : (
    <UI.Span sc={{ muted: true }}> &ndash; </UI.Span>
  );

  const hasAddress = containsAddress(registration);

  if (attribute === ParticipantAttributes.date_of_birth) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.date_of_birth')}</UI.Legend>
        {registration.date_of_birth && formatDate(
          parseDate(registration.date_of_birth, { format: 'internal_date' }),
          { format: 'date_of_birth' },
        )}
        {!registration.date_of_birth && emptyValue}
      </UI.Div>
    );
  }

  if (attribute === ParticipantAttributes.gender) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.gender')}</UI.Legend>
        {registration.gender && t(`genders.${registration.gender}`)}
        {!registration.gender && emptyValue}
      </UI.Div>
    );
  }

  if (attribute === ParticipantAttributes.nationality) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.nationality')}</UI.Legend>
        {registration.nationality && t(`countries.${registration.nationality}`)}
        {!registration.nationality && emptyValue}
      </UI.Div>
    );
  }

  if (attribute === ParticipantAttributes.address) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.address')}</UI.Legend>
        {hasAddress && <Address {...registration} />}
        {!hasAddress && emptyValue}
      </UI.Div>
    );
  }

  if (attribute === ParticipantAttributes.phone) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.phone')}</UI.Legend>
        {registration.phone && <PhoneNumber phoneNumber={registration.phone} />}
        {!registration.phone && emptyValue}
      </UI.Div>
    );
  }

  if (attribute === ParticipantAttributes.emergency_phone) {
    return (
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500 }}>{t('participant_attributes.emergency_phone')}</UI.Legend>
        {registration.emergency_phone && <PhoneNumber phoneNumber={registration.emergency_phone} />}
        {!registration.emergency_phone && emptyValue}
      </UI.Div>
    );
  }

  return <></>;
};

export default RegistrationAttribute;
