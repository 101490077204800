import styled, { css } from 'styled-components';

import { ArrayResponsiveProp, ResponsiveProp, SimpleProp } from '../../theme/helpers';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

export const TD = styled.td<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    padding: .2rem 0;
    vertical-align: top;

    ${theme.useMixins(props)}
  `}
`;

interface TRProps {
  borderTop?: number;
}

export const TR = styled.tr<SC<UIProps & TRProps>>`
  ${({ sc: { borderTop, ...props } = {}, theme }) => css`
    ${borderTop && css`
      > td {
        border-top: ${borderTop}px solid rgba(0, 0, 0, .05);
      }
    `}

    ${theme.useMixins(props)}
  `}
`;

export const THead = styled.thead<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${TH} {
      padding: .4rem 0;
      font-size: ${theme.fontSizes.body}rem;
      color: ${theme.colors.gray[500]};
      font-weight: 500;
      text-align: left;
    }

    ${theme.useMixins(props)}
  `}
`;

export const TH = styled.th<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

export const TBody = styled.tbody<SC<UIProps>>`
  ${({ sc: props = {}, theme }) => css`
    ${theme.useMixins(props)}
  `}
`;

export interface TableProps {
  spacingV?: SimpleProp<number> | ResponsiveProp<number> | ArrayResponsiveProp<number>;
  borderedRows?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderWidth?: number;
  bordered?: boolean;
}

export const Table = styled.table<SC<TableProps & UIProps>>`
  ${({ sc: {
    spacingV = 0.5, borderedRows = false, borderTop = false, borderBottom = false, borderWidth = 1, bordered, ...props
  } = {}, theme }) => css`
    width: 100%;

    ${TBody}${!borderBottom && ':not(:last-child)'} {
      ${TR}:last-child ${TD} {
        ${!!spacingV && theme.responsiveProp(spacingV, (spacing: number) => css`
          padding-bottom: ${(spacing / 2) * theme.gutter}px;
        `)}
      }
    }

    ${borderedRows && css`
      ${TR} + ${TR} {
        border-top: ${borderWidth}px solid rgba(0, 0, 0, .05);
        padding-top: ${theme.gutter / 2}px;
      }
    `}

    ${!bordered && css`
      ${TBody}${!borderTop && ':not(:first-child)'} {
        border-top: ${borderWidth}px solid rgba(0, 0, 0, .05);

        ${TR}:first-child ${TD} {
          ${!!spacingV && theme.responsiveProp(spacingV, (spacing: number) => css`
            padding-top: ${(spacing / 2) * theme.gutter}px;
          `)}
        }
      }
    `}

    ${borderBottom && css`
      ${TBody}:last-child {
        border-bottom: ${borderWidth}px solid rgba(0, 0, 0, .05);

        ${TR}:first-child ${TD} {
          ${!!spacingV && theme.responsiveProp(spacingV, (spacing: number) => css`
            padding-bottom: ${(spacing / 2) * theme.gutter}px;
          `)}
        }
      }
    `}

    ${!!spacingV && theme.responsiveProp(spacingV, (spacing: number) => css`
      ${TD} {
        padding-top: ${(spacing / 4) * theme.gutter}px;
        padding-bottom: ${(spacing / 4) * theme.gutter}px;
      }
    `)}

    ${bordered && css`
      ${TD}, ${TH} {
        border: ${borderWidth}px solid rgba(0, 0, 0, .075);
        padding: ${theme.gutter / 4}px;
      }
    `}

    ${theme.useMixins(props)}
  `}
`;
