import { useEffect } from 'react';

import { EventType } from '../Checkout/events';

const useEventHandler = (eventType: EventType, handler: (payload: object) => void) => {
  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const { data } = message;

      if (typeof data === 'object') {
        const { payload, type } = data;

        if (type === eventType) {
          handler(payload);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [eventType, handler]);
};

export default useEventHandler;
