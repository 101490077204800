import styled, { css } from 'styled-components';

import { Div } from './Generic';

const ScrollablePanel = styled(Div)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray[250]};
    padding: ${theme.gutter / 2}px;
    border-radius: ${theme.borderRadiuses.md}px;
    overflow-y: auto;
    height: 200px;
  `}
`;

export default ScrollablePanel;
