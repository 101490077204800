import React from 'react';

/* eslint-disable max-len */
const Communication = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M22.25.5h-16A1.76,1.76,0,0,0,4.5,2.25V8.9H1.75A1.76,1.76,0,0,0,0,10.65v12.6a.75.75,0,0,0,.45.69.77.77,0,0,0,.3.06.78.78,0,0,0,.5-.19L4.36,21h9a1.76,1.76,0,0,0,1.75-1.75l0-3.25h3.34l4.29,3.81a.78.78,0,0,0,.5.19.7.7,0,0,0,.31-.07.75.75,0,0,0,.44-.68v-17A1.76,1.76,0,0,0,22.25.5ZM13.6,19.25a.25.25,0,0,1-.25.25H4.08a.78.78,0,0,0-.51.19L1.5,21.56V10.65a.25.25,0,0,1,.25-.25H4.5v3.85A1.76,1.76,0,0,0,6.25,16h7.37Zm8.9-1.67-3.25-2.89a.78.78,0,0,0-.5-.19H6.25A.25.25,0,0,1,6,14.25v-12A.25.25,0,0,1,6.25,2h16a.25.25,0,0,1,.25.25Z" fill="currentColor" />
    <g opacity="0.5">
      <path d="M19.25,6h-10a.75.75,0,0,1,0-1.5h10a.75.75,0,0,1,0,1.5Z" fill="currentColor" />
      <path d="M17.25,9h-8a.75.75,0,0,1,0-1.5h8a.75.75,0,0,1,0,1.5Z" fill="currentColor" />
      <path d="M19.25,12h-10a.75.75,0,0,1,0-1.5h10a.75.75,0,0,1,0,1.5Z" fill="currentColor" />
    </g>
  </svg>
);

export default Communication;
