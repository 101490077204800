import { DocumentNode } from 'graphql';

import {
  LazyQueryHookOptions, OperationVariables, QueryTuple, useLazyQuery as useLazyApolloQuery,
} from '@apollo/client';

function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode, options?: LazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> {
  options = options || {};

  options.fetchPolicy = options.fetchPolicy || 'network-only';

  return useLazyApolloQuery<TData, TVariables>(query, options);
}

export default useLazyQuery;
