import { HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { assetUrl } from '../helpers';
import UI from '../UI';
import useLocale from '../useLocale';

interface PlatformFeeLineProps {
  fee: number;
}

const PlatformFeeLine = ({ fee }: PlatformFeeLineProps) => {
  const { t } = useTranslation('common');
  const [showFeeDescription, setShowFeeDescription] = useState(false);
  const { formatCurrency } = useLocale();

  return (
    <UI.GridContainer sc={{ gutter: 0.5 }}>
      <UI.Div>
        <UI.GridContainer sc={{ columns: '2fr 1fr', small: true }}>
          <UI.Div>
            <UI.A
              onClick={() => setShowFeeDescription((show) => !show)}
              role="button"
              sc={{ secondary: true }}
            >
              {t('platform_fee_line.platform_fee')}
              {' '}
              <UI.Icon sc={{ muted: true }}>
                <HelpCircle />
              </UI.Icon>
            </UI.A>
          </UI.Div>
          <UI.Div sc={{ textAlign: 'right' }}>
            {formatCurrency(fee)}
          </UI.Div>
        </UI.GridContainer>
      </UI.Div>
      {showFeeDescription && (
        <UI.FadeIn>
          <UI.Info sc={{ type: 'atleta' }}>
            <UI.GridContainer sc={{ columns: '70px 1fr', gutter: 0.5 }}>
              <UI.Div>
                <img
                  src={assetUrl('images/platform-fee.svg')}
                  alt={t('platform_fee_line.platform_fee_title')}
                  width="100%"
                />
              </UI.Div>
              <UI.Div>
                <UI.Strong sc={{ block: true }}>
                  {t('platform_fee_line.platform_fee_title')}
                </UI.Strong>
                {t('platform_fee_line.platform_fee_explanation')}
              </UI.Div>
            </UI.GridContainer>
          </UI.Info>
        </UI.FadeIn>
      )}
    </UI.GridContainer>
  );
};

export default PlatformFeeLine;
