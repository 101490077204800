import styled, { css } from 'styled-components';

import { GridContainer } from './Container';
import { GridProps } from '../../theme/mixins';
import { H4, Paragraph } from './Text';
import InputGroup, { InputDescription, InputLabel } from './InputGroup';
import SC from './SC';

/**
 * Control form spacing.
 * - Can and should be nested if necessary
 */
const FormGrid = styled(GridContainer)<SC<GridProps>>`
 ${({ theme }) => css`
   ${InputGroup} {
     margin-bottom: 0;
   }

   > ${Paragraph} {
     margin-bottom: 0;
   }

   /* Put spacing between title and description */
   ${H4} + ${InputDescription} {
     margin-top: ${theme.gutter / 4}px;
     color: ${theme.colors.gray[500]};
   }

   /* Put spacing between label and input element, but not between label and description */
   ${InputLabel} + *:not(${InputDescription}) {
     margin-top: ${theme.gutter / 4}px;
   }

   /* Put spacing between description and input element */
   ${InputDescription} + * {
     margin-top: ${theme.gutter / 4}px;
   }
 `}
`;

export default FormGrid;
