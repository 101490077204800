import gql from 'graphql-tag';

export default gql`
  mutation CompleteCheckout($input: CompleteCheckoutInput!) {
    completeCheckout(input: $input) {
      status
      redirect_url
    }
  }
`;
