import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { ParticipantAttributes } from '../../__generated__/globalTypes';
import { zipCodeRegex } from '../helpers';

export const getParticipantAttributesSchema = (
  participantAttributes: ParticipantAttributes[], required: boolean = true,
) => yup.object(
  participantAttributes.reduce((schema, name) => {
    if (name === ParticipantAttributes.date_of_birth) {
      schema.date_of_birth = (yup.string().ensure() as any).after('1900-01-01').past()
        .test('required', '', (value: any) => !required || !!value);
    } else if (name === ParticipantAttributes.gender) {
      schema.gender = yup.string().ensure()
        .test('required', '', (value) => !required || !!value);
    } else if (name === ParticipantAttributes.nationality) {
      schema.nationality = yup.string().ensure()
        .test('required', '', (value) => !required || !!value);
    } else if (name === ParticipantAttributes.address) {
      schema.street = yup.string().ensure()
        .test('required', '', (value) => !required || !!value);
      schema.house_number = yup.string().ensure()
        .test('required', '', (value) => !required || !!value)
        .when(['country'], {
          is: (country: string) => country === 'NL',
          then: yup.string().ensure().test('invalid', (value) => (
            // Dutch house numbers should contain at least one digit
            !value || !!value.match(/\d/)
          )),
        });
      schema.zip_code = yup.string().ensure()
        .test('required', '', (value) => !required || !!value)
        .when(['country'], {
          is: (country: string) => country === 'NL',
          then: yup.string().ensure().test('zip_code', (value) => (
            // Remove non-alphanumeric characters and check if it matches 1111AA
            !value || !!value.replace(/[^0-9a-z]/gi, '').match(zipCodeRegex.NL)
          )),
        });
      schema.city = yup.string().ensure()
        .test('required', '', (value) => !required || !!value);
      schema.country = yup.string().ensure()
        .test('required', '', (value) => !required || !!value);
    } else if (name === ParticipantAttributes.phone) {
      schema.phone = yup.string().ensure()
        .test('required', '', (value) => !required || !!value)
        .test('phone', '', (value) => !value || isValidPhoneNumber(value));
    } else if (name === ParticipantAttributes.emergency_phone) {
      schema.emergency_phone = yup.string().ensure()
        .test('required', '', (value) => !required || !!value)
        .test('phone', '', (value) => !value || isValidPhoneNumber(value));
    }

    return schema;
  }, {} as any),
);

export default undefined;
