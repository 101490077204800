import styled, { css } from 'styled-components';

import { Size } from '../../theme/mixins';
import SC from './SC';

interface LogoContainerProps {
  size?: Size;
}

const LogoContainer = styled.a<SC<LogoContainerProps>>`
  ${({ sc: { size = 'md' } = {}, theme }) => css`
      display: inline-block;

      img {
        display: block;
        max-width: 100%;
      }

      ${theme.useSizes(size, 'max-width', ['100px', '150px', '200px', '250px'])}
  `}
`;

export default LogoContainer;
