import React, { useRef } from 'react';
import sortBy from 'lodash/sortBy';

import ParticipantFieldInputProps, { ParticipantFieldChoice } from '../ParticipantFieldInputProps';
import UI from '../../UI';

const SelectionInput = ({
  value, onChange, onBlur, field, disabledChoices = [], touched, errors, validating,
}: ParticipantFieldInputProps) => {
  const idRef = useRef(Math.round(Math.random() * 1000000));
  const choices = sortBy(field.choices || [...field.enabled_choices, ...disabledChoices], 'position');
  const checked = value.choices.map(({ id }) => id);

  const toggle = (choice: ParticipantFieldChoice) => {
    if (checked.includes(choice.id)) {
      onChange({ choices: value.choices.filter(({ id }) => id !== choice.id) });
    } else {
      onChange({ choices: [...value.choices, { id: choice.id }] });
    }

    onBlur?.();
  };

  return (
    <UI.InputGroup sc={{ valid: !errors?.choices, touched }}>
      <UI.InputLabel htmlFor={`Field_${idRef.current}_0`}>
        {field.title}
        {' '}
        {field.required && <UI.RequiredMark />}
        {validating && (
          <>
            {' '}
            <UI.Icon>
              <UI.Loader sc={{ brand: 'gray.400' }} />
            </UI.Icon>
          </>
        )}
      </UI.InputLabel>
      {field.description && (
        <UI.InputDescription>
          <UI.HTML html={field.description} />
        </UI.InputDescription>
      )}
      {choices.map((choice) => (
        <UI.Checkbox
          checked={checked.includes(choice.id)}
          onChange={() => toggle(choice)}
          key={choice.id}
        >
          <UI.Span sc={{ muted: !choice.enabled }}>
            {choice.title}
          </UI.Span>
        </UI.Checkbox>
      ))}
      <UI.ErrorMessages attribute={field.title} errors={errors?.choices} />
    </UI.InputGroup>
  );
};

export default SelectionInput;
