import styled, { css } from 'styled-components';

import { Div } from './Generic';
import { InputProps } from './helpers';
import SC from './SC';
import useInput from './useInput';

const Input = styled.input<SC<InputProps>>`
  ${({ sc: { size, ...props } = {}, theme }) => css`
    ${useInput}
    ${theme.useSizes(size, 'padding', ['0 5px', '0 5px', '0 10px', '0 10px', '0 10px'])}
    ${theme.useSizes(size, 'height', ['30px', '30px', '40px', '50px', '50px'])}
    ${theme.useSpacing(props)}

    &[type="file"] {
      height: auto;
      line-height: 1;
      ${theme.useSizes(size, 'padding', ['5px', '5px', '10px', '10px', '10px'])}
    }
  `}
`;

export const InputFallback = styled(Div)`
  ${useInput}
  height: 40px;
  line-height: 38px;
  padding: 0 10px;
`;

export default Input;
