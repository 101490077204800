import { CheckoutStep } from '../helpers';
import { CheckoutStepPayload, EventType } from '../events';
import useEventHandler from '../../Common/useEventHandler';

interface UseCheckStepEventHandlerProps {
  fbq?: (action: string, event: string, data: object) => void;
  trackPageView?: (path?: string) => void;
}

const useCheckoutStepEventHandler = ({ fbq, trackPageView }: UseCheckStepEventHandlerProps) => {
  const checkoutSteps = {
    tickets: 1,
    personalisation: 2,
    extras: 3,
    team: 4,
    fundraising: 5,
    details: 6,
    payment: 7,
  };

  const handleCheckoutStepEvent = (payload: CheckoutStepPayload) => {
    const stepNumber = checkoutSteps[payload.step as CheckoutStep] || null;

    // Track with Facebook Pixel
    if (stepNumber && fbq) {
      fbq('trackCustom', 'CheckoutStep', {
        // Custom properties
        event_id: payload.event.id,
        event_title: payload.event.title,
        step: payload.step,
      });
    }

    // Track with Google Analytics
    if (stepNumber && trackPageView) {
      trackPageView(`/atleta/${payload.step}/${payload.event.id}`);
    }
  };

  useEventHandler(EventType.CheckoutStep, handleCheckoutStepEvent);
};

export default useCheckoutStepEventHandler;
