import React from 'react';

/* eslint-disable max-len */
const Ticket = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path className="cls-1" d="m1.5,9.65c1.42,0,2.57,1.15,2.57,2.57s-1.15,2.57-2.57,2.57c0,0,0,0,0,0v2.47c0,1.24,1.01,2.25,2.25,2.25h16.51c1.24,0,2.25-1.01,2.25-2.25v-2.47s0,0,0,0c-1.42,0-2.57-1.15-2.57-2.57s1.15-2.57,2.57-2.57c0,0,0,0,0,0v-2.61c0-1.24-1.01-2.25-2.25-2.25H3.75c-1.24,0-2.25,1.01-2.25,2.25v2.61s0,0,0,0Z" />
    <line className="cls-1" x1="15.5" y1="15.82" x2="15.5" y2="15.82" />
    <line className="cls-1" x1="15.5" y1="12.15" x2="15.5" y2="12.15" />
    <line className="cls-1" x1="15.5" y1="8.47" x2="15.5" y2="8.47" />
  </svg>
);

export default Ticket;
